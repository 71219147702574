import { useEffect, useState } from 'react'
import { KTIcon } from '../../../../_metronic/helpers'
import { initOnLoad } from 'apexcharts';
interface SearchProps {
  initalValue: string,
  onChange(arg0: string): any;
}
const SearchInner: React.FC<SearchProps> = ({ initalValue: string, onChange }) => {

  const [search, setSearch] = useState<string>('')

  useEffect(() => {
    onChange(search)
  }, [search])

  return (

    <div data-kt-search-element='wrapper'>
      <form
        data-kt-search-element='form'
        className='w-100 position-relative mb-3'
        autoComplete='off'
      >
        <KTIcon
          iconName='magnifier'
          className='fs-2 text-lg-1 text-gray-500 position-absolute top-50 translate-middle-y ms-3'
        />
        <input
          type='text'
          className='form-control form-control-flush ps-12'
          name='search'
          value={search}
          placeholder='Search...'
          data-kt-search-element='input'
          onChange={(e) => setSearch(e.target.value)}
        />
      </form>

    </div>
  )
}

export { SearchInner }

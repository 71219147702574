import { UserModel } from '../../auth';
import { Option } from '../components/multi-select-dropdown/MultiSelectionDropdown';
import { Filter, Task, TaskCategory } from './_models';
import { FastField } from 'formik';
import { runtimeEnv } from '../../../../env';

export function taskCategoryToOptions(taskCategories: any[]): Option[] {
    let options: Option[] = [];
    for (let t of taskCategories) {
        options.push({
            id: t.id,
            label: t.name
        })
    }
    return options
}

export function UserToOptions(users: UserModel[]): Option[] {
    let options: Option[] = [];
    for (let t of users) {
        options.push({
            id: t.id,
            label: t.name
        })
    }
    return options
}
export function generateWeekArray(data: any[], hideCompleted = false) {

    let weekArray: any[] = [];
    const weeks: { week: string; newSingleArr: any }[] = [];

    //Week Array
    for (const task of data) {
        weekArray = weekArray.filter(f => f !== task.start_week).concat([task.start_week])
    }

    for (const week of weekArray) {

        let tasksInWeek: any = {};
        for (const itemSub of data) {
            if (hideCompleted == true && itemSub.task_status_id == 2)
                continue;
            if (itemSub.start_week == week) {
                if (tasksInWeek[week]) {
                    tasksInWeek[week].push(itemSub);
                }
                else {
                    const newItemArr: any = [itemSub];
                    tasksInWeek[week] = newItemArr;
                }
            }
        }
        tasksInWeek = Object.values(tasksInWeek);

        weeks.push({ week: week, newSingleArr: tasksInWeek[0] ? tasksInWeek[0] : [] });
    }

    weeks.forEach((a, i) => {
        weeks[i].newSingleArr.sort((a: any, b: any) => { return (new Date(a.start_date).getTime() - new Date(b.start_date).getTime()) })
    })

    return weeks;
}

export function generateQuery(filters: Filter[]): string {
    let q: string[] = [];
    for (let filter of filters) {
        q.push(filter.name + '=' + filter.value.join(","));
    }
    return '?' + q.join('&');
}

export const formatDate = (_d: string | Date) => {
    return (new Date(_d)).toLocaleDateString();

}

export const formatCustomDate = (_d: string | Date) => {
    let d = (new Date(_d))
    return (d.getMonth() + 1) + '/' + d.getDate()

}
export const getWeekDay = (_d: string | Date) => {
    const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return weekday[(new Date(_d)).getDay()];

}
export const formatDateTime = (_d: any) => {
    return (new Date(_d)).toLocaleTimeString

}

export const formatMoney = (_n: number) => {
    return _n.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
}

export const formatNumber = (_n: number) => {
    return _n.toLocaleString('en-US',  {minimumFractionDigits: 0})
}

export const getInitals = (name: string) => {
    var names = name.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
}

export const getWeekFrom = (_d: Date, week: number) => {
    var curr = new Date(_d); // get current date
    curr.setDate(curr.getDate() + 7 * week);
    var first = curr.getDate() - curr.getDay() + 1; // First day is the day of the month - the day of the week
    var last = first + 6; // last day is the first day + 6
    var firstday = new Date(curr);
    var lastday = new Date(curr);
    firstday.setDate(first)
    lastday.setDate(last)
    return { firstDay: formatDate(firstday), lastDay: formatDate(lastday) }

}

export const dateBetweenWeek = (_date: Date, _releaseDate: Date, week: number) => {
    var curr = new Date(_releaseDate); // get current date
    curr.setDate(curr.getDate() + 7 * week);
    var first = curr.getDate() - curr.getDay() + 1; // First day is the day of the month - the day of the week
    var last = first + 6; // last day is the first day + 6
    var firstday = new Date(curr);
    var lastday = new Date(curr);
    firstday.setDate(first)
    lastday.setDate(last)
    if (_date > firstday && _date < lastday)
        return true;
    return false;

}
export const distanceBetweenWeek = (_date: Date, _releaseDate: Date, week: number) => {
    var curr = new Date(_releaseDate); // get current date
    curr.setDate(curr.getDate() + 7 * week);
    var first = curr.getDate() - curr.getDay() + 1; // First day is the day of the month - the day of the week
    var last = first + 6; // last day is the first day + 6
    var firstday = new Date(curr);
    var lastday = new Date(curr);
    firstday.setDate(first)
    lastday.setDate(last)

    if (_date > firstday && _date < lastday)
        return 0;

    var diffDays;

    if (firstday > _date) {
        diffDays = Math.abs(firstday.getTime() - _date.getTime());
    } else {
        diffDays = Math.abs(_date.getTime() - lastday.getTime()) / (1000 * 60 * 60 * 24);
    }

    return diffDays;
}

export const getAssetUrl = (url: string) => {
    return runtimeEnv.REACT_APP_URL + url;
}
const pad = (width: number, string: string, padding: string): string => {
    return (width <= string.length) ? string : pad(width, padding + string, padding)
}
export const adjustTextToMinLength = (text: string, minLength: number): string => {

    return pad(minLength, text, ' ');
}
export const getDays = (start: Date, end: Date) => {
    for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
        arr.push(new Date(dt).toISOString().slice(0, 10));
    }
    return arr;
};

export const extractContent = (html: any): string | null => {
    return new DOMParser()
        .parseFromString(html, "text/html")
        .documentElement.textContent;
}
export const clipText = (text: string, length: number): string => {
    if (text.length < length)
        return text;
    return text.substring(0, length) + " ...";

}

export function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
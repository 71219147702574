import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';



import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { CampaignCategory, TaskCategory } from '../../../../core/Models';
import Loader from '../../../../../global-module/loader/loader';
import { postCampaignCategories, postTaskCategories, putCampaignCategories, putTaskCategories } from '../../../../core/Requests';
import { Exception } from 'sass';


interface Props {
    showDailog: any,

    editData: TaskCategory | undefined,
    onChange(arg0: boolean): any;
}
export const AddUpdateDailog: React.FC<Props> = ({ showDailog, editData, onChange }) => {

    const [isLoading, setIsLoading] = useState(false);

    const handleClose = () => {
        onChange(false);
    };

    useEffect(() => {
        if (editData && editData.id) {
            setFormData(editData);
        }
    }, []);

    const [formData, setFormData] = useState<TaskCategory>({
        id: undefined,
        name: '',

    });

    const [errors, setErrors] = useState({
        name: '',

    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const validateForm = (data: any) => {
        const errors: any = {};

        if (!data.name) {
            errors.name = 'Name is required.';
        }




        return Object.keys(errors).length ? errors : null;
    };

    const handleSubmit = () => {

        const validationErrors = validateForm(formData);
        if (validationErrors) {
            setErrors(validationErrors);
            return;
        }

        if (editData && editData.id) {

            // Edit data
            setIsLoading(true);

            putTaskCategories(formData).then((response: any) => {
                console.log(response);
                toast.success(response?.message);
            }).catch((e: Exception) => {
                toast.error('Something went wrong please try again');
            }).finally(() => {
                onChange(false);
                setIsLoading(false);
            })

        }
        else {
            // Add data
            setIsLoading(true);

            postTaskCategories(formData).then((response: any) => {
                console.log(response);
                toast.success(response?.message);
            }).catch((e: Exception) => {
                toast.error('Something went wrong please try again');
            }).finally(() => {
                onChange(false);
                setIsLoading(false);
            })
        }
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (<></>)}

            <Modal show={showDailog} onHide={handleClose}>
                <div className="custom_modal">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {editData && editData.id ? 'Update' : 'Add New'}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className='labels'>Task Name</label>
                                    <input className='form-control' placeholder='Enter Name' type="text" id="name" name="name" value={formData.name} onChange={handleChange} />
                                    {errors.name && formData.name === '' && <div className='error-form'>{errors.name}</div>}
                                </div>
                            </div>



                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="" onClick={handleSubmit} className='btn-active'>
                            {editData && editData.id ? 'Update' : 'Add'}
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal>
            {/* <ToastContainer /> */}
        </>
    )
}

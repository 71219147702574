import React, { useState } from 'react';


interface CheckboxSingleSelectProps {
    options: string[];
    filterName: string;
    onChange(arg0: any): any;
}
const SingleSelectionDropdown: React.FC<CheckboxSingleSelectProps> = ({ options, filterName, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');



    const handleOptionClick = (option: any) => {
        setSelectedOption(option);
        setIsOpen(false);
        onChange(option);
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <div className="dropdown sele_dropdown mb-1">
                <button className="btn dropdown-toggle btn-sm fw-bold btn-secondary ms-3" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" onClick={toggleDropdown}>
                    {selectedOption || filterName}
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    {options.map((option, index) => (
                        <div className="form-check ps-1" key={index} onClick={() => handleOptionClick(option)}>
                            <label className="form-check-label">
                                {option}
                            </label>
                        </div>
                    ))}
                </div>
            </div>
        </>

    );
};

export default SingleSelectionDropdown;
import { useState, useEffect, ChangeEvent, useRef } from 'react'
import { toast } from 'react-toastify'

import clsx from 'clsx'
import { KTIcon, toAbsoluteUrl } from '../../../../../_metronic/helpers'

// Load services
import instance from '../../../../services/axiosConfig'

// Task Dialog
import AddTask from './dialog/add-task'

// Load other component
import DeleteConfirmationDialog from '../../../global-module/deleteConfirmationDialog/deleteConfirmationDialog'
import { DrawerMessenger } from '../../../global-module/components/task-comment'
import { useLocation } from 'react-router-dom'
import { useAuth } from '../../../auth/core/Auth'
import MultiSelectionDropdown from '../multi-select-dropdown/MultiSelectionDropdown'
import { Activity, AddProps, CampaignType, Filter, Task, TaskCategory, TaskComment, initialData } from '../../core/_models'
import {
  deleteCampaign,
  deleteTask,
  getSingleCampaign,
  getTaskCategory,
  getUser,
  updateTask,
} from '../../core/_requests'
import {
  UserToOptions,
  dateBetweenWeek,
  distanceBetweenWeek,
  formatCustomDate,
  formatDate,
  formatMoney,
  generateQuery,
  generateWeekArray,
  getWeekDay,
  getWeekFrom,
  taskCategoryToOptions,
} from '../../core/Helpers'
import { UserModel, getReleases } from '../../../auth'
import Loader from '../../../global-module/loader/loader'
import { useCommonContext } from '../../../../services/CommonContext'
import AddActivity from '../activity-dialog/add-activity'
import { Settings, getSettings } from '../../../../helpers/auth'
import ViewActivity from '../activity-dialog/view-activity'
import MultiSelectionGroupedDropdown from '../multi-select-dropdown/MultiSelectionGroupedDropdown'
import { DrawerComponent } from '../../../../../_metronic/assets/ts/components'

const itemClass = 'ms-1 ms-lg-3'
const btnClass = 'btn btn-icon'
const btnIconClass = 'fs-1'
export function Tasks({ withBudget = true }) {
  const [taskCategories, setTaskCategories] = useState<TaskCategory[]>([])

  const [isLoading, setLoading] = useState(false)
  const [editTask, setEditTaskForm] = useState(false)
  const [taskId, setshowTaskId] = useState(0)
  const [singleTask, setSingleTask] = useState<Task | undefined>(undefined)
  const [singleCampaign, setSingleCampaing] = useState<Activity | undefined>(undefined)
  const [users, setUsers] = useState<UserModel[]>([])
  const [data, setData] = useState<Task[]>([])
  const [show, setShow] = useState(false)
  const [editCampaign, setEditCampaign] = useState(false)
  const [taskDeleteDialogOpen, setTaskDeleteDialogOpen] = useState(false)
  const [taskDeleteId, setTaskDeleteId] = useState<number | undefined>(undefined)
  const [campaignDeleteDialogOpen, setCampaignDeleteDialogOpen] = useState(false)
  const [campaignDeleteId, setCampaignDeleteId] = useState<number | undefined>(undefined)
  const [requestInQueue, setRequestInQueue] = useState<number>(0)
  const [hideCompleted, setHideCompleted] = useState<boolean>(false)
  const { currentUser, release } = useAuth()
  const { campaignUpdated, budgetUpdated, releaseDate, setBudgetUpdated, setCampaignUpdated } =
    useCommonContext()
  const [showListActivity, setShowListActivity] = useState(false)
  const [showActivityForm, setshowActivityForm] = useState(false)
  const [expandAll, setExpandAll] = useState(false)
  const [colapseAll, setColapseAll] = useState(false)
  const [editActivity, setEditActivity] = useState<Activity | undefined>(undefined)
  const [intialFormData, setInitalFormData] = useState<AddProps>(initialData)
  const [filters, setFilters] = useState<Filter[]>([
    { name: 'release_id', value: [release ? release : ''] },
  ])
  const [campaignCategories, setCampaignCategories] = useState<CampaignType[]>([])

  const weekRef = useRef<any>([]);

  const fetchCampaignCategory = async () => {
    try {
      const response = await instance.get('/campaign-type')
      setCampaignCategories(response.data?.data)
    } catch (error) { }
  }

  let expanded = useRef(true);
  useEffect(() => {
    //fetchAllTask();
    if (currentUser) {
      fetchCategories()
      fetchUsers()
      setHideCompleted(getSettings().hideCompleted)
      fetchCampaignCategory()
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      DrawerComponent.bootstrap()
    }, 500);
  }, [])

  useEffect(() => {
    fetchAllTask()
    //fetchReleaseDate();
  }, [filters, campaignUpdated])

  useEffect(() => {
    if (release) setFilters([{ name: 'release_id', value: [release] }])
  }, [release])


  const fetchAllTask = async () => {
    if (filters.length > 0) {
      instance
        .get('/task' + generateQuery(filters))
        .then((r) => {
          setData(r?.data?.data)
          setLoading(false)
        })
        .catch((e) => {
          if (e.message == 'canceled') {
            // handle abort()
            console.log('Aborted!')
          } else {
            throw e
          }
        })
    }
  }

  const fetchCategories = async () => {
    const taskCategories = await getTaskCategory('')
    setTaskCategories(taskCategories)
  }

  const fetchUsers = async () => {
    const data = await getUser('')
    setUsers(data)
  }

  const categoryOptions = taskCategoryToOptions(taskCategories)
  const campaignCategoryOptions = taskCategoryToOptions(campaignCategories)

  const userOptions = UserToOptions(users)
  const handleShow = (param: any) => {
    setEditTaskForm(param)
    fetchAllTask()
    setShow(false)
  }

  const handleEditTask = (param: any, itemId: number, currentTask: any) => {
    setSingleTask(currentTask)
    setshowTaskId(itemId)
    setEditTaskForm(param)
  }

  const handleEditCampaign = (param: any, itemId: number, task: Task) => {
    if (task.campaign_id != null) {
      getSingleCampaign(task.campaign_id.toString(), task.release_id).then((d) => {
        setEditCampaign(true)
        setSingleCampaing(d)
      })
    }
  }

  // Delete Conformation

  const handleTaskDelete = async () => {
    console.log("deleting task")
    if (taskDeleteId)
      deleteTask(taskDeleteId?.toString())
        .then(() => {
          toast.success('Delete Task Successfully')
          setTaskDeleteDialogOpen(false)
          fetchAllTask()
          setBudgetUpdated(!budgetUpdated)
        })
        .catch(() => {
          toast.error('Delete Task Failed')
          setTaskDeleteDialogOpen(false)
        })
  }
  const handleCampaignDelete = async () => {
    if (campaignDeleteId)
      deleteCampaign(campaignDeleteId?.toString())
        .then(() => {
          toast.success('Delete campaign Successfully')
          setCampaignDeleteDialogOpen(false)
          fetchAllTask()
          setBudgetUpdated(!budgetUpdated)
          setCampaignUpdated(!campaignUpdated)
        })
        .catch((e) => {
          //console.log(e);
          toast.success('Delete Campaign Failed')
          setCampaignDeleteDialogOpen(false)
        })
  }

  const handleCancelDelete = () => {
    setTaskDeleteDialogOpen(false)
    setCampaignDeleteDialogOpen(false)
  }

  const openTaskDeletePopup = (param: any, itemId: number) => {
    setTaskDeleteId(itemId)
    setTaskDeleteDialogOpen(param)
  }

  const openCampaignDeletePopup = (param: any, itemId: number) => {
    setCampaignDeleteId(itemId)
    setCampaignDeleteDialogOpen(param)
  }

  const [isCurrentTaskName, setCurrentTaskName] = useState('')
  const [isCurrentTaskId, setCurrentTaskId] = useState()

  const openCommentDialog = async (taskTile: any, taskId: any) => {
    setCurrentTaskName(taskTile)
    setCurrentTaskId(taskId)
  }

  const weeksArray = generateWeekArray(data, hideCompleted)

  async function checkHandler(event: ChangeEvent<HTMLInputElement>) {
    if (!currentUser) {
      toast.error('Kidnly Login to perform this action')
      return
    }

    let _data: Task[] = []

    for (let i of data) {
      if (i.id == parseInt(event.currentTarget.value)) {
        i.task_status_id = event.currentTarget.checked ? 2 : 1
      }
      _data = [..._data, i]
    }

    setData(_data)
    let statusData = {}
    if (event.currentTarget.checked) statusData = { task_status_id: 2 }
    else statusData = { task_status_id: 1 }

    updateTask(event.currentTarget.value, statusData).then((d) => {
      //fetchAllTask();
    })
  }

  const handleHideCompleted = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked
    setHideCompleted(isChecked)
  }
  const showListActivityDialog = (param: any) => {
    setShowListActivity(param)
  }
  const showActivityDialog = (param: any) => {
    setshowActivityForm(param)
  }

  if (currentUser?.role == "partner") {
    withBudget = false;
  }

  return (
    <>
      {isLoading ? <Loader></Loader> : null}
      <DrawerMessenger custTaskName={isCurrentTaskName} custtaskId={isCurrentTaskId} />

      <div className='assets_head marketing'>
        <h3>Marketing Plan</h3>
        <div className='d-flex flex-row-reverse mb-2'>
          {/* <a className="btn btn-sm fw-bold btn-light-primary  ms-3" onClick={() => showListActivityDialog(true)}>View Campaigns</a> */}
        </div>
      </div>

      {currentUser ? (
        <div className='item_head sticky pb-2 pt-2'>
          <div>&nbsp;</div>
          <div>
            <div className='sele_dropdown mb-1 pe-3 border-right'>
              <button
                className='btn btn-sm btn-outline fw-bold  ms-3'
                type='button'
                onClick={() => {

                  let closestWeek: any;
                  let weekFound = false;
                  let distance: number | undefined;
                  weeksArray.map((week, index) => {

                    if (dateBetweenWeek(new Date(), releaseDate!, Number.parseInt(week.week))) {
                      weekFound = true;
                      weekRef.current[index].scrollIntoView()
                      if (weekRef.current[index].className.split(" ").indexOf("collapsed") > -1) {
                        weekRef.current[index].click();
                      }
                    }


                    if (distance === undefined) {
                      distance = distanceBetweenWeek(new Date(), releaseDate!, Number.parseInt(week.week))
                      closestWeek = week
                    }

                    if (distanceBetweenWeek(new Date(), releaseDate!, Number.parseInt(week.week)) < distance) {
                      distance = distanceBetweenWeek(new Date(), releaseDate!, Number.parseInt(week.week))
                      closestWeek = week
                    }
                  })

                  if (!weekFound) {

                    weekRef.current[weeksArray.indexOf(closestWeek)].scrollIntoView();
                  }
                }}
                title="Jump to Today"
              >
                <img src={toAbsoluteUrl('/media/misc/icon-today.svg')} className='me-2'></img>
                <span>Today</span>
              </button>

            </div>
            <div className='sele_dropdown mb-1 pe-3 border-right'>
              <button
                className='btn btn-sm btn-outline fw-bold  ms-3'
                type='button'
                onClick={() => {
                  setExpandAll(!expandAll)
                  expanded.current = !expanded.current;
                }}
                title={expanded.current ? 'Collapse All' : 'Expand All'}
              >
                <img src={toAbsoluteUrl('/media/misc/icon-expand.svg')} className={!expanded.current ? '' : 'rotated'}></img>
              </button>

            </div>

            <div className='form-check heading-checkbox mt-2 ms-3'>
              <input
                className='form-check-input'
                type='checkbox'
                value={1}
                checked={hideCompleted}
                onChange={handleHideCompleted}
              />
              <label className='form-check-heading'>Hide Completed</label>
            </div>
            <MultiSelectionGroupedDropdown
              options1={categoryOptions}
              enableSelectedOption={false}
              selectedOption1={[]}
              buttonText='Category'
              text1={'Task Category'}
              onChange1={function (arg0: any[]) {
                let _f: Filter[] = filters.filter((f) => f.name != 'task_category_ids')
                let values: string[] = []
                for (let a of arg0) {
                  values.push(a.id)
                }
                _f = [..._f, { name: 'task_category_ids', value: values }]
                setFilters(_f)
              }}
              options2={campaignCategoryOptions}
              selectedOption2={[]}
              text2={'Category'}
              onChange2={function (arg0: any[]) {
                let _f: Filter[] = filters.filter((f) => f.name != 'campaign_category_ids')
                let values: string[] = []
                for (let a of arg0) {
                  values.push(a.id)
                }
                _f = [..._f, { name: 'campaign_category_ids', value: values }]
                setFilters(_f)
              }}
            />

            {/* <MultiSelectionDropdown options={categoryOptions} enableSelectedOption={false} selectedOption={[]} text={'Category'} onChange={function (arg0: any[]) {
                            let _f: Filter[] = filters.filter(f => f.name != 'task_category_ids');
                            let values: string[] = [];
                            for (let a of arg0) {
                                values.push(a.id);
                            }
                            _f = [..._f, { "name": "task_category_ids", value: values }]
                            setFilters(_f)
                        }} /> */}
            <MultiSelectionDropdown
              options={userOptions}
              enableSelectedOption={false}
              selectedOption={[]}
              text={'Assignee'}
              onChange={function (arg0: any[]) {
                let _f: Filter[] = filters.filter((f) => f.name != 'user_ids')
                let values: string[] = []
                for (let a of arg0) {
                  values.push(a.id)
                }
                _f = [..._f, { name: 'user_ids', value: values }]
                setFilters(_f)
              }}
            />
            {currentUser && currentUser.role != "partner" && (
              <div className='dropdown sele_dropdown mb-1'>
                <button
                  className='btn btn-sm  btn-bg-light fw-bold btn-primary ms-3'
                  type='button'
                  id='dropdownUserAction'
                  onClick={() => setShow(true)}
                >
                  <i className='fa fa-plus'></i> Add
                </button>

              </div>
            )}

          </div>
        </div>
      ) : null}
      <DeleteConfirmationDialog
        isOpen={taskDeleteDialogOpen}
        onCancel={handleCancelDelete}
        onConfirm={handleTaskDelete}
      />
      <DeleteConfirmationDialog
        isOpen={campaignDeleteDialogOpen}
        onCancel={handleCancelDelete}
        onConfirm={handleCampaignDelete}
      />

      {showActivityForm ? (
        <AddActivity
          dialogAction={showActivityForm}
          editId={editActivity ? editActivity.id : 0}
          editData={editActivity}
          callback={() => {
            setEditActivity(undefined)
            setCampaignUpdated(!campaignUpdated)
            setshowActivityForm(false)
          }}
          initalData={intialFormData}
          onDataChange={(data, openAddTask, openAddCampaign

          ) => {
            setInitalFormData(data);
            if (openAddTask) {
              setShow(true)
              setshowActivityForm(false)
            }
          }}
        />
      ) : null}

      {showListActivity ? (
        <ViewActivity
          dialogAction={showListActivity}
          sendData={showListActivityDialog}
          callback={() => {
            setCampaignUpdated(!campaignUpdated)
          }}
        />
      ) : null}

      {show ? (
        <AddTask initalData={intialFormData} dialogAction={show} editId={0} editData={null} onChange={handleShow} onDataChange={(formdata, openAddTask, openAddCampaign) => {
          setInitalFormData(formdata);
          if (openAddCampaign) {
            setShow(false)
            setshowActivityForm(true)
          }
        }} />
      ) : null}

      {editTask ? (
        <AddTask
          initalData={intialFormData}
          dialogAction={editTask}
          editId={taskId}
          editData={singleTask}
          onChange={handleShow}
          onDataChange={(formdata, openAddTask, openAddCampaign) => {
            setInitalFormData(formdata);

            if (openAddCampaign) {
              setEditTaskForm(false)
              setshowActivityForm(true)

            }
          }}
        />
      ) : null}


      {editCampaign && singleCampaign ? (
        <AddActivity
          dialogAction={editCampaign}
          editId={singleCampaign.id}
          editData={singleCampaign}
          callback={() => {
            setEditCampaign(false)
          }}
          initalData={intialFormData}
          onDataChange={(data, openAddTask, openAddCampaign) => {

            setInitalFormData(data);

            if (openAddTask) {
              setShow(true)
              setshowActivityForm(false)
              setEditCampaign(false)
            }
          }}
        ></AddActivity>
      ) : null}

      {data.length == 0 && (currentUser && currentUser.role != "partner" ?
        <div className='nodatafound'>
          Looks empty! Get started by clicking the '+ Add' button
        </div> : <div className='nodatafound'>
          Looks empty!
        </div>
      )}
      {weeksArray.map((week, index) =>
        week?.newSingleArr.length > 0 ? (
          <div key={index} className='custom_according'>
            <div
              className={expanded.current ? 'd-flex align-items-center collapsible py-3 toggle mb-0 accordion-heading' : 'd-flex align-items-center collapsible py-3 toggle mb-0 accordion-heading collapsed '}
              data-bs-toggle='collapse'
              data-bs-target={`#kt_job_4_${index}`}
              ref={el => weekRef.current[index] = el}

            >
              <div className='btn btn-sm btn-icon mw-20px btn-active-color-primary me-5'>
                <i className='ki-solid ki-up toggle-on fs-1'></i>
                <i className='ki-solid ki-down toggle-off fs-1'></i>
              </div>

              <h4 className=' fw-bold cursor-pointer mb-0'>
                {week?.week == '-17'
                  ? 'No Week'
                  : 'Week ' +
                  week?.week +
                  ' (' +
                  getWeekFrom(releaseDate!, Number.parseInt(week.week)).firstDay +
                  ' - ' +
                  getWeekFrom(releaseDate!, Number.parseInt(week.week)).lastDay +
                  ')'}
              </h4>
            </div>

            <div id={`kt_job_4_${index}`} className={expanded.current ? 'collapse fs-6 ms-1 show' : 'collapse fs-6 ms-1'}>
              <div className='mb-4 text-gray-600 fw-semibold fs-6'>
                <div className='fs-6 ms-1'>
                  <div className='mb-4 text-gray-600 fw-semibold fs-6'>
                    <div className='card mb-5 mb-xl-8'>
                      <div className='card-body py-3 px-3'>
                        {week?.newSingleArr.length > 0 ?
                          <>
                            <div className='table-responsive'>
                              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3 '>
                                <thead>
                                  <tr className='fw-bold text-muted'>
                                    <th className='w-25px'></th>
                                    <th className='min-w-150px w-40'>NAME</th>
                                    <th className='w-150px'>TYPE</th>
                                    <th className='w-200px text-center'>CATEGORY</th>
                                    <th className='w-150px text-center'>DATE</th>
                                    <th className='w-200px text-center'>ASSIGNEE</th>
                                    {withBudget ? (
                                      <th className='w-200px text-center'>BUDGET</th>
                                    ) : null}
                                    <th className='min-w-50px'></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {week?.newSingleArr.map((inSubItem: any, inSubindex: number) => (
                                    <>
                                      {
                                        inSubItem?.date != null && (!week?.newSingleArr[inSubindex - 1] || week?.newSingleArr[inSubindex - 1].date != inSubItem?.date) ? (
                                          <tr
                                            key={inSubindex + "_" + Date.now()}
                                            className={
                                              hideCompleted && inSubItem?.task_status_id == 2
                                                ? 'hide'
                                                : ''
                                            }>
                                            <td className='w-25px fw-bold ' colSpan={6}>{getWeekDay(inSubItem?.date + 'T00:00:00.000-08:00')}, {formatDate(inSubItem?.date + 'T00:00:00.000-08:00')}</td>

                                          </tr>) : null
                                      }

                                      <tr
                                        key={inSubindex}
                                        className={
                                          hideCompleted && inSubItem?.task_status_id == 2
                                            ? 'hide'
                                            : ''
                                        }
                                      >
                                        <td className='w-25px'>
                                          <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                            <input
                                              className='form-check-input widget-13-check'
                                              type='checkbox'
                                              checked={inSubItem?.task_status_id == 2}
                                              value={inSubItem?.id}
                                              onChange={checkHandler}
                                            />
                                          </div>
                                        </td>
                                        <td
                                          className={
                                            inSubItem?.task_status_id == 2
                                              ? 'min-w-150px w-40 strikeThrough'
                                              : 'min-w-150px w-40'
                                          }
                                        >
                                          {inSubItem?.title}
                                          <div
                                            className={clsx(
                                              'd-inline-block',
                                              itemClass,
                                              'min-w-120px'
                                            )}
                                            onClick={() =>
                                              openCommentDialog(inSubItem?.title, inSubItem?.id)
                                            }
                                          >
                                            <div
                                              className={clsx('position-relative', btnClass)}
                                              id='kt_drawer_chat_toggle'
                                            >
                                              <KTIcon
                                                iconName='message-text-2'
                                                className={btnIconClass}
                                              />
                                            </div>
                                          </div>
                                        </td>
                                        <td className='w-200px '>
                                          {inSubItem?.type == 'task' ? 'Task' : 'Campaign'}
                                        </td>
                                        <td className='w-200px text-center'>
                                          {inSubItem?.type == 'task'
                                            ? inSubItem?.task_category?.name
                                            : inSubItem?.campaign_type?.name}
                                        </td>
                                        <td className=' w-150px  text-center'>
                                          {inSubItem?.is_fixed_date == false
                                            ? '-'
                                            : formatDate(inSubItem?.start_date + 'T00:00:00.000-08:00')}
                                        </td>
                                        <td className='w-200px text-center'>
                                          {inSubItem?.user?.name}
                                        </td>
                                        {withBudget ? (
                                          <td className='w-200px text-center'>
                                            {formatMoney(Number(inSubItem?.budget))}
                                          </td>
                                        ) : null}
                                        {/* <td className='text-center'>
                                                                                    <span className={"badge " + (inSubItem?.task_status?.name != 'Pending' ? 'badge-light-success' : 'badge-light-warning')}>{inSubItem?.task_status?.name}</span>
                                                                                </td> */}

                                        <td className='text-end min-w-50px'>
                                          {currentUser && currentUser?.role != "partner" ? (

                                            <>
                                              <div className='dropleft align-right-auto'>
                                                <button
                                                  className=' btn btn-sm btn-icon btn-bg-light btn-active-color-primary '
                                                  type='button'
                                                  id='dropdownUserAction'
                                                  data-bs-toggle='dropdown'
                                                  aria-expanded='false'
                                                >
                                                  <i className='bi bi-three-dots fs-3'></i>
                                                </button>
                                                <div
                                                  className='dropdown-menu w-150px'
                                                  aria-labelledby='dropdownUserAction'
                                                >
                                                  <div className='menu-item px-3'>
                                                    <a
                                                      className={'menu-link px-3'}
                                                      onClick={() =>
                                                        handleEditTask(
                                                          true,
                                                          inSubItem.id,
                                                          inSubItem
                                                        )
                                                      }
                                                    >
                                                      Edit
                                                    </a>
                                                  </div>
                                                  <div className='menu-item px-3'>
                                                    <a
                                                      className='menu-link px-3 color-red'

                                                      onClick={() =>
                                                        openTaskDeletePopup(true, inSubItem.id)
                                                      }
                                                    >
                                                      Delete
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>

                                            </>

                                          ) : null}
                                        </td>
                                      </tr>
                                    </>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </>
                          : (
                            ''
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )
      )}
    </>
  )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEventHandler, useEffect, useState } from 'react'

import { Link, useParams } from 'react-router-dom'

import { useLocation } from 'react-router'
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Dropdown1 } from '../../../../_metronic/partials'
import { useQuery } from 'react-query'
import {
  getRelease,
  getReleaseStats,
  getReleases,
  updateBudget,
  updateLinkSharing,
  updateOverview,
} from '../core/_requests'
import { CategoryBudget, Release, ReleaseStats } from '../core/_models'
import { extractContent, formatDate, formatMoney, formatNumber } from '../core/Helpers'
import { useCommonContext } from '../../../services/CommonContext'
import { useAuth } from '../../auth'
import ReactQuill from 'react-quill'
import { Button, Modal, ProgressBar } from 'react-bootstrap'
import { link } from 'fs'
import { ToastContainer, toast } from 'react-toastify'
import CryptoJS from 'crypto-js'
import { AddUpdateDailog } from '../../release-bundle/dialog/AddUpdateDailog'
import { runtimeEnv } from '../../../../env'
interface Props {
  releaseStats?: ReleaseStats
  withBudget?: boolean
}
enum BudgetType {
  Stream,
  Creations,
  Budget,
}
const ReleaseHeader: React.FC<Props> = ({ releaseStats = null, withBudget = true }) => {
  const { budgetUpdated, setReleaseDate } = useCommonContext()
  const [release, setRelease] = useState<Release>()
  const [showModal, setShowModel] = useState(false)
  const [showBudgetModal, setShowBudgetModal] = useState(false)
  const [currentBudget, setCurrentBudget] = useState('')

  const { id }: any = useParams<any>()
  const { release: releaseId, currentUser } = useAuth()

  const [overviewText, setOverviewText] = useState<string | undefined>(undefined)
  const [initialOverviewText, setInitialOverviewText] = useState<string | undefined>(undefined)
  const [showOverviewText, setShowOverviewText] = useState(true)
  const [linkSharingWithBudgetEnabled, setLinkSharingWithBudgetEnabled] = useState<boolean>(false)
  const [linkSharingWithoutBudgetEnabled, setLinkSharingWithoutBudgetEnabled] =
    useState<boolean>(false)
  const [linkSharingWithBudget, setLinkSharingWithBudget] = useState<string | undefined>(undefined)
  const [linkSharingWithoutBudget, setLinkSharingWithoutBudget] = useState<string | undefined>(
    undefined
  )
  const [budgetType, setBudgetType] = useState<BudgetType>(BudgetType.Stream)
  const [projectedStreams, setProjectedStreams] = useState<number | undefined>(undefined)
  const [projectedCreations, setProjectedCreations] = useState<number | undefined>(undefined)
  const [projectedBudget, setProjectedBudget] = useState<number | undefined>(undefined)
  const [showReleaseUpdateDailog, setShowReleaseUpdateDailog] = useState<boolean>(false)
  //let releaseId: string = useLocation().pathname.split('/').pop()!;

  const {
    refetch: refetchRelease,
    data: releases
  } = useQuery(
    `releases`,
    () => {
      if (releaseId) return getRelease(releaseId)
    },
    { cacheTime: 100, keepPreviousData: true, refetchOnWindowFocus: false }
  )
  const closeModel = () => {
    setShowModel(false)
  }
  //console.log(releaseStats);
  useEffect(() => {
    if (releases) {
      let k = releases
      if (k.id == id) {
        let r: Release = {
          id: k.id,
          imageUrl: k.image_url ? k.is_bundle ? runtimeEnv.REACT_APP_URL + k.image_url : k.image_url : '/media/svg/menu/bundles-icon-blue.svg',
          title: k.name,
          artist: k.artist,
          year: k.year,
          releaseDate: new Date(Date.parse(k.release_date)),
          linkSharingWithBudgetEnabled: k.link_sharing_with_budget_enabled,
          linkSharingWithoutBudgetEnabled: k.link_sharing_without_budget_enabled,
          linkSharingWithBudget: k.link_sharing_with_budget,
          linkSharingWithoutBudget: k.link_sharing_without_budget,
          projectedStreams: k.projected_streams,
          projectedCreations: k.projected_creations,
          projectBudget: k.project_budget,
          internalTag: k.internal_tag,
          isBundle: k.is_bundle,
          child: k.child
        }
        //console.log((Date.parse(k.release_date)));
        setReleaseDate(new Date(Date.parse(k.release_date)))
        setRelease(r)
        setOverviewText(k.overview)
        setInitialOverviewText(k.overview)

        if (k.projected_streams) {
          setProjectedStreams(k.projected_streams)
        }

        if (k.projected_creations) {
          setProjectedCreations(k.projected_creations)
        }

        if (k.projected_budget) {
          setProjectedBudget(k.projected_budget)
        }

        setLinkSharingWithBudgetEnabled(k.link_sharing_with_budget_enabled)
        setLinkSharingWithoutBudgetEnabled(k.link_sharing_without_budget_enabled)
        if (k.link_sharing_with_budget) setLinkSharingWithBudget(k.link_sharing_with_budget)
        else
          setLinkSharingWithBudget(
            window.location.protocol +
            '//' +
            window.location.hostname +
            '/s/' +
            encodeURIComponent(CryptoJS.AES.encrypt('b', 'iGrooveSecret').toString()) +
            '/' +
            releaseId + '/' + currentUser?.tenant_id
          )

        if (k.link_sharing_without_budget)
          setLinkSharingWithoutBudget(k.link_sharing_without_budget)
        else
          setLinkSharingWithoutBudget(
            window.location.protocol +
            '//' +
            window.location.hostname +
            '/s/' +
            encodeURIComponent(CryptoJS.AES.encrypt('wb', 'iGrooveSecret').toString()) +
            '/' +
            releaseId + '/' + currentUser?.tenant_id
          )
      }
    }
  }, [releases])

  const saveOverview = () => {
    if (release && overviewText) {
      console.log(overviewText.length)
      if (overviewText.length > 65535) {
        toast.error('Text is larger than 65535 characters')
      } else
        updateOverview(release.id, overviewText)
          .then(() => {
            setShowOverviewText(true)
          })
          .catch((e) => {
            setShowOverviewText(false)
            toast.error('Unable to save the text Kindly try again')
          })
      setInitialOverviewText(overviewText)
    }
  }

  const handleCloseBudgetModal = () => {
    setShowBudgetModal(false)
  }

  const handleBudgetSubmit = () => {
    setShowBudgetModal(false)
    if (budgetType == BudgetType.Stream) {
      if (release?.id)
        updateBudget(release?.id, Number(currentBudget), projectedCreations, projectedBudget)
          .then(() => {
            setProjectedStreams(Number(currentBudget))
          })
          .catch((e) => {
            setShowBudgetModal(true)
            toast.error('Unable to update the streams')
          })
    }
    if (budgetType == BudgetType.Creations) {
      if (release?.id)
        updateBudget(release?.id, projectedStreams, Number(currentBudget), projectedBudget)
          .then(() => {
            setProjectedCreations(Number(currentBudget))
          })
          .catch((e) => {
            setShowBudgetModal(true)
            toast.error('Unable to update the creations')
          })
    }
    if (budgetType == BudgetType.Budget) {
      if (release?.id)
        updateBudget(release?.id, projectedStreams, projectedCreations, Number(currentBudget))
          .then((e) => {
            setProjectedBudget(Number(currentBudget))
          })
          .catch((e) => {
            setShowBudgetModal(true)
            toast.error('Unable to update the budget')
          })
    }
  }

  const handleChangedBudget = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentBudget(e.currentTarget.value)
  }

  if (release != undefined)
    return (
      <>
        <div className='card mb-5 mb-xl-10'>
          <div className='card-body p-0'>
            <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
              <div className='flex-grow-1'>
                <div className='d-flex  align-items-start flex-wrap'>
                  <div className='me-7 mb-4'>
                    <div className='symbol symbol-100px symbol-lg-100px symbol-fixed position-relative'>
                      <img src={release.imageUrl} alt='' />
                      {/* <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div> */}
                    </div>
                  </div>
                  <div className='d-flex flex-grow-1 flex-column'>
                    <div className='d-flex align-items-center mb-2'>
                      <span className='text-gray-800 fs-2 fw-bolder me-1'>
                        {release.title} &nbsp;
                        {release.internalTag && release.internalTag.length > 0 ? (
                          <div className='dropdown align-center-auto d-inline '>
                            <img
                              className='mb-2 cursor-pointer'
                              src={toAbsoluteUrl('/media/misc/note.png')}
                              id='dropdownUserAction'
                              data-bs-toggle='dropdown'
                              aria-expanded='false'
                            ></img>
                            <div
                              className='dropdown-menu dropdown-menu-center w-400px mt-10 p-4 text-gray-600 fw-medium'
                              aria-labelledby='dropdownUserAction'
                            >
                              {release.internalTag}
                            </div>
                          </div>
                        ) : null}
                      </span>
                    </div>

                    <div className='d-flex flex-wrap fw-bold fs-6 mb-2 pe-2'>
                      <span className='d-flex align-items-center text-gray-400  me-5 mb-2'>

                        {release.isBundle ? (
                          <div className="dropdown sele_dropdown mb-1">
                            <span className={"btn dropdown-toggle btn-sm fw-bold btn-secondary rounded ps-2 pe-2 pt-1 pb-1 " + (!(release?.child && release?.child?.length > 1) ? 'disabled' : '')} id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                              {release.isBundle ? release?.child && release?.child?.length > 1 ? release?.child?.length + ' releases included' : release?.child?.length + ' release included' : release.artist}
                            </span>
                            <div className='dropdown-menu w-300px' aria-labelledby="dropdownMenuButton1" >
                              <ol>
                                {release.child?.map((e: any, i: any) => (
                                  <li key={i}>{e.name} <span style={{ color: "#7e8299" }}>| EAN:</span> {e.release_identifier}</li>
                                ))}

                              </ol>
                            </div>
                          </div>) : <>
                          <KTIcon iconName='profile-circle' className='fs-4 me-1' />
                          {release.artist}
                        </>}

                      </span>
                      {/* <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      <KTIcon iconName='geolocation' className='fs-4 me-1' />
                      SF, Bay Area
                    </a> */}
                      <span className='d-flex align-items-center text-gray-400  mb-2'>
                        <KTIcon iconName='calendar' className='fs-4 me-1' />
                        {formatDate(release.releaseDate)}
                      </span>
                    </div>
                    <div className='d-flex flex-wrap fw-bold fs-6 mb-2 pe-2'>

                    </div>

                  </div>
                  {currentUser && currentUser.role != "partner" ? (

                    <>
                      {release.isBundle ? (<button
                        className='btn btn-sm fw-bold btn-primary me-3'
                        onClick={() => {
                          setShowReleaseUpdateDailog(true)
                        }}
                      >
                        <i className='fa fa-regular fa-edit'></i> Edit
                      </button>) : null}

                      <button
                        className='btn btn-sm fw-bold btn-primary'
                        onClick={() => {
                          setShowModel(true)
                        }}
                      >
                        <i className='fa fa-regular fa-share-from-square'></i> Share
                      </button>
                    </>

                  ) : null}
                </div>
                <div className='d-flex flex-wrap flex-stack'>
                  <div className='d-flex flex-column flex-grow-1'>
                    <div className='d-flex flex-wrap row'>
                      <div className='col-md-4 col-xs-6'>
                        <div className='border border-gray-300 rounded min-w-125px py-3 px-4 me-3 mb-3'>
                          <div className='d-flex align-items-center'>
                            <div className='fs-2 fw-bolder'>
                              {releaseStats?.currentTotalStreams ? formatNumber(Number(releaseStats?.currentTotalStreams)) : '-'}
                            </div>
                          </div>
                          <div className='fw-bold fs-6 text-gray-400'>Total Audio Streams</div>

                          {currentUser && currentUser.role != "partner" ? (
                            <>
                              <div className='separator my-3'></div>
                              <div className='pg d-flex align-items-end pt-0'>
                                {projectedStreams ? (
                                  <div className='d-flex align-items-center flex-column mt-3 w-100'>
                                    <div className='d-flex justify-content-between  w-100 mt-auto mb-2'>
                                      <span className='pg-left clickable' onClick={() => { }}>
                                        {Number(projectedStreams) <
                                          Number(releaseStats?.currentTotalStreams) ? (
                                          <i className='fa fa-circle-check text-success'></i>
                                        ) : null}{' '}
                                        Projected Streams
                                      </span>
                                      <span className='pg-right'>{Number(projectedStreams)}</span>
                                    </div>
                                    <div className='h-8px mx-3 w-100 bg-opacity-50 rounded text-success'>
                                      <ProgressBar
                                        variant={
                                          Number(projectedStreams) <
                                            Number(releaseStats?.currentTotalStreams)
                                            ? 'success'
                                            : ''
                                        }
                                        now={
                                          (Number(releaseStats?.currentTotalStreams) /
                                            Number(projectedStreams)) *
                                          100
                                        }
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div className='d-flex align-items-left flex-column mt-4 mb-4 w-100'>
                                    Click Edit to set projected value.
                                  </div>
                                )}
                                <a
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 ms-1'
                                  onClick={() => {
                                    setBudgetType(BudgetType.Stream)
                                    setShowBudgetModal(true)
                                    setCurrentBudget(projectedStreams?.toString() ?? '')
                                  }}
                                >
                                  <KTIcon iconName='pencil' className='fs-3' />
                                </a>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                      <div className='col-md-4 col-xs-6'>
                        <div className='border border-gray-300 rounded min-w-125px py-3 px-4 me-3 mb-3'>
                          <div className='d-flex align-items-center'>
                            <div className='fs-2 fw-bolder'>
                              {releaseStats?.currentTotalCreations ? formatNumber(Number(releaseStats?.currentTotalCreations)) : '-'}
                            </div>
                          </div>
                          {/* <div className=' fs-6 text-gray-400'>&nbsp;</div> */}
                          <div className='fw-bold fs-6 text-gray-400'>Total Creations</div>
                          {currentUser && currentUser.role != "partner" ? (
                            <>
                              <div className='separator my-3'></div>
                              <div className='pg d-flex align-items-end pt-0'>
                                {projectedCreations ? (
                                  <div className='d-flex align-items-center flex-column mt-3 w-100'>
                                    <div className='d-flex justify-content-between  w-100 mt-auto mb-2'>
                                      <span className='pg-left clickable' onClick={() => { }}>
                                        {' '}
                                        {Number(projectedCreations) <
                                          Number(releaseStats?.currentTotalCreations) ? (
                                          <i className='fa fa-circle-check text-success'></i>
                                        ) : null}
                                        Projected Creations
                                      </span>
                                      <span className='pg-right'>{Number(projectedCreations)}</span>
                                    </div>
                                    <div className='h-8px mx-3 w-100 bg-opacity-50 rounded'>
                                      <ProgressBar
                                        variant={
                                          Number(projectedCreations) <
                                            Number(releaseStats?.currentTotalCreations)
                                            ? 'success'
                                            : ''
                                        }
                                        now={
                                          (Number(releaseStats?.currentTotalCreations) /
                                            Number(projectedCreations)) *
                                          100
                                        }
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div className='d-flex align-items-left flex-column mt-4 mb-4 w-100'>
                                    Click Edit to set projected value.
                                  </div>
                                )}
                                <a
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 ms-1'
                                  onClick={() => {
                                    setBudgetType(BudgetType.Creations)
                                    setShowBudgetModal(true)
                                    setCurrentBudget(projectedCreations?.toString() ?? '')
                                  }}
                                >
                                  <KTIcon iconName='pencil' className='fs-3' />
                                </a>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>

                      {withBudget ? (
                        <div className='col-md-4 col-xs-6'>
                          <div className='border border-gray-300 rounded min-w-125px py-3 px-4 mb-3'>
                            <div className='d-flex align-items-center'>
                              {/* <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' /> */}
                              <div className='fs-2 fw-bolder'>
                                {formatMoney(releaseStats?.totalBudget ?? 0)}
                              </div>
                            </div>
                            {/* <div className=' fs-6 text-gray-400'>Campaigns: {formatMoney(releaseStats?.campaignBudget ?? 0)} Tasks: {formatMoney(releaseStats?.taskBudget ?? 0)} Assets: {formatMoney(releaseStats?.assetBuget ?? 0)}</div> */}
                            <div className='fw-bold fs-6 text-gray-400'>Marketing Budget</div>
                            {currentUser && currentUser.role != "partner" ? (
                              <>
                                <div className='separator my-3'></div>
                                <div className='pg d-flex align-items-end pt-0'>
                                  {projectedBudget ? (
                                    <div className='d-flex align-items-center flex-column mt-3 w-100'>
                                      <div className='d-flex justify-content-between  w-100 mt-auto mb-2'>
                                        <span className='pg-left clickable' onClick={() => { }}>
                                          {Number(projectedBudget) <
                                            Number(releaseStats?.totalBudget) ? (
                                            <i className='fa fa-circle-check text-success'></i>
                                          ) : null}{' '}
                                          Projected Budget
                                        </span>
                                        <span className='pg-right'>
                                          {formatMoney(Number(projectedBudget))}
                                        </span>
                                      </div>
                                      <div className='h-8px mx-3 w-100 bg-opacity-50 rounded text-success'>
                                        <ProgressBar
                                          variant={
                                            Number(projectedBudget) <
                                              Number(releaseStats?.totalBudget)
                                              ? 'success'
                                              : ''
                                          }
                                          now={
                                            (Number(releaseStats?.totalBudget) /
                                              Number(projectedBudget)) *
                                            100
                                          }
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <div className='d-flex align-items-left flex-column mt-4 mb-4 w-100'>
                                      Click Edit to set projected value.
                                    </div>
                                  )}
                                  <a
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 ms-1'
                                    onClick={() => {
                                      setBudgetType(BudgetType.Budget)
                                      setShowBudgetModal(true)
                                      setCurrentBudget(projectedBudget?.toString() ?? '')
                                    }}
                                  >
                                    <KTIcon iconName='pencil' className='fs-3' />
                                  </a>
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='assets_head'>
          <h3>
            Overview
            {currentUser && currentUser.role != "partner" ? (
              <a
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1 ms-2'
                onClick={() => {
                  setShowOverviewText(!showOverviewText)
                }}
              >
                <KTIcon iconName='pencil' className='fs-3' />
              </a>
            ) : null}
          </h3>
        </div>
        <div className='assets_head mb-50 '>
          {showOverviewText ? (
            overviewText &&
              overviewText?.length > 0 &&
              extractContent(overviewText) &&
              extractContent(overviewText)!.length > 0 ? (
              <div dangerouslySetInnerHTML={{ __html: overviewText ?? '' }} />
            ) :
              currentUser && currentUser.role != "partner" ? ('Click the edit button to add the overview.') : ("Overview not yet available")

          ) : (
            <div className='col-md-6 col-xs-12'>
              <div className='form-group'>
                <ReactQuill theme='snow' value={overviewText} onChange={setOverviewText} />
              </div>
              <div className='form-group mt-5'>
                <Button
                  variant=''
                  onClick={() => {
                    setShowOverviewText(true)
                    setOverviewText(initialOverviewText)
                  }}
                  className='btn btn-sm btn-secondary me-2'
                >
                  Cancel
                </Button>
                <Button
                  variant=''
                  onClick={() => {
                    saveOverview()
                  }}
                  className='btn btn-sm btn-primary'
                >
                  Save
                </Button>
              </div>
            </div>
          )}
        </div>

        <Modal show={showModal} onHide={closeModel}>
          <div className='custom_modal'>
            <Modal.Header closeButton>
              <Modal.Title>Share Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='d-flex flex-column'>
                <div className='d-flex justify-content-between align-items-start flex-wrap mb-3'>
                  <div>
                    <strong>Link with Budget Visible</strong>
                  </div>
                  <div className='form-check form-switch form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value=''
                      id='flexSwitchDefault'
                      checked={linkSharingWithBudgetEnabled}
                      onChange={() => {
                        setLinkSharingWithBudgetEnabled(!linkSharingWithBudgetEnabled)
                        clearTimeout(timeoutId1)
                        timeoutId1 = setTimeout(() => {
                          if (linkSharingWithBudget && linkSharingWithoutBudget)
                            updateLinkSharing(
                              id,
                              !linkSharingWithBudgetEnabled,
                              linkSharingWithoutBudgetEnabled,
                              linkSharingWithBudget,
                              linkSharingWithoutBudget
                            )
                        }, 1000)
                      }}
                    />
                  </div>
                </div>
                {linkSharingWithBudgetEnabled ? (
                  <div className='d-flex  align-items-center mb-3'>
                    <input
                      type='text'
                      className='form-control h-35px'
                      placeholder='Link'
                      value={linkSharingWithBudget}
                      disabled
                    />
                    <button
                      disabled={!linkSharingWithBudgetEnabled}
                      className='btn btn-sm fw-bold btn-primary share-link ms-3 w-150px'
                      onClick={() => {
                        if (linkSharingWithBudget) {
                          navigator.clipboard.writeText(linkSharingWithBudget)
                          toast.success('Link Copied')
                        } else {
                          toast.error('Link Error')
                        }
                      }}
                    >
                      <i className='fa fa-regular fa-copy '></i> Copy Link
                    </button>
                  </div>
                ) : null}
                <div className='separator my-10'></div>

                <div className='d-flex justify-content-between align-items-start flex-wrap mb-3'>
                  <div>
                    <strong>Link with Budget Hidden</strong>
                  </div>
                  <div className='form-check form-switch form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked={linkSharingWithoutBudgetEnabled}
                      id='flexSwitchDefault'
                      onChange={() => {
                        setLinkSharingWithoutBudgetEnabled(!linkSharingWithoutBudgetEnabled)
                        clearTimeout(timeoutId2)
                        timeoutId2 = setTimeout(() => {
                          if (linkSharingWithBudget && linkSharingWithoutBudget)
                            updateLinkSharing(
                              id,
                              linkSharingWithBudgetEnabled,
                              !linkSharingWithoutBudgetEnabled,
                              linkSharingWithBudget,
                              linkSharingWithoutBudget
                            )
                        }, 1000)
                      }}
                    />
                  </div>
                </div>
                {linkSharingWithoutBudgetEnabled ? (
                  <div className='d-flex  align-items-center mb-3'>
                    <input
                      type='text'
                      className='form-control h-35px'
                      placeholder='Link'
                      disabled
                      value={linkSharingWithoutBudget}
                    />
                    <button
                      disabled={!linkSharingWithoutBudgetEnabled}
                      className='btn btn-sm fw-bold btn-primary share-link ms-3 w-150px'
                      onClick={() => {
                        if (linkSharingWithoutBudget) {
                          navigator.clipboard.writeText(linkSharingWithoutBudget)
                          toast.success('Link Copied')
                        } else {
                          toast.error('Link Error')
                        }
                      }}
                    >
                      <i className='fa fa-regular fa-copy '></i> Copy Link
                    </button>
                  </div>
                ) : null}
              </div>
            </Modal.Body>
          </div>
        </Modal>

        <Modal show={showBudgetModal} onHide={handleCloseBudgetModal}>
          <div className='custom_modal'>
            <Modal.Header closeButton>
              <Modal.Title>
                Edit Projected {budgetType == BudgetType.Budget ? 'Budget' : null}
                {budgetType == BudgetType.Creations ? 'Creations' : null}
                {budgetType == BudgetType.Stream ? 'Streams' : null}{' '}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='form-group'>
                    <label className='labels'>Enter Projected Value</label>
                    <input
                      className='form-control'
                      placeholder='Enter Value'
                      type='number'
                      id='name'
                      name='name'
                      value={currentBudget}
                      onChange={handleChangedBudget}
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={handleCloseBudgetModal}>
                Close
              </Button>
              <Button variant='' onClick={handleBudgetSubmit} className='btn-active'>
                Save
              </Button>
            </Modal.Footer>
          </div>
        </Modal>

        {showReleaseUpdateDailog ? <AddUpdateDailog showDailog={showReleaseUpdateDailog} editData={releases} onChange={function (arg0: boolean) {
          setShowReleaseUpdateDailog(arg0)
          refetchRelease()
        }} /> : null}

      </>
    )
  else return <div className='card mb-5 mb-xl-10'>Loading..</div>
}
var timeoutId1: any, timeoutId2: any
export { ReleaseHeader }

import { useEffect, useState } from "react"
import { useQuery } from "react-query";
import { deleteCampaignCategories, getCampaignCategories } from "../../../core/Requests";
import { CampaignCategory } from "../../../core/Models";
import { KTIcon } from "../../../../../../_metronic/helpers";
import { AddUpdateDailog } from "./dialog/AddUpdateDailog";
import { ToastContainer, toast } from "react-toastify";
import DeleteConfirmationDialog from "../../../../global-module/deleteConfirmationDialog/deleteConfirmationDialog";
import { useAuth } from "../../../../auth";


export const CampaignCategoryList = () => {

    const [campaignCategories, setCampagignCategories] = useState<CampaignCategory[]>([]);
    const [page, setPage] = useState<number>(1);
    const [isLoading, setIsLoading] = useState(false);
    const [editingCampaignCategory, setEditingCampaignCategory] = useState<CampaignCategory | undefined>(undefined)
    const [deletingCampaignCategory, setDeletingCampaignCategory] = useState<CampaignCategory | undefined>(undefined)
    const [showDeleteDailog, setShowDeleteDailog] = useState<boolean>(false);
    const [showAddUpdateDailog, setShowAddUpdateDailog] = useState<boolean>(false);
    const { currentUser } = useAuth();

    const {
        isFetching,
        refetch: refectCategoryList,
        data: response,
    } = useQuery(
        `campaignCategory`,
        () => {
            return getCampaignCategories();
        },
        { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: true }
    )
    useEffect(() => {
        //console.log(response);
        if (!isFetching)
            setCampagignCategories(response.data);
    }, [response])
    function handleCancelDelete(): void {
        setShowDeleteDailog(false)
    }

    function handleDelete(): void {
        setIsLoading(true);
        if (deletingCampaignCategory)
            deleteCampaignCategories(deletingCampaignCategory).then(() => {
                toast.success('Campaign Category Deleted Successfully');
            }).catch(() => {
                toast.error('Something went wrong please try again');
            }).finally(() => {
                setIsLoading(false);
                setShowDeleteDailog(false)
                refectCategoryList()
            })
    }

    return (
        <>
            <div className="assets_head  px-3">
                <h3>Campaign Categories</h3>
                <div>
                    <div>
                        {
                            currentUser?.role == 'admin' ?
                                <a className="btn btn-sm fw-bold btn-primary" onClick={() => { setEditingCampaignCategory(undefined); setShowAddUpdateDailog(true) }}><span>+</span> Add New</a>
                                : null
                        }
                    </div>
                </div>
            </div>
            <div className="mb-4 text-gray-600 fw-semibold fs-6">
                <div className="fs-6 ms-1">

                    <div className="mb-4 text-gray-600 fw-semibold fs-6">
                        <div className='card mb-5 mb-xl-8'>
                            <div className='card-body py-3 px-3 border  border-radius-5'>

                                <div className='table-responsive'>
                                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                                        <thead>
                                            <tr className='fw-bold text-muted'>
                                                <th className='min-w-150px w-30'>Name</th>
                                                <th className='w-150px '>Color</th>
                                                <th className="text-end"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {campaignCategories && campaignCategories.length < 1 ? <tr>
                                                <td colSpan={3}>Loading...</td>
                                            </tr> : null}
                                            {campaignCategories.map((item, index) => (
                                                <tr key={index}>
                                                    <td className='min-w-150px w-30'>{item.name}</td>
                                                    <td className='w-150px '><div className="color-box" style={{ background: item.color }}>&nbsp;</div> <span style={{ verticalAlign: "middle" }}>{item.color}</span></td>
                                                    <td className="text-end">
                                                        {currentUser?.role == 'admin' ?
                                                            <>
                                                                <a

                                                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1'
                                                                    onClick={() => { setShowAddUpdateDailog(true); setEditingCampaignCategory(item); }}
                                                                >
                                                                    <KTIcon iconName='pencil' className='fs-3' />
                                                                </a>
                                                                <a
                                                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1'
                                                                    onClick={() => {
                                                                        setDeletingCampaignCategory(item);
                                                                        setShowDeleteDailog(true);
                                                                    }}
                                                                >
                                                                    <KTIcon iconName='trash' className='fs-3' />
                                                                </a>
                                                            </> : null}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showAddUpdateDailog ? <AddUpdateDailog showDailog={showAddUpdateDailog} editData={editingCampaignCategory} onChange={function (arg0: boolean) {
                setShowAddUpdateDailog(arg0)
                refectCategoryList();
            }} /> : null}
            <ToastContainer />
            <DeleteConfirmationDialog
                isOpen={showDeleteDailog}
                onCancel={handleCancelDelete}
                onConfirm={handleDelete}
            />
        </>)

}
import { AuthModel } from './_models'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
export const AUTH_LOCAL_STORAGE_RELEASE_KEY = 'release-id'
const AUTH_LOCAL_STORAGE_RELEASES_KEY = 'releases'
const getAuth = (): AuthModel | undefined => {

  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const authData = JSON.parse(lsValue);
    const auth: AuthModel = {
      api_token: authData.token,
      token: authData.token
    } as AuthModel

    if (auth) {
      // You can easily check auth_token expiration also

      return auth
    }
  } catch (error) {
    console.log(error);
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: { headers: { Authorization: string } }) => {
      const auth = getAuth()
      if (auth && auth.api_token) {
        config.headers.Authorization = `Bearer ${auth.api_token}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}
const getLastRelease = (): string | undefined => {

  let releaseId: string | undefined = undefined;

  if (window.location.pathname.split('/')[1] == 'share')
    releaseId = window.location.pathname.split('/').pop()!;

  if (releaseId != undefined && Number(releaseId) > 0) {
    return releaseId;
  }

  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_RELEASE_KEY)
  if (!lsValue) {
    return
  }
  return lsValue;

}


const setLastRelease = (releaseId: string) => {
  if (!localStorage) {
    return
  }
  try {
    localStorage.setItem(AUTH_LOCAL_STORAGE_RELEASE_KEY, releaseId)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeLastRelease = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_RELEASE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

const saveReleases = (releases: any) => {
  if (!localStorage) {
    return
  }
  try {
    localStorage.setItem(AUTH_LOCAL_STORAGE_RELEASES_KEY, JSON.stringify(releases))
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const getReleases = (): any | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_RELEASES_KEY)
  if (!lsValue) {
    return
  }
  return JSON.parse(lsValue);

}


export { getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY, getLastRelease, setLastRelease, removeLastRelease, saveReleases, getReleases }


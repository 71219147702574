import axios, { AxiosRequestConfig } from 'axios';
import { getToken } from '../helpers/auth';
import { API_URL } from '../modules/dashboard/core/_requests';
import { useParams } from 'react-router-dom';

const instance = axios.create({
  baseURL: `${API_URL}`, // Replace with your API base URL
});

instance.interceptors.request.use((config) => {
  const token = getToken();
  const tenant = window.location.pathname.split("/").pop();
  if (token) {
    if (config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  } else if (tenant) {
    if (config.headers) {
      config.headers.Tenant = tenant;
    }
  }
  return config;
});

export default instance;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

import Loader from '../../../../global-module/loader/loader';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

// Load services
import instance from '../../../../../services/axiosConfig';


const UpdateUserPassword = (props: any) => {

    const { dialogAction, sendData, editId } = props;
    const [show, setShowUser] = useState(dialogAction);
    const [isLoading, setIsLoading] = useState(false);
    const [showPasswod, setShowPassword] = useState(false)
    const [showPasswodConfirmation, setShowPasswordConfirmation] = useState(false)

    const handleClose = () => {
        setShowUser(false);
        sendData(false);
    };

    // Start form

    interface User {
        password: string;
        confirm_password: string;
    }

    const [formData, setFormData] = useState<User>({
        password: '',
        confirm_password: ''
    });

    const [errors, setErrors] = useState({
        password: '',
        confirm_password: '',
        match_password: '',
    });


    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const validateForm = (data: any) => {
        const errors: any = {};

        if (!data.password) {
            errors.password = 'Password is required.';
        }

        if (!data.confirm_password) {
            errors.confirm_password = 'Confirm Password is required.';
        }

        if (data.password != data.confirm_password) {
            errors.match_password = 'Password not match.';
        }

        return Object.keys(errors).length ? errors : null;
    };


    const handleSubmit = async () => {

        const validationErrors = validateForm(formData);
        if (validationErrors) {
            setErrors(validationErrors);
            return;
        }

        // Add data
        setIsLoading(true);
        try {
            const response = await instance.put('/user/' + editId, formData);
            toast.success(response?.data?.message);
            setTimeout(function () {
                setShowUser(false);
                sendData(false);
                setIsLoading(false);
            }, 2000);
        } catch (error) {
            toast.error('something went wrong please try again');
            setTimeout(function () {
                setIsLoading(false);
            }, 2000);
        }
    }
    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <div className="custom_modal">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Update Password
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className='labels'>Password
                                        <span className="btn btn-sm btn-icon  me-n2" onClick={() => {
                                            setShowPassword(!showPasswod);
                                        }}>
                                            <i className={showPasswod ? "bi bi-eye-slash fs-2 " : "bi bi-eye fs-2 "}></i>
                                        </span>
                                    </label>
                                    <input className='form-control' placeholder='Enter Password' type={showPasswod ? 'text' : 'password'} id="password" name="password" value={formData.password} onChange={handleChange} />
                                    {errors.password && formData.password === '' && <div className='error-form'>{errors.password}</div>}
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className='labels'>Confirm Password
                                        <span className="btn btn-sm btn-icon  me-n2" onClick={() => {
                                            setShowPasswordConfirmation(!showPasswodConfirmation);
                                        }}>
                                            <i className={showPasswodConfirmation ? "bi bi-eye-slash fs-2 " : "bi bi-eye fs-2 "}></i>
                                        </span>
                                    </label>
                                    <input className='form-control' placeholder='Enter Password' type={showPasswodConfirmation ? 'text' : 'password'} id="confirm_password" name="confirm_password" value={formData.confirm_password} onChange={handleChange} />
                                    {errors.confirm_password && formData.confirm_password === '' && <div className='error-form'>{errors.confirm_password}</div>}
                                    {errors.match_password && formData.confirm_password !== '' && <div className='error-form'>{errors.match_password}</div>}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="" onClick={handleSubmit} className='btn-active'>
                            Update
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal>
            <ToastContainer />
        </>
    );
}

export default UpdateUserPassword;
import React, { useState, useEffect, ChangeEvent } from 'react';
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Exception } from 'sass';
import { Filter, Release, TaskCategory } from '../../dashboard';
import { postTaskCategories, putTaskCategories } from '../../settings/core/Requests';
import Loader from '../../global-module/loader/loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import { formatDate, generateQuery } from '../../dashboard/core/Helpers';
import instance from '../../../services/axiosConfig';
import { SearchInner } from '../../global-module/components/SearchInner';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { getRelease, postRelease, updateRelease } from '../../dashboard/core/_requests';
import { useCommonContext } from '../../../services/CommonContext';

let cancelToken: any
interface Props {
    showDailog: any,

    editData: any,
    onChange(arg0: boolean): any;
}
export const AddUpdateDailog: React.FC<Props> = ({ showDailog, editData, onChange }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1)
    const [listReleases, setReleases] = useState<any[]>([])
    const [totalRelease, setTotalRelease] = useState(0)
    const [filters, setFilters] = useState<Filter[]>([])
    const [searchText, setSearchText] = useState<any>('')
    const [checkedRelease, setCheckedRelease] = useState<any[]>([])
    const { releaseUpdated, setReleaseUpdated } = useCommonContext()

    const handleClose = () => {
        onChange(false);
    };

    useEffect(() => {
        // if (editData && editData.id) {
        //     setFormData(editData);
        // }
    }, []);


    useEffect(() => {
        if (editData)
            getRelease(editData.id)
                .then((d) => {
                    setCheckedRelease(d.child)
                })
    }, [editData]);
    useEffect(() => {

        fetchReleases(page.toString()).then((d) => {
            setReleases(d)
        })
    }, [filters])


    useEffect(() => {
        setPage(1)
        setFilters([
            {
                name: 'search',
                value: [searchText],
            },
        ])
        console.log(searchText)
    }, [searchText])

    useEffect(() => {
        let childReleases: number[] = [];
        checkedRelease.map((c: any, i: number) => {
            childReleases.push(c.id);
        })
        setFormData({ ...formData, ['release_ids']: childReleases });
    }, [checkedRelease])

    const fetchReleases = async (page?: string) => {
        try {
            if (typeof cancelToken != typeof undefined) {
                cancelToken.cancel('canceled')
            }

            cancelToken = axios.CancelToken.source()
            setIsLoading(true)

            let response;
            response = (
                await instance.get('/release' + generateQuery(filters) + ' &type=single&page=' + (page ? page : 1), {
                    cancelToken: cancelToken.token,
                })
            ).data.data

            let listoutReleases = response.data
            setTotalRelease(response.total)

            let findReleasesArray: any = []
            findReleasesArray = listoutReleases

            setIsLoading(false)

            return findReleasesArray
        } catch (e: any) {
            console.log(e)
            if (e.message != 'canceled') setIsLoading(false)
        }
        return []
    }

    // console.log(checkedRelease);

    async function checkHandler(event: ChangeEvent<HTMLInputElement>) {
        let currentRelease = listReleases.find((e, i) => e.id == parseInt(event.currentTarget.value));
        console.log(currentRelease);
        if (checkedRelease.findIndex((e, i) => e.id == parseInt(event.currentTarget.value)) == -1) {
            setCheckedRelease([...checkedRelease, currentRelease])
        }
        else {
            setCheckedRelease(checkedRelease.filter(function (item) {
                return item.id !== parseInt(event.currentTarget.value)
            }))
        }
    }

    const perPage = 30

    const [formData, setFormData] = useState<any>({

        name: editData?.name || '',
        artist: editData?.artist || '',
        release_date: editData?.release_date || '',
        release_ids: []
    });

    const [errors, setErrors] = useState({
        name: '',
        artist: '',
        release_date: '',
        release_ids: '',
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const validateForm = (data: any) => {
        const errors: any = {};

        if (!data.name) {
            errors.name = 'Title is required.';
        }
        if (!data.artist) {
            errors.artist = 'Artist is required.';
        }

        if (!data.release_date) {
            errors.release_date = 'Release Date is required.';
        }
        return Object.keys(errors).length ? errors : null;
    };

    const handleSubmit = () => {

        const validationErrors = validateForm(formData);
        // console.log(validationErrors);
        if (validationErrors) {
            setErrors(validationErrors);
            return;
        }

        if (editData && editData.id) {

            // Edit data
            setIsLoading(true);

            updateRelease(editData.id, formData).then((response: any) => {
                console.log(response);
                toast.success(response?.message);
                setReleaseUpdated(!releaseUpdated)
            }).catch((e: any) => {
                //console.log(e)
                toast.error(e?.response?.data?.message || 'Something went wrong please try again');
            }).finally(() => {
                onChange(false);
                setIsLoading(false);
            })

        }
        else {
            // Add data
            setIsLoading(true);
            console.log(formData);

            postRelease(formData).then((response: any) => {
                console.log(response);
                toast.success(response?.message);
            }).catch((e: any) => {
                toast.error(e?.response?.data?.message || 'Something went wrong please try again');
            }).finally(() => {
                onChange(false);
                setIsLoading(false);
            })
        }
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (<></>)}

            <Modal show={showDailog} onHide={handleClose}>
                <div className="custom_modal">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {editData && editData.id ? 'Update Release Bundle' : 'Add New Release Bundle'}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className='labels'>Bundle Name</label>
                                    <input className='form-control' placeholder='Enter Name' type="text" id="name" name="name" value={formData.name} onChange={handleChange} />
                                    {errors.name && formData.name === '' && <div className='error-form'>{errors.name}</div>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className='labels'>Artist</label>
                                    <input className='form-control' placeholder='Enter Artist' type="text" id="artist" name="artist" value={formData.artist} onChange={handleChange} />
                                    {errors.artist && formData.artist === '' && <div className='error-form'>{errors.artist}</div>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className='labels'>Release Date</label>
                                    <input className='form-control' placeholder='Enter Name' type="date" id="releaseDate" name="release_date" value={formData.release_date} onChange={handleChange} />
                                    {errors.release_date && formData.release_date === '' && <div className='error-form'>{errors.release_date}</div>}
                                </div>
                            </div>
                            <div className="separator my-3"></div>
                            <InfiniteScroll
                                dataLength={page * perPage}
                                next={() => {
                                    fetchReleases((page + 1).toString()).then((d) => {
                                        setReleases([...listReleases, ...d])
                                        setPage(Number(page) + 1)
                                    })
                                }}

                                hasMore={page * perPage < totalRelease}
                                loader={null}
                                height={400}
                                endMessage={
                                    <p style={{ textAlign: 'center' }} className='text-white'>
                                        {/* <b>Yay! You have seen it all</b> */}
                                    </p>
                                }
                            >
                                <SearchInner initalValue={searchText} onChange={function (arg0: string) {
                                    console.log(arg0)
                                    setSearchText(arg0)
                                }}></SearchInner>

                                {!isLoading && listReleases.length < 1 ? (
                                    <div className=' text-center mb-2'>No Record found</div>
                                ) : null}
                                {checkedRelease.map((releasesData, i) => (
                                    <ListItem releasesData={releasesData} checkedRelease={checkedRelease} checkHandler={checkHandler}></ListItem>

                                ))}
                                {listReleases.map((releasesData, i) => {

                                    let show: boolean = (checkedRelease.findIndex((e, i) => e.id == releasesData.id) == -1);

                                    return <ListItem releasesData={releasesData} checkedRelease={checkedRelease} checkHandler={checkHandler} show={show}></ListItem>
                                })}
                                {isLoading ? <div className=' text-center mb-2'>Loading...</div> : null}
                            </InfiniteScroll>


                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="" onClick={handleSubmit} className='btn-active'>
                            {editData && editData.id ? 'Update Bundle' : 'Save Bundle'}
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal>
            {/* <ToastContainer /> */}
        </>
    )
}

interface ListItemProps {
    releasesData: any,
    checkedRelease: any[],
    checkHandler: any,
    show?: boolean | true
    // onChange(arg0: string): any;
}
const ListItem: React.FC<ListItemProps> = ({ releasesData, checkedRelease, checkHandler, show = true }) => {

    return (
        <div className='menu-item' hidden={!show}>
            <span className='menu-link px-3 py-3 active' data-kt-element='project'>
                <div className=' d-flex me-4 form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                        className='form-check-input widget-13-check'
                        type='checkbox'
                        checked={checkedRelease.findIndex((e, i) => e.id == parseInt(releasesData?.id)) != -1}
                        value={releasesData?.id}
                        onChange={checkHandler}
                    // disabled={releasesData?.already_imported ? true : false}
                    />
                </div>

                <span className='d-flex flex-center flex-shrink-0 w-40px me-3'>
                    <img
                        alt='Logo'
                        src={releasesData?.image_url}
                        data-kt-element='logo'
                        width={45}
                    />
                </span>
                <span className='d-flex flex-column align-items-start'>
                    <span className='fs-5 fw-bold text-black' data-kt-element='title'>
                        {releasesData?.name}&nbsp;
                        {releasesData.internal_tag && releasesData.internal_tag.length > 0 ? (
                            <img
                                className='mb-2'
                                src={toAbsoluteUrl('/media/misc/note.png')}
                                id='dropdownUserAction'
                                data-bs-toggle='dropdown'
                                aria-expanded='false'
                            ></img>
                        ) : null}
                    </span>
                    <span className='fs-7 fw-bold text-gray-700 lh-sm' data-kt-element='desc'>
                        {releasesData?.artist} &#9679; Added {formatDate(releasesData?.release_date + 'T00:00:00.000-08:00')}
                    </span>
                </span>
                {/* {
                releasesData?.already_imported ? <span className='d-flex flex-center flex-shrink-0  m-l-auto'>
                    <a
                        className='text-danger'
                        onClick={() => {
                            setOpenDeleteDailog(true);
                            setRemoveReleaseCandidate(releasesData?.id);
                            setToBeDeleted(releasesData?.total_campaigns + ' Campaigns, ' + releasesData?.total_tasks + ' Tasks, ' + releasesData?.total_assets + ' Assets will be deleted.');
                        }}
                    ><i className='fa text-danger fa-trash'></i> Remove Release</a>
                </span> :
                    null
            } */}


            </span>
            <div className="separator my-3"></div>
        </div>


    )
}

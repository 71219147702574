import React from 'react';

const Loader: React.FC = () => {
  return (
    <div className="cust_loader">
      <span className="spinner-border spinner-border-lg  align-middle ms-2"></span>
    </div>
  );
};

export default Loader;
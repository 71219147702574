import { useEffect, useState } from "react"
import { useQuery } from "react-query";


import { AddUpdateDailog } from "./dialog/AddUpdateDailog";
import { ToastContainer, toast } from "react-toastify";
import { Release, TaskCategory } from "../dashboard";
import { useAuth } from "../auth";
import { deleteTaskCategories, getTaskCategories } from "../settings/core/Requests";
import { KTIcon } from "../../../_metronic/helpers";
import DeleteConfirmationDialog from "../global-module/deleteConfirmationDialog/deleteConfirmationDialog";
import { deleteRelease, getRelease, unlinkRelease } from "../dashboard/core/_requests";
import Pagination from "../global-module/pagination/pagination";
import { DeleteConfirmationDialogWithTerms } from "../dashboard/components/releases/dailog/add-release";
import { useCommonContext } from "../../services/CommonContext";
import { runtimeEnv } from "../../../env";



export const ReleaseBundleList = () => {

    const [releaseBundle, setReleaseBundle] = useState<any>([]);
    const [expandedReleaseBundle, setExpandedReleaseBundle] = useState<any>([]);
    const [page, setPage] = useState<number>(1);
    const [isLoading, setIsLoading] = useState(true);
    const [editingReleaseBundle, setEditingReleaseBundle] = useState<Release | undefined>(undefined)
    const [deletingReleaseBundle, setDeletingReleaseBundle] = useState<Release | undefined>(undefined)
    const [showDeleteDailog, setShowDeleteDailog] = useState<boolean>(false);
    const [showAddUpdateDailog, setShowAddUpdateDailog] = useState<boolean>(false);
    const { currentUser, saveRelease } = useAuth();
    const { releaseDeleted, setReleaseDeleted, releaseUpdated, setReleaseUpdated } = useCommonContext()
    const {
        isFetching,
        refetch: refetchReleaseBundle,
        data: response,
    } = useQuery(
        `releaseBundle`,
        () => {
            setIsLoading(false)
            return getRelease("?type=bundle&perpage=10&page=" + page);
        },
        { cacheTime: 1000, keepPreviousData: false, refetchOnWindowFocus: true }
    )
    useEffect(() => {
        if (response)
            setReleaseBundle(response.data);
    }, [response, isLoading])

    useEffect(() => {
        refetchReleaseBundle();
    }, [page])

    function handleCancelDelete(): void {
        setShowDeleteDailog(false)
    }

    function handleDelete(): void {
        setIsLoading(true);
        if (deletingReleaseBundle)
            deleteRelease(deletingReleaseBundle.id).then(() => {
                setReleaseDeleted(Number(deletingReleaseBundle.id))
                saveRelease(undefined);
                toast.success('Release Bundle Deleted Successfully');
            }).catch(() => {
                toast.error('Something went wrong please try again');
            }).finally(() => {
                setIsLoading(false);
                setShowDeleteDailog(false)
                refetchReleaseBundle()
            })
    }

    function unlinkReleaseFromBundle(bundleId: any, releaseId: any): void {
        setIsLoading(true);

        unlinkRelease(bundleId, releaseId).then(() => {
            setReleaseUpdated(!releaseUpdated)
            toast.success('Release unlinked from bundle successfully');
        }).catch(() => {
            toast.error('Something went wrong please try again');
        }).finally(() => {
            setIsLoading(false);

            refetchReleaseBundle()
        })
    }

    return (
        <>
            <div className="assets_head px-3">
                <h3>Release Bundle</h3>
                <div>
                    <div>
                        {
                            currentUser?.role == 'admin' ?
                                <a className="btn btn-sm fw-bold btn-primary" onClick={() => { setEditingReleaseBundle(undefined); setShowAddUpdateDailog(true) }}><span>+</span> Add Bundle</a>
                                : null
                        }
                    </div>
                </div>
            </div>
            <div className="mb-4 text-gray-600 fw-semibold fs-6">
                <div className="fs-6 ms-1">

                    <div className="mb-4 text-gray-600 fw-semibold fs-6">
                        <div className='mb-5 mb-xl-8'>
                            <div className=' py-3 '>

                                {releaseBundle.length < 1 && !isLoading ? <div className="ps-10">
                                    <div className="d-flex align-items-center mb-2 ">
                                        List is empty. Click add to create bundle release!
                                    </div>
                                </div> : null}
                                {releaseBundle.map((item: any, index: number) => (
                                    <div key={index} className='custom_according'>
                                        <div
                                            className={'d-flex align-items-center collapsible py-3 toggle mb-0 accordion-heading collapsed '}
                                            data-bs-toggle='collapse'
                                            data-bs-target={`#kt_job_4_${index}`} >
                                            <div className='btn btn-sm btn-icon mw-20px btn-active-color-primary me-5'>
                                                <i className='ki-solid ki-up toggle-on fs-1'></i>
                                                <i className='ki-solid ki-down toggle-off fs-1'></i>
                                            </div>
                                            <span className="d-flex flex-center flex-shrink-0 w-40px me-3 ">
                                                <img
                                                    alt='Logo'
                                                    src={item.image_url ? item.is_bundle ? runtimeEnv.REACT_APP_URL + item.image_url : item.image_url : '/media/svg/menu/bundles-icon-blue.svg'}
                                                    data-kt-element='logo'
                                                    width={45}
                                                />

                                            </span>
                                            <h4 className=' fw-bold cursor-pointer mb-0'>
                                                {item.name}
                                            </h4>
                                            {currentUser?.role == 'admin' ?
                                                <div className="ml-auto">
                                                    <a className="btn btn-outline btn-sm me-2 ouline-primary"
                                                        href={'/share/' + item?.id}
                                                        onClick={(e) => {
                                                            window.location.href = '/share/' + item?.id;
                                                            e.stopPropagation();
                                                            e.nativeEvent.stopImmediatePropagation();
                                                        }}
                                                    >Open</a>
                                                    <a
                                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2 mb-1'
                                                        onClick={() => { setShowAddUpdateDailog(true); setEditingReleaseBundle(item) }}
                                                    >
                                                        <KTIcon iconName='pencil' className='fs-3' />
                                                    </a>
                                                    <a
                                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2 mb-1'
                                                        onClick={() => {
                                                            setDeletingReleaseBundle(item);
                                                            setShowDeleteDailog(true)
                                                        }}
                                                    >
                                                        <KTIcon iconName='trash' className='fs-3' />
                                                    </a>
                                                </div> : null}

                                        </div>

                                        <div id={`kt_job_4_${index}`} className={'collapse fs-6 ms-1'}>
                                            <div className='mb-4 text-gray-600 fw-semibold fs-6'>
                                                <div className='fs-6 ms-1'>
                                                    <div className='mb-4 text-gray-600 fw-semibold fs-6'>
                                                        <div className='card mb-5 mb-xl-8'>
                                                            <div className='card-body py-3 px-3 '>
                                                                {item.child.length < 1 ? <div className="ps-10">
                                                                    <div className="d-flex align-items-center mb-2 ">
                                                                        Your bundle is empty. Edit to add releases!
                                                                    </div>
                                                                </div> : null}
                                                                {item.child.map((cItem: any, index: number) => (
                                                                    <div className="ps-10">
                                                                        <div className="d-flex align-items-center mb-2 ">

                                                                            <span className="-flex flex-center flex-shrink-0 w-40px me-3 symbol ">
                                                                                <img
                                                                                    alt='Logo'
                                                                                    src={cItem.is_bundle ? runtimeEnv.REACT_APP_URL + cItem.image_url : cItem.image_url}
                                                                                    data-kt-element='logo'
                                                                                    width={45}
                                                                                />
                                                                            </span>
                                                                            <div className="d-flex flex-column">
                                                                                <h4 className=' fw-bold mb-0 ms-3'>
                                                                                    {cItem.name}
                                                                                </h4>
                                                                                <span className="ms-3">{cItem.artist}</span>
                                                                            </div>
                                                                            {currentUser?.role == 'admin' ?
                                                                                <div className="ml-auto">
                                                                                    <a
                                                                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb-1'
                                                                                        onClick={() => {
                                                                                            // setDeletingReleaseBundle(item);
                                                                                            // setShowDeleteDailog(true)
                                                                                            unlinkReleaseFromBundle(item.id, cItem.id)
                                                                                        }}
                                                                                    >
                                                                                        <KTIcon iconName='trash' className='fs-3' />
                                                                                    </a>
                                                                                </div> : null}

                                                                        </div>
                                                                        <div className="separator my-3"></div>
                                                                    </div>
                                                                ))}


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                <Pagination
                    currentPage={page}
                    totalPages={Math.ceil(response?.total / response?.per_page)}
                    onNextPage={() => { setPage(page + 1) }}
                    onPreviousPage={() => { setPage(page - 1) }}
                    onGoToPage={(page: number) => { setPage(page) }}
                />
            }
            {showAddUpdateDailog ? <AddUpdateDailog showDailog={showAddUpdateDailog} editData={editingReleaseBundle} onChange={function (arg0: boolean) {
                setShowAddUpdateDailog(arg0)
                refetchReleaseBundle()
            }} /> : null}
            <ToastContainer />
            <DeleteConfirmationDialogWithTerms
                isOpen={showDeleteDailog}
                onCancel={handleCancelDelete}
                onConfirm={handleDelete}
                body="All your data, including tasks, campaigns, and assets, will be permanently removed."
            />
        </>)
}
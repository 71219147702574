/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, Suspense, useEffect, useState } from 'react'
import { Navigate, Outlet, Route, Routes, useParams } from 'react-router-dom'
import { WithChildren, toAbsoluteUrl } from '../../../_metronic/helpers'
import { Common } from './components/Common'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../../_metronic/assets/ts/_utils'
import { useAuth } from '../auth/core/Auth'
import { ReleaseHeader } from './components/ReleaseHeader'
import { Trends } from './components/Trends'
import { Assets } from './components/assets/Assets'
import { Tasks } from './components/tasks/Tasks'
import { useCommonContext } from '../../services/CommonContext'
import Budget from './components/Budget'
import { CategoryBudget, ReleaseStats } from './core/_models'
import { getRelease, getReleaseStats, syncRelease } from './core/_requests'
import { useQuery } from 'react-query'
import { Error404 } from '../errors/components/Error404'
import { ErrorsPage } from '../errors/ErrorsPage'
import { ToastContainer } from 'react-toastify'
import CryptoJS from "crypto-js";

export const DashboardLayout = () => {
  useEffect(() => {
    syncRelease();
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  const { currentUser } = useAuth()

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'

    >
      {/* begin::Content */}
      <div className='d-flex flex-column flex-column-fluid'>
        <div className='app-content  flex-column-fluid'>
          <div className={"app-container " + (!currentUser ? 'container-xxl' : '')}>
            <Common>
              <Outlet />
            </Common>
          </div>
        </div>
      </div>
    </div>
  )
}

const DasboardPage = ({ withEncData = false }) => {
  const { id, data, tenant }: any = useParams<any>();

  const { currentUser, release, saveRelease, setCurrentUser } = useAuth();
  const [releaseStats, setReleaseStats] = useState<ReleaseStats | undefined>(undefined);
  const { budgetUpdated } = useCommonContext()
  const [showError, setShowError] = useState<boolean>(false);
  const [withBudget, setWithBudget] = useState<boolean>(true);
  const {
    data: releaseStatsData,
    refetch: refetchStats,
    error: error
  } = useQuery(
    `releaseStats`,
    () => {
      if (release)
        return getReleaseStats(release);
    },
    { cacheTime: 100, keepPreviousData: true, refetchOnWindowFocus: false }
  )


  const {
    data: releases,
  } = useQuery(
    `releases`,
    () => {
      if (release)
        return getRelease(release);
    },
    { cacheTime: 100, keepPreviousData: true, refetchOnWindowFocus: false }
  )


  useEffect(() => {

    refetchStats();
  }, [budgetUpdated, release])

  useEffect(() => {
    if (withEncData) {
      setCurrentUser(undefined)
      if (release != id)
        saveRelease(id);
      const bytes = CryptoJS.AES.decrypt(data, "iGrooveSecret");
      try {
        if (bytes.toString(CryptoJS.enc.Utf8) == "b") {
          setWithBudget(true)
        } else if (bytes.toString(CryptoJS.enc.Utf8) == "wb") {
          setWithBudget(false)
        } else {

          setShowError(true);
        }
      } catch (e) {
        setShowError(true);
      }
    }
    if (currentUser && currentUser?.role == "partner") {
      setWithBudget(false)
    }
  }, [])

  useEffect(() => {
    if (releases) {
      let k = releases
      if (k.id == release) {

        if (withBudget && k.link_sharing_with_budget_enabled && showError === false) {
          setShowError(false);

        }
        else if (!withBudget && k.link_sharing_without_budget_enabled && showError === false) {
          setShowError(false);

        } else {
          setShowError(true);

        }

      }
    }
  }, [releases]);

  useEffect(() => {

    if (releaseStatsData != undefined) {
      let taskBudgetCount = 0, campaignBudgetCount = 0, assetBudgetCount = 0;

      let taskCategoryBudget: CategoryBudget[] = [];
      releaseStatsData.task_category_budget_list.map((e: any) => {
        taskBudgetCount += e.count;
        let categoryBudget = Number(releaseStatsData.task_budget.total)

        if (categoryBudget > 0)
          e.percentage = e.budget / categoryBudget * 100

        taskCategoryBudget = [...taskCategoryBudget, e];
      })

      let campaignCategoryBudget: CategoryBudget[] = [];
      releaseStatsData.campaign_category_budget_list.map((e: any) => {
        campaignBudgetCount += e.count;
        let categoryBudget = Number(releaseStatsData.campaign_budget.total)

        if (categoryBudget > 0)
          e.percentage = e.budget / categoryBudget * 100

        campaignCategoryBudget = [...campaignCategoryBudget, e];
      })

      let assetCategoryBudget: CategoryBudget[] = [];
      releaseStatsData.asset_category_budget_list.map((e: any) => {
        assetBudgetCount += e.count;
        let categoryBudget = Number(releaseStatsData.asset_budget.total)

        if (categoryBudget > 0)
          e.percentage = e.budget / categoryBudget * 100

        assetCategoryBudget = [...assetCategoryBudget, e];
      })

      let releaseStats: ReleaseStats = {
        currentMonthlyListeners: releaseStatsData.current.monthlyListeners ?? '0',
        currentTotalCreations: releaseStatsData.current.totalCreations ?? '0',
        currentTotalStreams: releaseStatsData.current.totalStreams ?? '0',
        growthMonthlyListeners: releaseStatsData.current.monthlyListeners ?? '0',
        growthTotalCreations: releaseStatsData.current.totalCreations ?? '0',
        growthTotalStreams: releaseStatsData.current.totalStreams ?? '0',
        totalBudget: releaseStatsData.budget.total ?? 0,
        taskBudget: Number(releaseStatsData.task_budget.total) ?? 0,
        campaignBudget: Number(releaseStatsData.campaign_budget.total) ?? 0,
        assetBuget: Number(releaseStatsData.asset_budget.total) ?? 0,
        taskCategoryBudget: taskCategoryBudget,
        campaignCategoryBudget: campaignCategoryBudget,
        assetCategoryBudget: assetCategoryBudget,
        taskBudgetCount: taskBudgetCount,
        campaignBudgetCount: campaignBudgetCount,
        assetBudgetCount: assetBudgetCount
      }
      setReleaseStats(releaseStats);
    }

  }, [releaseStatsData])

  let hasAccess = false;

  if (currentUser?.role == 'manager')
    currentUser?.releases?.map((r) => {
      if (release == r.id.toString())
        hasAccess = true;
    })
  else
    hasAccess = true;

  if (!id && release && hasAccess) {
    console.log(release);
    console.log('test');
    return <Navigate to={'/share/' + release} />
  }

  if (currentUser)
    return (
      currentUser?.role == 'manager' && currentUser?.releases && currentUser?.releases?.length < 1 ? <div>
        You haven't been granted access to any release. Please reach out to your Administrator for assistance.
      </div> : !release ? <div>Loading</div> : <>
        <ReleaseHeader releaseStats={releaseStats} withBudget={withBudget}></ReleaseHeader >
        {
          releases?.is_bundle && releases?.child.length < 1 ?
            (<p className='mb-50'> <div className='assets_head'><h3>Trends</h3></div> <span>When you add releases to this bundle, Trends data will appear here.</span></p>) : (<Trends></Trends>)}
        <Assets></Assets>
        {currentUser?.role != 'partner' ?
          <Budget releaseStats={releaseStats}></Budget> : null}
        <Tasks></Tasks>
        <ToastContainer />
      </>
    )

  else if (!currentUser && releaseStatsData) {
    if (releases && showError) {
      return <ErrorsPage />
    }
    else if (!withBudget)
      return (<>
        <ReleaseHeader withBudget={withBudget} releaseStats={releaseStats}></ReleaseHeader >
        <Trends></Trends>
        <Assets></Assets>
        {/* <Budget releaseStats={releaseStats}></Budget> */}
        <Tasks withBudget={withBudget}></Tasks>
      </>)
    else if (!showError) {
      return (<>
        <ReleaseHeader withBudget={withBudget} releaseStats={releaseStats}></ReleaseHeader >
        <Trends></Trends>
        <Assets></Assets>
        <Budget releaseStats={releaseStats}></Budget>
        <Tasks withBudget={withBudget}></Tasks>
      </>)
    }
    else {
      return <div>Loading</div>
    }
  }
  else if (error) {
    return <ErrorsPage />
  }
  else {
    return <div>Loading</div>
  }
}

const ApgRedirection = () => {
  return (
    <Navigate to="apg" />
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}
export { DasboardPage, ApgRedirection }

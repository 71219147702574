import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { KTIcon } from '../../../../../_metronic/helpers'
import { ToastContainer, toast } from 'react-toastify';

// User Dialog
import AddUser from './dialog/add-new-user';
import UpdateUserPassword from './dialog/update-password';

// Load other component
import DeleteConfirmationDialog from '../../../global-module/deleteConfirmationDialog/deleteConfirmationDialog';

import CheckboxMultipleSelect from '../../../dashboard/components/multi-select-dropdown/MultiSelectionDropdown';


// Load services
import instance from '../../../../services/axiosConfig';
import { capitalizeFirstLetter, getInitals } from '../../../dashboard/core/Helpers';
import SingleSelectionDropdown from '../../../dashboard/components/custom-select-option/SingleSelectionDropdown';
import InfiniteScroll from 'react-infinite-scroll-component';
let cancelToken: any;
let cancelToken1: any;
export function UserList() {

  const [show, setShow] = useState(false);
  const [currentRole, setCurrentRole] = useState(localStorage.getItem('role'));
  const [listUser, setUserList] = useState<any[]>([]);
  const [userId, setuserId] = useState(0);
  const [singleUser, setSingleUser] = useState<any>();
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isDeleteId, setDeleteId] = useState(0);
  const [mouseLocation, setMouseLocation] = useState(-1);
  const [listReleases, setReleases] = useState<any[]>([]);
  const [releasePage, setReleasePage] = useState<number>(1);
  const [userPage, setUserPage] = useState<number>(1);
  const [totalUsers, setTotalUsers] = useState<number>(0);
  const [totalRelease, setTotalRelease] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const [choiceReleases, setchoiceReleases] = useState<any[]>([]);
  const [roleFilter, setRoleFilter] = useState<string | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const [releaseSearchTerm, setReleaseSearchTerm] = useState<string | undefined>(undefined);

  const handleShow = (param: any) => {
    setShow(param);
    fetchUserList();
    setuserId(0);
  };


  useEffect(() => {
    fetchReleases();
  }, [releasePage, releaseSearchTerm]);

  useEffect(() => {
    setUserPage(1)
  }, [roleFilter, choiceReleases, searchTerm])

  useEffect(() => {
    fetchUserList();
  }, [userPage, roleFilter, choiceReleases, searchTerm])

  const fetchUserList = async () => {
    setIsLoading(true);
    let filter: string[] = [];

    if (searchTerm)
      filter = [...filter, 'search=' + searchTerm];

    if (roleFilter)
      filter = [...filter, 'role=' + roleFilter];

    if (choiceReleases.length)
      choiceReleases.map((i) => {

        filter = [...filter, 'releases[]=' + i.id];
      })

    filter = [...filter, 'page=' + userPage];
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("canceled")
    }
    try {

      //Save the cancel token for the current request
      cancelToken = axios.CancelToken.source()
      const response = await instance.get('/user?' + filter.join('&'), { cancelToken: cancelToken.token });

      if (userPage == 1)
        setUserList(response.data?.data);
      else
        setUserList([...listUser, ...response.data?.data])

      setTotalUsers(response.data?.total)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  };

  const fetchReleases = async () => {
    if (typeof cancelToken1 != typeof undefined) {
      cancelToken1.cancel('canceled')
    }
    let releaseSelector: any = [];
    cancelToken1 = axios.CancelToken.source()
    try {
      //setIsLoading(true);
      const response = await instance.get('/release?page=' + releasePage + (releaseSearchTerm ? '&search=' + releaseSearchTerm : ''), {
        cancelToken: cancelToken1.token,
      });
      var fetchResponse = response.data.data.data;
      for (const k in fetchResponse) {
        releaseSelector = [...releaseSelector, { label: fetchResponse[k].name, id: parseInt(fetchResponse[k].id) },]
      }
      if (releasePage == 1)
        setReleases(releaseSelector);
      else
        setReleases([...listReleases, ...releaseSelector]);
      setTotalRelease(Number(response.data.total));
      //setIsLoading(false);
    } catch (error) {
      //setIsLoading(false);'
      console.error(error)
    }
  };

  const searchRelease = async (searchTerm: string) => {
    setReleaseSearchTerm(searchTerm)
    setReleasePage(1)
  }
  // Edit Action


  const editiUser = (param: any, itemId: number, currentUser: any) => {
    setSingleUser(currentUser);
    setuserId(itemId);
    setShow(param);
  }

  // Update User Password
  const [showPasswordDialog, setShowPasswordDialo] = useState(false);

  const openUserPassword = (param: any, itemId: number) => {
    setuserId(itemId);
    setShowPasswordDialo(param);
  }

  const handlePasswordDialogShow = (param: any) => {
    setShowPasswordDialo(param);
    setuserId(0);
  };

  // Delete Assets



  const deleteUserDialogOpen = (param: any, itemId: number, user: any) => {
    setSingleUser(user);
    setDeleteId(itemId);
    setDeleteDialogOpen(param);
  }

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false);
  };

  const handleDelete = async () => {
    try {
      const response = await instance.delete('/user/' + isDeleteId);
      setDeleteDialogOpen(false);
      toast.success('Delete User Successfully');
      fetchUserList();
    } catch (error) {
      toast.error('Delete User Failed');
      setDeleteDialogOpen(false);
      fetchUserList();
    }
  };
  const onchange = function (arg0: any[]) {
    setchoiceReleases(arg0);
  }

  return (
    <>
      <DeleteConfirmationDialog
        isOpen={isDeleteDialogOpen}
        onCancel={handleCancelDelete}
        onConfirm={handleDelete}
        message={singleUser?.tasks_count > 0 ? 'User ' + singleUser?.email + ' has ' + singleUser?.tasks_count + ' tasks assigned. If you proceed to delete this user, these tasks will become unassigned.\n Are you sure you want to continue?' : 'Are you sure you want to delete?'}
      />
      <div className="assets_head px-3">

        <h3>Project Users</h3>
        <div className="assets_head">
          <div className='card-title'>
            {/* begin::Search */}
            <div className='d-flex align-items-center position-relative my-1'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search user'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            {/* end::Search */}
          </div>

          <div>
            {currentRole == 'admin' ?
              <div>
                <SingleSelectionDropdown options={["All Users", "Admin", "Manager", "Partner"]} filterName='All Users' onChange={(arg0: any) => {
                  if (arg0 == "Admin")
                    setRoleFilter('admin')
                  else if (arg0 == "Manager")
                    setRoleFilter('manager')
                  else if (arg0 == "Partner")
                    setRoleFilter('partner')
                  else
                    setRoleFilter(undefined)
                }} />

                <CheckboxMultipleSelect options={listReleases} enableSelectedOption={false} selectedOption={[]}
                  onChange={onchange} text='Releases'
                  total={totalRelease}
                  loadMore={() => {
                    setReleasePage(releasePage + 1)
                  }}
                  dropdownHeight={200}
                  enableSearch={true}
                  onSearch={searchRelease}
                />

                <a className="btn btn-sm fw-bold btn-primary ms-3" onClick={() => { handleShow(true) }}><span>+</span> Add User</a>
              </div> : null}
          </div>
        </div>
      </div>
      {/* <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>
          Users
          <span className='fs-6 text-gray-400 fw-bold ms-1'>({listUser.length})</span>
        </h3>
        {currentRole == 'admin' ?
          <div className='d-flex my-2'>
            <a className="cust_link_btn" onClick={() => handleShow(true)}><span>+</span> Add User</a>
          </div> : null}
      </div> */}

      <div className=''>
        <InfiniteScroll
          dataLength={listUser.length}
          next={() => { setUserPage(userPage + 1) }}
          hasMore={listUser.length < totalUsers}
          loader={<h4>Loading...</h4>}

          className='row g-6 g-xl-9'
          endMessage={
            <p style={{ textAlign: "center" }} className='text-white'>
              {/* <b>Yay! You have seen it all</b> */}
            </p>
          }
        >
          {listUser.length == 0 && !isLoading ? <div className="col-md-12">No results found for your search criteria</div> : null}
          {listUser.map((userData, i) => (
            <div className='col-md-6 col-xxl-4 '>
              <div className='card' onMouseEnter={() => { setMouseLocation(i) }} onMouseOut={() => { setMouseLocation(-1) }}>
                <div className={mouseLocation == i ? 'card-body d-flex flex-center flex-column p-3 hover-user' : 'card-body d-flex flex-center flex-column p-3 user-border'}>

                  {currentRole == 'admin' ?
                    <>

                      <div className='dropdown align-right-auto'

                      >
                        <button className=" btn btn-sm btn-icon btn-bg-light btn-active-color-primary " type="button" id="dropdownUserAction" data-bs-toggle="dropdown" aria-expanded="false">
                          <i className='bi bi-three-dots fs-3'></i>
                        </button>
                        <div className={isDeleteDialogOpen ? "dropdown-menu w-150px" : "dropdown-menu w-150px"} aria-labelledby="dropdownUserAction">
                          <div className="menu-item px-3">
                            <a
                              className='menu-link px-3'
                              onClick={() => editiUser(true, userData?.id, userData)}>
                              Edit
                            </a>
                          </div>
                          <div className="menu-item px-3">
                            <a
                              className='menu-link px-3'
                              onClick={() => openUserPassword(true, userData?.id)}>
                              Change password
                            </a>
                          </div>

                          <div className="menu-item px-3">
                            <a
                              className='menu-link px-3 color-red'

                              onClick={() => deleteUserDialogOpen(true, userData?.id, userData)}>
                              Delete User
                            </a>
                          </div>
                        </div>
                      </div>
                    </>
                    : null}
                  <div className='mb-5'>
                    <div className='symbol symbol-75px symbol-circle'>
                      <span className="symbol-label bg-light-primary text-primary fs-5 fw-bolder">{getInitals(userData.name)}</span>
                    </div>

                  </div>

                  <a className='fs-4 text-gray-800 fw-bolder mb-0'>
                    {userData.name}
                  </a>
                  {userData.role == "admin" ? <span className="badge badge-primary fs-7 fw-400 mb-20">Admin</span> : <span className="badge fs-7 user-manager-text mb-20">{capitalizeFirstLetter(userData.role)} &bull; {userData.releases.length} assigned Releases</span>}

                </div>
              </div>
            </div>
          ))}
        </InfiniteScroll>
      </div>

      {show ? <AddUser dialogAction={show} editId={userId} editData={singleUser} sendData={handleShow} /> : null}

      {showPasswordDialog ? <UpdateUserPassword dialogAction={showPasswordDialog} editId={userId} sendData={handlePasswordDialogShow} /> : null}

      <ToastContainer />
    </>
  )
}
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

import CheckboxMultipleSelect from '../../../../dashboard/components/multi-select-dropdown/MultiSelectionDropdown';
import Loader from '../../../../global-module/loader/loader';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

// Load services
import instance from '../../../../../services/axiosConfig';
import { useAuth } from '../../../../auth/core/Auth';
let cancelToken1: any;

const AddUser = (props: any) => {

    const { dialogAction, sendData, editId, editData } = props;
    const [show, setShowUser] = useState(dialogAction);
    const [isLoading, setIsLoading] = useState(false);
    const { currentUser } = useAuth()
    const [editReleases, setEditReleases] = useState<any[]>([]);
    const [choiceReleases, setchoiceReleases] = useState<any[]>([]);
    const [listReleases, setReleases] = useState<any[]>([]);
    const [totalRelease, setTotalRelease] = useState<number>(0);
    const [releasePage, setReleasePage] = useState<number>(1);
    const [showPasswod, setShowPassword] = useState(false)
    const [releaseSearchTerm, setReleaseSearchTerm] = useState<string | undefined>(undefined);


    const onchange = function (arg0: any[]) {
        console.log(arg0)
        setchoiceReleases(arg0);
    }

    // Fetch the Releases
    useEffect(() => {
        if (editId != 0) {
            handleEdit(editData);
        }
        fetchReleases();
    }, []);

    useEffect(() => {
        fetchReleases()
    }, [releasePage, releaseSearchTerm])

    const fetchReleases = async () => {
        if (typeof cancelToken1 != typeof undefined) {
            cancelToken1.cancel('canceled')
        }
        let releaseSelector: any = [];
        cancelToken1 = axios.CancelToken.source()
        try {
            //setIsLoading(true);
            const response = await instance.get('/release?page=' + releasePage + (releaseSearchTerm ? '&search=' + releaseSearchTerm : ''), {
                cancelToken: cancelToken1.token,
            });
            var fetchResponse = response.data.data.data;
            for (const k in fetchResponse) {
                releaseSelector = [...releaseSelector, { label: fetchResponse[k].name, id: parseInt(fetchResponse[k].id) },]
            }
            if (releasePage == 1)
                setReleases(releaseSelector);
            else
                setReleases([...listReleases, ...releaseSelector]);
            setTotalRelease(Number(response.data.total));
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };


    const searchRelease = async (searchTerm: string) => {
        setReleaseSearchTerm(searchTerm)
        setReleasePage(1)
    }

    const handleEdit = async (editData: any) => {
        if (editData?.releases?.length > 0) {
            var releasesSelected: any = [];
            for (let i = 0; i < editData?.releases.length; i++) {
                releasesSelected.push(parseInt(editData?.releases[i].id));
            }
            setEditReleases(releasesSelected);
        }
        setFormData(editData);
    }

    const handleClose = () => {
        setShowUser(false);
        sendData(false);
    };

    // Start form

    interface User {
        name: string;
        email: string;
        role: string;
        password: string;
        release_ids: any;
    }

    const [formData, setFormData] = useState<User>({
        name: '',
        email: '',
        role: '',
        password: '',
        release_ids: '',
    });

    const [errors, setErrors] = useState({
        name: '',
        email: '',
        role: '',
        email_validation: '',
        password: '',
        release_ids: '',
    });


    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const validateForm = (data: any) => {
        const errors: any = {};

        if (!data.name) {
            errors.name = 'Name is required.';
        }

        if (!data.email) {
            errors.email = 'Email is required.';
        }

        if (!data.role) {
            errors.role = 'Role is required.';
        }

        if (data.email && !data.email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)) {
            errors.email_validation = 'Enter a valid email address';
        }

        if (!data.password) {
            errors.password = 'Password is required.';
        }

        if (choiceReleases?.length == 0 && data.role == 'manager') {
            errors.release_ids = 'Release is required.';
        }
        if (choiceReleases?.length > 30 && data.role == 'manager') {
            errors.release_ids = 'You cannot asign more than 30 releases to a user';
        }

        return Object.keys(errors).length ? errors : null;
    };

    const EditvalidateForm = (data: any) => {
        const errors: any = {};

        if (!data.name) {
            errors.name = 'Name is required.';
        }

        if (!data.email) {
            errors.email = 'Email is required.';
        }

        if (data.email && !data.email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)) {
            errors.email_validation = 'Enter a valid email address';
        }
        return Object.keys(errors).length ? errors : null;
    };

    const handleSubmit = async () => {

        // Validate form data

        if (editId != 0) {
            const validationErrors = EditvalidateForm(formData);
            if (validationErrors) {
                setErrors(validationErrors);
                return;
            }

            // Add data
            setIsLoading(true);
            try {

                var releasesToString = [];

                for (let i = 0; i < choiceReleases.length; i++) {
                    releasesToString.push(choiceReleases[i].id);
                }

                formData.release_ids = releasesToString.join(',').toString();
                const response = await instance.put('/user/' + editId, formData);

                toast.success(response?.data?.message);

                setTimeout(function () {
                    setShowUser(false);
                    sendData(false);
                    setIsLoading(false);
                }, 2000);
            } catch (error) {
                toast.error('something went wrong please try again');
                setTimeout(function () {
                    setIsLoading(false);
                }, 2000);
            }
        }
        else {
            const validationErrors = validateForm(formData);
            if (validationErrors) {
                setErrors(validationErrors);
                return;
            }

            var releasesToString = [];
            for (let i = 0; i < choiceReleases.length; i++) {
                releasesToString.push(choiceReleases[i].id);
            }

            formData.release_ids = releasesToString.join(',').toString();

            // Add data
            setIsLoading(true);
            try {
                const response = await instance.post('/auth/register', formData);
                toast.success(response?.data?.message);
                setTimeout(function () {
                    setShowUser(false);
                    sendData(false);
                    setIsLoading(false);
                }, 2000);
            } catch (error) {
                toast.error('something went wrong please try again');
                setTimeout(function () {
                    setIsLoading(false);
                }, 2000);
            }
        }
    }
    return (
        <>
            {/* {isLoading ? (
                <Loader />
            ) : (<></>)} */}

            <Modal show={show} onHide={handleClose}>
                <div className="custom_modal">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {editId != 0 ? 'Update User' : 'Add New User'}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className='labels'>Name</label>
                                    <input className='form-control' placeholder='Enter Name' type="text" id="name" name="name" value={formData.name} onChange={handleChange} />
                                    {errors.name && formData.name === '' && <div className='error-form'>{errors.name}</div>}
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className='labels'>Email</label>
                                    <input className='form-control' placeholder='Enter Email' type="text" id="email" name="email" value={formData.email} onChange={handleChange} />
                                    {errors.email && formData.email === '' && <div className='error-form'>{errors.email}</div>}
                                    {errors.email_validation && formData.email !== '' && <div className='error-form'>{errors.email_validation}</div>}
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className='labels'>Role</label>
                                    <select className='form-control' name='role' value={formData.role} onChange={handleChange}>
                                        <option value='' defaultValue=''>Select a Role</option>
                                        <option value='admin'>Admin</option>
                                        <option value='manager'>Manager</option>
                                        <option value='partner'>Partner</option>
                                    </select>
                                    {errors.role && formData.role === '' && <div className='error-form'>{errors.role}</div>}
                                </div>
                            </div>

                            {formData.role != 'admin' ?
                                <div className="col-md-12">
                                    <div className="form-group activity_song_drop">
                                        <label className='labels'>Releases</label>
                                        <CheckboxMultipleSelect options={listReleases} enableSelectedOption={editId ? true : false} selectedOption={editReleases}
                                            onChange={onchange} text='Releases'
                                            total={totalRelease}
                                            loadMore={() => {
                                                setReleasePage(releasePage + 1)
                                            }}
                                            dropdownHeight={200}
                                            isLoading={isLoading}
                                            enableSearch={true}
                                            onSearch={searchRelease}
                                        />
                                        {errors.release_ids && (choiceReleases?.length === 0 || choiceReleases?.length > 30) && <div className='error-form'>{errors.release_ids}</div>}
                                    </div>
                                </div> : <></>
                            }

                            {/* <div className="col-md-12">
                                <div className="form-group">
                                    <label className='labels'>Releases</label>
                                    <select className='form-control' name='release_ids' value={formData.release_ids} onChange={handleChange} multiple>
                                        <option value='' defaultValue='' disabled>Select a Releases</option>
                                        {listReleases.map((releasesData) => (
                                            <option value={releasesData?.id}>{releasesData?.title}</option>
                                        ))}
                                    </select>
                                    {errors.release_ids && formData.release_ids === '' && <div className='error-form'>{errors.release_ids}</div>}
                                </div>
                            </div> */}

                            {editId == 0 &&
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className='labels'>Password
                                            <span className="btn btn-sm btn-icon  me-n2" onClick={() => {
                                                setShowPassword(!showPasswod);
                                            }}>
                                                <i className={showPasswod ? "bi bi-eye-slash fs-2 " : "bi bi-eye fs-2 "}></i>
                                            </span>
                                        </label>
                                        <input className='form-control' placeholder='Enter Password' type={showPasswod ? 'text' : 'password'} id="password" name="password" value={formData.password} onChange={handleChange} />
                                        {errors.password && formData.password === '' && <div className='error-form'>{errors.password}</div>}
                                    </div>
                                </div>
                            }


                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="" onClick={handleSubmit} className='btn-active'>
                            {editId != 0 ? 'Update User' : 'Add User'}
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal>
            <ToastContainer />
        </>
    );
}

export default AddUser;
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect, useRef } from 'react'
import { Link, Navigate, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { KTIcon, toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'

// Load services
import Loader from '../../../../global-module/loader/loader'
import instance from '../../../../../services/axiosConfig'
import { useAuth } from '../../../../auth/core/Auth'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Filter, Release } from '../../../../dashboard/core/_models'
import { getRelease, getReleases } from '../../../../dashboard/core/_requests'
import { generateQuery } from '../../../../dashboard/core/Helpers'
import axios from 'axios'
import { Exception } from 'sass'
import { Console, error } from 'console'
import { AUTH_LOCAL_STORAGE_RELEASE_KEY, Logout } from '../../../../auth'
import AddRelease from '../../../../dashboard/components/releases/dailog/add-release'
import { useCommonContext } from '../../../../../services/CommonContext'
import { runtimeEnv } from '../../../../../../env'

let cancelToken: any

function useOutsideAlerter(ref: any, condition: boolean, callback: any) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target) && condition) {
        callback()
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, condition])
}
const SidebarMenuMain = () => {
  const { id }: any = useParams<any>()
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [isActive, setActive] = useState(false)
  const [showReleaseAdd, setShowReleaseAdd] = useState(false)
  const [totalRelease, setTotalRelease] = useState(0)
  const [filters, setFilters] = useState<Filter[]>([])
  const [searchText, setSearchText] = useState<any>('')
  const { releaseDeleted, setReleaseDeleted, releaseUpdated } = useCommonContext()
  const perPage = 30
  const intl = useIntl()

  const { currentUser, release, saveRelease, logout } = useAuth()

  const ToggleClass = () => {
    setActive(!isActive)
  }

  // Fetch the Releases
  const [listReleases, setReleases] = useState<Release[]>([])


  const [singleRelease, setsingleRelease] = useState<Release>()

  let hasAccess = false
  if (currentUser?.role == 'manager')
    currentUser?.releases?.map((r) => {
      if (id == r.id) hasAccess = true
    })
  else hasAccess = true

  useEffect(() => {
    //if (hasAccess) {
    if (release)
      getRelease(release).then((k) => {
        let r: Release = {
          id: k.id,
          imageUrl: k.image_url ? k.is_bundle ? runtimeEnv.REACT_APP_URL + k.image_url : k.image_url : '/media/svg/menu/bundles-icon-blue.svg',
          title: k.name,
          artist: k.artist,
          year: k.year,
          releaseDate: new Date(Date.parse(k.release_date)),
          linkSharingWithBudgetEnabled: k.link_sharing_with_budget_enabled,
          linkSharingWithoutBudgetEnabled: k.link_sharing_without_budget_enabled,
          linkSharingWithBudget: k.link_sharing_with_budget,
          linkSharingWithoutBudget: k.link_sharing_without_budget,
          projectedStreams: k.projected_streams,
          projectedCreations: k.projected_creations,
          projectBudget: k.project_budget,
          internalTag: k.internal_tag,
          isBundle: k.is_bundle,
          child: k.child
        }
        setsingleRelease(r)
      }).catch(error => {
        if (error.response) {
          if (error.response.status == 401) {
            localStorage.removeItem(AUTH_LOCAL_STORAGE_RELEASE_KEY);
            window.location.href = "/notfound";
          }
        }
      })
    else
      fetchReleases().then((d) => {
        setReleases(d)
      })
    //}
  }, [release, releaseUpdated])

  useEffect(() => {
    if (listReleases.length < 1 && isActive) {
      fetchReleases().then((d) => {
        setReleases(d)
      })
    }
  }, [isActive])

  useEffect(() => {
    if (isActive)
      fetchReleases(page.toString()).then((d) => {
        setReleases(d)
      })
  }, [filters])
  useEffect(() => {
    setPage(1)
    setFilters([
      {
        name: 'search',
        value: [searchText],
      },
    ])
  }, [searchText, releaseDeleted])

  const fetchReleases = async (page?: string) => {
    try {
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel('canceled')
      }
      let response
      //Save the cancel token for the current request
      cancelToken = axios.CancelToken.source()
      //let fetchFreshData = false;
      setIsLoading(true)
      response = (
        await instance.get('/release' + generateQuery(filters) + '&page=' + (page ? page : 1), {
          cancelToken: cancelToken.token,
        })
      ).data;

      let listoutReleases: Release[] = [];

      response.data.data.forEach(function (k: any, index: any) {
        let r: Release = {
          id: k.id,
          imageUrl: k.image_url ? k.is_bundle ? runtimeEnv.REACT_APP_URL + k.image_url : k.image_url : '/media/svg/menu/bundles-icon-blue.svg',
          title: k.name,
          artist: k.artist,
          year: k.year,
          releaseDate: new Date(Date.parse(k.release_date)),
          linkSharingWithBudgetEnabled: k.link_sharing_with_budget_enabled,
          linkSharingWithoutBudgetEnabled: k.link_sharing_without_budget_enabled,
          linkSharingWithBudget: k.link_sharing_with_budget,
          linkSharingWithoutBudget: k.link_sharing_without_budget,
          projectedStreams: k.projected_streams,
          projectedCreations: k.projected_creations,
          projectBudget: k.project_budget,
          internalTag: k.internal_tag,
          isBundle: k.is_bundle,
          child: k.child
        }
        listoutReleases.push(r);
      })

      setTotalRelease(response.total)
      //setPage(response.page);

      let findReleasesArray: any = []

      if (currentUser?.role != 'admin') {
        let getByReleasesLocal
        console.log(localStorage.getItem('userReleasesId'));
        if (
          localStorage.getItem('userReleasesId') != 'undefined' &&
          localStorage.getItem('userReleasesId') != ''
        ) {
          var parseTouserReleases: any = localStorage.getItem('userReleasesId')
          getByReleasesLocal = JSON.parse(parseTouserReleases)
        } else {
          getByReleasesLocal = []
        }

        var getCurrentUserReleases: any = []

        getByReleasesLocal.forEach(function (item: any, index: any) {
          getCurrentUserReleases.push(item.id.toString())
        })

        for (let re = 0; re < listoutReleases.length; re++) {
          if (getCurrentUserReleases.includes(listoutReleases[re].id)) {
            findReleasesArray.push(listoutReleases[re])
          }
        }
      } else {
        findReleasesArray = listoutReleases
      }

      if (!release) {
        saveRelease(findReleasesArray[0].id)
        setsingleRelease(findReleasesArray[0])
      }
      setIsLoading(false)

      return findReleasesArray
    } catch (e: any) {
      console.log(e)
      if (e.message != 'canceled') setIsLoading(false)
    }
    return []
  }

  if (id && hasAccess) saveRelease(id)
  const wrapperRef = useRef<HTMLDivElement>(null)

  useOutsideAlerter(wrapperRef, isActive, () => {
    setActive(false)
  })

  return (
    <>
      {!singleRelease || singleRelease.id == releaseDeleted?.toString() ? (
        <></>
      ) : (
        <>
          <div className='head_title d-flex justify-content-between'>
            <h4>Releases</h4>
            {/* <i className="fas fs-1 fa-square-plus mt-2" onClick={() => setShowReleaseAdd(true)}></i> */}
          </div>
          <div className='app-sidebar-header cust-metronic  d-lg-flex' id='kt_app_sidebar_header'>
            <button
              type='button'
              className='btn btn-custom btn-flex w-100 menu-dropdown'
              onClick={ToggleClass}
            >
              <span className='d-flex flex-center flex-shrink-0 w-40px me-3'>
                <img alt='Logo' src={singleRelease?.imageUrl} data-kt-element='logo' width={45} />

              </span>
              <span className='d-flex flex-column align-items-start flex-grow-1 text-start'>
                <span className='fs-5 fw-bold text-white' data-kt-element='title'>
                  {singleRelease?.title} &nbsp; {singleRelease.internalTag && singleRelease.internalTag.length > 0 ? (
                    <img
                      className='mb-3'
                      src={toAbsoluteUrl('/media/misc/note.png')}
                      id='dropdownUserAction'
                      data-bs-toggle='dropdown'
                      aria-expanded='false'
                    ></img>
                  ) : null}
                </span>
                <span className='fs-7 fw-bold text-gray-700 lh-sm' data-kt-element='desc'>
                  {singleRelease.isBundle ? singleRelease?.child && singleRelease?.child?.length > 1 ? singleRelease?.child?.length + ' releases included' : singleRelease?.child?.length + ' release included' : singleRelease.artist}
                </span>
              </span>
              <span className='d-flex flex-column me-n4'>
                <i className='ki-outline ki-up fs-2 text-gray-700'></i>
                <i className='ki-outline ki-down fs-2 text-gray-700'></i>
              </span>
            </button>

            <div
              ref={wrapperRef}
              className={
                isActive
                  ? 'menu menu-sub menu-sub-dropdown menu-column menu-state-bg menu-rounded p-2 show'
                  : 'menu menu-sub menu-sub-dropdown menu-column menu-state-bg menu-rounded p-2'
              }
              data-kt-menu='true'
            >
              <div className='hover-scroll-y '>
                <InfiniteScroll
                  dataLength={page * perPage}
                  next={() => {
                    if (isActive)
                      fetchReleases((page + 1).toString()).then((d) => {
                        setReleases([...listReleases, ...d])
                        setPage(Number(page) + 1)
                      })
                  }}
                  hasMore={page * perPage < totalRelease}
                  loader={null}
                  height={400}
                  endMessage={
                    <p style={{ textAlign: 'center' }} className='text-white'>
                      {/* <b>Yay! You have seen it all</b> */}
                    </p>
                  }
                >
                  <input
                    className='form-control mb-2'
                    placeholder='Search'
                    type='text'
                    id='search'
                    name='search'
                    value={searchText}
                    onChange={(t) => {
                      setSearchText(t.currentTarget.value)
                    }}
                  />
                  {!isLoading && listReleases.length < 1 ? (
                    <div className='text-white text-center mb-2'>No Record found</div>
                  ) : null}

                  {listReleases.map((releasesData, i) => (
                    <a
                      key={i}
                      className='menu-item'
                      onClick={(e) => { }}
                      href={'/share/' + releasesData?.id}
                    >
                      <span className='menu-link px-3 py-3 active' data-kt-element='project'>
                        <span className='d-flex flex-center flex-shrink-0 w-40px me-3'>
                          <img
                            alt='Logo'
                            src={releasesData?.imageUrl}
                            data-kt-element='logo'
                            width={45}
                          />
                        </span>
                        <span className='d-flex flex-column align-items-start'>
                          <span className='fs-5 fw-bold text-white' data-kt-element='title'>
                            {releasesData?.title}&nbsp;
                            {releasesData.internalTag && releasesData.internalTag.length > 0 ? (
                              <img
                                className='mb-2'
                                src={toAbsoluteUrl('/media/misc/note.png')}
                                id='dropdownUserAction'
                                data-bs-toggle='dropdown'
                                aria-expanded='false'
                              ></img>
                            ) : null}
                          </span>
                          <span className='fs-7 fw-bold text-gray-700 lh-sm' data-kt-element='desc'>
                            {releasesData.isBundle ? releasesData?.child && releasesData?.child?.length > 1 ? releasesData?.child?.length + ' releases included' : releasesData?.child?.length + ' release included' : releasesData.artist}
                          </span>
                        </span>
                      </span>
                    </a>
                  ))}
                  {isLoading ? <div className='text-white text-center mb-2'>Loading...</div> : null}
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </>
      )}
      <SidebarMenuItem
        to={release ? '/share/' + release : '/dashboard'}
        image='/media/svg/menu/home-icon-grey.svg'
        imageActive='/media/svg/menu/home-icon-white.svg'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
      />
      {
        currentUser?.role != 'partner' ?
          <>
            <SidebarMenuItem
              to='/project-user'
              image='/media/svg/menu/users-icon-grey.svg'
              imageActive='/media/svg/menu/users-icon-white.svg'
              title='Project Users'
            />
            <SidebarMenuItem
              to='/release-bundle'
              image='/media/svg/menu/bundles-icon-grey.svg'
              imageActive='media/svg/menu/bundles-icon.svg'
              title='Release Bundle'
            />
            <SidebarMenuItemWithSub
              to='/settings'
              title='Settings'
              image='/media/svg/menu/settings-icon-grey.svg'
              imageActive='/media/svg/menu/settings-icon-white.svg'
            >
              <SidebarMenuItem
                to='/settings/campaign-category'
                title='Campaign Category'
                hasBullet={true}
              />
              <SidebarMenuItem to='/settings/task-category' title='Task Category' hasBullet={true} />
              <SidebarMenuItem to='/settings/asset-category' title='Asset Category' hasBullet={true} />
            </SidebarMenuItemWithSub>
          </>
          : null
      }
      {
        showReleaseAdd ?
          <AddRelease showReleaseDailog={showReleaseAdd} onChange={function (arg0: boolean) {
            setShowReleaseAdd(false);
          }} />
          : null
      }
    </>
  )
}

export { SidebarMenuMain }

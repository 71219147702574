import { Dispatch, FC, SetStateAction, createContext, useContext, useState } from "react"
import { WithChildren } from "../../_metronic/helpers"

type CommonContextProps = {
  budgetUpdated: boolean | false
  setBudgetUpdated: Dispatch<SetStateAction<boolean | false>>


  campaignUpdated: boolean | false
  setCampaignUpdated: Dispatch<SetStateAction<boolean | false>>

  releaseUpdated: boolean | false
  setReleaseUpdated: Dispatch<SetStateAction<boolean | false>>

  releaseDate: Date | undefined
  setReleaseDate: Dispatch<SetStateAction<Date | undefined>>

  releaseDeleted: number | undefined
  setReleaseDeleted: Dispatch<SetStateAction<number | undefined>>

}

const initCommonContextPropsState = {
  budgetUpdated: false,
  setBudgetUpdated: () => { },
  campaignUpdated: false,
  setCampaignUpdated: () => { },
  releaseUpdated: false,
  setReleaseUpdated: () => { },
  releaseDate: undefined,
  setReleaseDate: () => { },
  releaseDeleted: undefined,
  setReleaseDeleted: () => { },
}

const CommonContext = createContext<CommonContextProps>(initCommonContextPropsState)

const useCommonContext = () => {
  return useContext(CommonContext)
}

const CommonContextProvider: FC<WithChildren> = ({ children }) => {

  const [budgetUpdated, setBudgetUpdated] = useState<boolean | false>(false)
  const [campaignUpdated, setCampaignUpdated] = useState<boolean | false>(false)
  const [releaseUpdated, setReleaseUpdated] = useState<boolean | false>(false)
  const [releaseDate, setReleaseDate] = useState<Date | undefined>(undefined)
  const [releaseDeleted, setReleaseDeleted] = useState<number | undefined>(undefined)

  return (
    <CommonContext.Provider value={{ budgetUpdated, setBudgetUpdated, campaignUpdated, setCampaignUpdated, releaseUpdated, setReleaseUpdated, releaseDate, setReleaseDate, releaseDeleted, setReleaseDeleted }}>
      {children}
    </CommonContext.Provider>
  )
}

export { CommonContextProvider, useCommonContext }
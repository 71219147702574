import { AxiosResponse } from "axios"
import instance from "../../../services/axiosConfig"
import { AssetCategory, CampaignCategory, TaskCategory } from "./Models"
import { runtimeEnv } from "../../../../env"

export const API_URL = runtimeEnv.REACT_APP_API_URL
const CAMPAIGN_CATEGORY_URL = `${API_URL}/campaign-type`
const TASK_CATEGORY_URL = `${API_URL}/task-category`
const ASSET_CATEGORY_URL = `${API_URL}/asset-category`

export const getCampaignCategories = (query?: string | null): Promise<any> => {
    return instance
        .get(`${CAMPAIGN_CATEGORY_URL}?${query ?? ''}`)
        .then((d: AxiosResponse<any>) => d.data)
}
export const postCampaignCategories = (formData: any): Promise<any> => {
    return instance
        .post(CAMPAIGN_CATEGORY_URL, formData)
        .then((d: AxiosResponse<any>) => d.data)
}

export const putCampaignCategories = (formData: CampaignCategory): Promise<any> => {
    return instance
        .put(`${CAMPAIGN_CATEGORY_URL}/${formData.id}`, formData)
        .then((d: AxiosResponse<any>) => d.data)
}

export const deleteCampaignCategories = (formData: CampaignCategory): Promise<any> => {
    return instance
        .delete(`${CAMPAIGN_CATEGORY_URL}/${formData.id}`)
        .then((d: AxiosResponse<any>) => d.data)
}

export const getTaskCategories = (query?: string | null): Promise<any> => {
    return instance
        .get(`${TASK_CATEGORY_URL}?${query ?? ''}`)
        .then((d: AxiosResponse<any>) => d.data)
}

export const postTaskCategories = (formData: any): Promise<any> => {
    return instance
        .post(TASK_CATEGORY_URL, formData)
        .then((d: AxiosResponse<any>) => d.data)
}

export const putTaskCategories = (formData: TaskCategory): Promise<any> => {
    return instance
        .put(`${TASK_CATEGORY_URL}/${formData.id}`, formData)
        .then((d: AxiosResponse<any>) => d.data)
}

export const deleteTaskCategories = (formData: TaskCategory): Promise<any> => {
    return instance
        .delete(`${TASK_CATEGORY_URL}/${formData.id}`)
        .then((d: AxiosResponse<any>) => d.data)
}


export const getAssetCategories = (query?: string | null): Promise<any> => {
    return instance
        .get(`${ASSET_CATEGORY_URL}?${query ?? ''}`)
        .then((d: AxiosResponse<any>) => d.data)
}

export const postAssetCategories = (formData: any): Promise<any> => {
    return instance
        .post(ASSET_CATEGORY_URL, formData)
        .then((d: AxiosResponse<any>) => d.data)
}

export const putAssetCategories = (formData: AssetCategory): Promise<any> => {
    return instance
        .put(`${ASSET_CATEGORY_URL}/${formData.id}`, formData)
        .then((d: AxiosResponse<any>) => d.data)
}

export const deleteAssetCategories = (formData: AssetCategory): Promise<any> => {
    return instance
        .delete(`${ASSET_CATEGORY_URL}/${formData.id}`)
        .then((d: AxiosResponse<any>) => d.data)
}
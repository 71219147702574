import axios, { AxiosResponse } from 'axios'
import instance from '../../../services/axiosConfig'
import { Activity, Task } from './_models'
import { runtimeEnv } from '../../../../env'

export const API_URL = runtimeEnv.REACT_APP_API_URL
const API_DOMAIN = runtimeEnv.API_DOMAIN
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
const GET_TRENDS_URL = `${API_URL}/trends`
const GET_CAMPAIGN_URL = `${API_URL}/campaign`

export const getTrends = (release: string, query?: string | null): Promise<any> => {
  return instance
    .get(`${GET_TRENDS_URL}/${release}?${query ?? ''}`)
    .then((d: AxiosResponse<any>) => d.data)
}

export const getCampaigns = (query?: string | null): Promise<any> => {
  return instance.get('/campaign' + query)
    .then((d: AxiosResponse<any>) => d.data)
}

export const getTasks = (query?: string | null): Promise<any> => {
  return instance.get('/task' + query)
    .then((d: AxiosResponse<any>) => d.data)
}


export const getReleases = (query?: string | null): Promise<any> => {
  return instance.get('/release?' + query ?? '')
    .then((d: AxiosResponse<any>) => d.data)
}

export const getRelease = (id: string): Promise<any> => {
  return instance.get('/release/' + id)
    .then((d: AxiosResponse<any>) => {
      return d.data.data
    })
}

export const getReleaseStats = (release: string): Promise<any> => {
  return instance.get('/release/' + release + '/stats')
    .then((d: AxiosResponse<any>) => d.data)
}


export const getUser = (query: string): Promise<any> => {
  return instance.get('/user' + query)
    .then((d: AxiosResponse<any>) => d.data.data)
}


export const getTaskCategory = (query: string): Promise<any> => {
  return instance.get('/task-category' + query)
    .then((d: AxiosResponse<any>) => d.data.data)
}

export const getSingleCampaign = (id: string, releaseId: string): Promise<Activity> => {
  return instance.get('/campaign?release_id=' + releaseId + '&id=' + id)
    .then((d: AxiosResponse<any>) => d.data.data[0])
};

export const getSingleTask = (id: string, releaseId: string): Promise<Activity> => {
  return instance.get('/task?release_id=' + releaseId + '&id=' + id)
    .then((d: AxiosResponse<any>) => d.data.data[0])
};

export const deleteCampaign = (id: string): Promise<any> => {
  return instance.delete('/campaign/' + id)
    .then((d: AxiosResponse<any>) => d.data)
};

export const updateTask = (id: string, data: any): Promise<Task> => {
  return instance.put('/task/' + id, data).then((d: AxiosResponse<any>) => d.data)
}

export const deleteTask = (id: string): Promise<Activity> => {
  return instance.delete('/task/' + id)
    .then((d: AxiosResponse<any>) => d.data)
};

export const updateOverview = (id: string, overview: string): Promise<Task> => {
  //throw ("some error");
  return instance.put('/release/' + id + '/overview', { 'overview': overview }).then((d: AxiosResponse<any>) => d.data)
}

export const updateLinkSharing = (id: string, linkSharingWithBudgetEnabled: boolean, linkSharingWithoutBudgetEnabled: boolean, linkSharingWithBudget: String, linkSharingWithoutBudget: String): Promise<any> => {
  return instance.put('/release/' + id + '/linksharing', {
    'link_sharing_with_budget_enabled': linkSharingWithBudgetEnabled,
    'link_sharing_without_budget_enabled': linkSharingWithoutBudgetEnabled,
    'link_sharing_with_budget': linkSharingWithBudget,
    'link_sharing_without_budget': linkSharingWithoutBudget,
  }).then((d: AxiosResponse<any>) => d.data)
}

export const updateBudget = (id: string, projectedStreams: number | undefined, projectedCreations: number | undefined, projectedBudget: number | undefined): Promise<any> => {
  let data: any = {};
  if (projectedStreams) {
    data.projected_streams = projectedStreams
  } else {
    data.projected_streams = 0
  }
  if (projectedCreations) {
    data.projected_creations = projectedCreations
  } else {
    data.projected_creations = 0
  }
  if (projectedBudget) {
    data.projected_budget = projectedBudget
  } else {
    data.projected_budget = 0
  }
  //  data = {
  //   'projected_streams': projectedStreams,
  //   'projected_creations': projectedCreations,
  //   'projected_budget': projectedBudget,
  // };

  return instance.put('/release/' + id + '/projectedvalus', data).then((d: AxiosResponse<any>) => d.data)
}

export const fetchAssetsByCategory = async (category: any, releaseId: string, onlyWithBudget: boolean = true) => {

  let apiUrl: string = '';

  if (category == 'allAssets') {
    apiUrl = '/asset?release_id=' + releaseId;
  }
  else {
    apiUrl = '/asset?' + (onlyWithBudget ? 'with_budget=1&' : '') + 'asset_category_id=' + category + '&release_id=' + releaseId;
  }

  return instance.get(apiUrl);

};

export const fetchCampaingsByCategory = async (category: any, releaseId: string, onlyWithBudget: boolean = true) => {

  let apiUrl: string = '';
  apiUrl = '/task?' + (onlyWithBudget ? 'with_budget=1&' : '') + 'campaign_category_ids=' + category + '&release_id=' + releaseId + '&task_category_ids=-1';

  return instance.get(apiUrl);

};

export const fetchTasksByCategory = async (category: any, releaseId: string, onlyWithBudget: boolean = true) => {

  let apiUrl: string = '';
  apiUrl = '/task?' + (onlyWithBudget ? 'with_budget=1&' : '') + 'task_category_ids=' + category + '&release_id=' + releaseId + '&campaign_category_ids=-1';
  return instance.get(apiUrl);
};

export const deleteRelease = (id: string): Promise<Activity> => {
  return instance.delete('/release/' + id)
    .then((d: AxiosResponse<any>) => d.data)
};

export const importRelease = (releaseId: string): Promise<Activity> => {
  return instance.post('/release/import/' + releaseId)
    .then((d: AxiosResponse<any>) => d.data.data[0])
};

export const postRelease = (data:any): Promise<Activity> => { 
  return instance.post('/release',data)
    .then((d: AxiosResponse<any>) => d.data)
};

export const updateRelease = (id: string, data:any): Promise<Task> => {
  //throw ("some error");
  return instance.put('/release/' + id, data).then((d: AxiosResponse<any>) => d.data)
}
export const unlinkRelease = (bundleId: string,releaseId:string): Promise<Activity> => {
  return instance.delete('/release/' + bundleId+'/'+releaseId)
    .then((d: AxiosResponse<any>) => d.data)
};

export const syncRelease = (): Promise<Activity> => {
  return instance.get('/../release/sync')
    .then((d: AxiosResponse<any>) => d.data)
};
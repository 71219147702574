import React from 'react';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onNextPage: () => void;
  onPreviousPage: () => void;
  onGoToPage: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onNextPage,
  onPreviousPage,
  onGoToPage,
}) => {
  totalPages = Math.round(totalPages);
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5; // Maximum number of page numbers to display

    const middlePage = Math.floor(maxVisiblePages / 2);
    let startPage = Math.max(currentPage - middlePage, 1);
    let endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);
    endPage = Math.round(endPage);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(endPage - maxVisiblePages + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      const isActive = currentPage === i;

      pageNumbers.push(
        <li
          key={i}
          className={`page-number ${isActive ? 'active' : ''}`}
          onClick={() => onGoToPage(i)}
        >
          {i}
        </li>
      );
    }

    return pageNumbers;
  };

  return (
    <div className="pagination cust_pagination">
      <button onClick={onPreviousPage} disabled={currentPage === 1}>
        <i className="fa-solid fa-angle-left"></i>
      </button>
      <ul className="page-numbers">
        {renderPageNumbers()}
      </ul>
      <button onClick={onNextPage} disabled={currentPage === totalPages}>
        <i className="fa-solid fa-angle-right"></i>
      </button>
    </div>
  );
};

export default Pagination;
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from 'react'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighChartsAnnotation from 'highcharts/modules/annotations'

import { useQuery } from 'react-query'
import { getCampaigns, getSingleCampaign, getSingleTask, getTasks, getTrends } from '../core/_requests'

// Activity Dialog
import SingleSelectionDropdown from './custom-select-option/SingleSelectionDropdown'
import MultiSelectionDropdown from './multi-select-dropdown/MultiSelectionDropdown'
import { Activity, DateFilter, Promotion, Task } from '../core/_models'
import { useAuth } from '../../auth/core/Auth'
import { adjustTextToMinLength, clipText, getDays } from '../core/Helpers'
import { useCommonContext } from '../../../services/CommonContext'
import AddActivity from './activity-dialog/add-activity'
import { escape } from 'querystring'
import Loader from '../../global-module/loader/loader'
import AddTask from './tasks/dialog/add-task'
import { runtimeEnv } from '../../../../env'

function dateToYMD(date: Date) {
  var d = date.getDate()
  var m = date.getMonth() + 1 //Month from 0 to 11
  var y = date.getFullYear()
  return '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d)
}

export function Trends() {
  let last2WeekDate = new Date()
  last2WeekDate.setDate(last2WeekDate.getDate() - 14)
  let yesterday = new Date()
  yesterday.setDate(yesterday.getDate())

  const [songs, setSongs] = useState([])
  const [selectedSong, setSelectedSong] = useState<any[]>([])
  const [dateFilter, setDateFilter] = useState<DateFilter>(new DateFilter(last2WeekDate, yesterday))
  const [promotions, setPromotions] = useState<Promotion[]>([])
  const [activePromotion, setActivePromotions] = useState<any[]>([])
  const [selectedPromotion, setSelectedPromotions] = useState<any[]>([])
  const [selectedPromotionOption, setSelectedPromotionOption] = useState<any[]>([])
  const [allPromotions, setAllPromotions] = useState<any[]>([])
  const [series, setSeries] = useState([{ data: [], type: 'spline' }])
  const [showListActivity, setShowListActivity] = useState(false)
  const [showActivityForm, setshowActivityForm] = useState(false)
  const [isStartAtFirstRecord, setIsStartAtFirstRecord] = useState(false)
  const [editCampaign, setEditCampaign] = useState<Activity | undefined>(undefined)
  const [hideCampaigns, setHideCampaigns] = useState(false)

  HighChartsAnnotation(Highcharts)
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)

  const { release, currentUser } = useAuth()
  const { campaignUpdated } = useCommonContext()
  var colors = ['#4f5bd5', '#CD201F', '#4f5bd5', '#CD201F', '#548999']
  const campaignVisualHeight = 45
  const {
    isFetching,
    dataUpdatedAt,
    refetch: reFetchTrends,
    data: responseTrends,
  } = useQuery(
    `trends`,
    () => {
      if (release)
        return getTrends(
          release,
          'start_date=' +
          dateToYMD(dateFilter.startDate) +
          '&end_date=' +
          dateToYMD(dateFilter.endDate)
        )
    },
    { cacheTime: 1000, keepPreviousData: false, refetchOnWindowFocus: false }
  )

  const { refetch: refetchCampaign, data: responseCampaign } = useQuery(
    `campaign`,
    () => {
      if (release) return getTasks('?release_id=' + release + '&task_category_ids=-1')
    },
    { cacheTime: 0, keepPreviousData: false, refetchOnWindowFocus: false }
  )

  useEffect(() => {
    refetchCampaign()
  }, [campaignUpdated])

  useEffect(() => {
    setSelectedPromotions(selectedPromotionOption)
  }, [selectedPromotionOption])


  useEffect(() => {
    if (responseCampaign != undefined) {
      let data: any[] = []

      for (const k in responseCampaign.data) {
        data = [
          ...data,
          {
            id: responseCampaign.data[k].id,
            name: responseCampaign.data[k].title,
            isVisible: true,
            duration: [
              Date.parse(responseCampaign.data[k].start_date),
              responseCampaign.data[k].end_date ? Date.parse(responseCampaign.data[k].end_date) : 0,
            ],
            plotStartPoint: -2,
            plotEndPoint: -2,
            categoryId: responseCampaign.data[k].campaign_type_id,
            imageAssetUrl:
              responseCampaign.data[k].asset_url != null
                ? runtimeEnv.REACT_APP_URL + responseCampaign.data[k].asset_url
                : null,
            color: responseCampaign.data[k].campaign_type.color,
          },
        ]
      }
      setAllPromotions(data)
      setPromotions(data)
    }
  }, [responseCampaign])

  useEffect(() => {
    let _series: any = []
    let _songSelector: any = []

    for (const k in responseTrends) {
      let data = []

      if (responseTrends[k].song != 'Total') {
        _songSelector = [..._songSelector, { label: responseTrends[k].song, id: parseInt(k) }]
        let addThisSong: boolean = false
        //Songs filter
        for (const _sS in selectedSong) {
          if (selectedSong[_sS].label == responseTrends[k].song) {
            addThisSong = true
          }
        }
        if (addThisSong == false) continue
      }

      //get the total days between 2 dates
      let days = getDays(dateFilter.startDate, dateFilter.endDate)


      let availableDays: any[] = []
      //fill the data in available Days
      for (const e in responseTrends[k].data) {
        data.push([
          Date.parse(responseTrends[k].data[e].dateNice),
          parseInt(responseTrends[k].data[e].quantity),
        ])
        availableDays.push(responseTrends[k].data[e].dateNice)
      }

      //make sure that there are no gaps between dates
      days
        .filter((n) => !availableDays.includes(n))
        .map((e) => {

          data.push([Date.parse(e), 0])
        })

      //Sort the Data
      data.sort(function (a, b) {
        return a[0] - b[0]
      })


      let length = data.length

      //only trim the data from end;
      if (availableDays.length > 0)
        for (let i = 0; i < length; i++) {
          let _d = data[length - i - 1]
          if (_d[1] == 0) {
            data.splice(length - i - 1, 1)
          }
          else if (!isStartAtFirstRecord) break;
        }
      let color: any = null
      let chartType: string = 'spline'
      if (responseTrends[k].song == 'Total') {
        color = {
          linearGradient: { x1: 0.2, x2: 0.2, y1: 0.2, y2: 1 },
          stops: [
            [0, '#009ef730'], // start

            [1, '#ffffff'], // end
          ],
        }
        chartType = 'areaspline'
      }
      if (_series.length < 1)
        _series = [
          {
            data: data,
            type: chartType,
            name: responseTrends[k].song,
            marker: {
              enabled: false,
            },
            threshold: null,
            crisp: false,
            color: color,
            lineColor: '#009ef7'
          },
        ]
      else
        _series = [
          ..._series,
          {
            data: data,
            type: chartType,
            name: responseTrends[k].song,
            marker: {
              enabled: false,
            },
            threshold: null,
            crisp: false,
            color: color,
          },
        ]
    }

    setSeries(_series)
    setSongs(_songSelector)
  }, [responseTrends, selectedSong, dataUpdatedAt])

  useEffect(() => {
    if (selectedPromotion.length > 0) {
      let _selectedPromotion: any[] = []
      for (const _p in selectedPromotion) {
        _selectedPromotion = [..._selectedPromotion, allPromotions[selectedPromotion[_p].id]]
      }
      setPromotions(_selectedPromotion)
      setHideCampaigns(false)
    } else setPromotions(allPromotions)


  }, [selectedPromotion])
  //console.log("rendering")

  useEffect(() => {
    const chart = chartComponentRef?.current?.chart
    if (chart != null) {
      //console.log(500 + 70 + 40 * activePromotion.length);
      chart.setSize(null, 500 + (hideCampaigns ? 0 : (70 + campaignVisualHeight * activePromotion.length)), false)
      chart.update(
        {
          chart: {
            marginBottom: (hideCampaigns ? 0 : (70 + activePromotion.length * campaignVisualHeight)),
          },
        },
        true
      )
    }
  }, [activePromotion, hideCampaigns])

  useEffect(() => {
    reFetchTrends()
  }, [dateFilter])

  let promotionOption: any[] = []

  for (const _p in allPromotions) {
    promotionOption = [...promotionOption, { label: allPromotions[_p].name, id: parseInt(_p) }]
  }

  function objectIsEmpty(obj: { constructor?: any } | null) {
    if (obj != null) return Object.keys(obj).length === 0 && obj.constructor === Object
  }

  function timeline(chart: {
    load?: () => void
    redraw?: () => void
    render?: () => void
    timeline?: any
    series?: any
    renderer?: any
    plotLeft?: any
    chartHeight?: any
    removeAnnotation?: any
    addAnnotation?: any
  }) {
    if (chart.series.length < 1 || chart.series[0].data.length < 1) {
      return
    }

    if (chart.timeline === undefined) {
      chart.timeline = []
    }

    if (_imgs != undefined) {
      for (let img of _imgs) {
        //console.log(img);
        img.destroy()
      }
    } else {
      _imgs = []
    }
    if (_popupPromotion != undefined) {
      for (let popup of _popupPromotion) {
        //console.log(img);
        popup.destroy()
      }
    } else {
      _popupPromotion = []
    }

    chart.timeline.forEach(function (_p: any) {
      _p.forEach((e: any) => {
        !objectIsEmpty(e) && e && e.destroy()
      })
      // !objectIsEmpty(_p[0]) && _p[0] && _p[0].destroy()
      // !objectIsEmpty(_p[1]) && _p[1] && _p[1].destroy()
      // !objectIsEmpty(_p[2]) && _p[2] && _p[2].destroy()
      // !objectIsEmpty(_p[3]) && _p[3] && _p[3].destroy()
    })
    if (hideCampaigns)
      return;
    let i = 0
    promotions.forEach(function (part, index) {
      if (part.duration[1] == 0) {
        part.unKnownEndDate = true
        if (part.duration[0] < Date.now()) part.duration[1] = Date.now()
        else part.duration[1] = part.duration[0]
      }

      // if (part.duration[0] < chart.series[0].data[chart.series[0].data.length - 1].x && part.duration[0] > chart.series[0].data[0].x) {
      let startPoint = -2,
        endPoint = -2

      chart.series[0].data.forEach(function (val: { x: number }, index: any) {
        let xDate = new Date(val.x)
        // console.log(xDate.toDateString());
        // console.log((new Date(part.duration[1])).toDateString());
        if (xDate.toDateString() == new Date(part.duration[0]).toDateString()) startPoint = index

        if (xDate.toDateString() == new Date(part.duration[1]).toDateString()) endPoint = index
      })

      if (
        chart.series[0].data[0].x > part.duration[0] &&
        chart.series[0].data[chart.series[0].data.length - 1].x < part.duration[1]
      ) {
        part.plotStartPoint = -1
        part.plotEndPoint = -1
      }

      if (startPoint != -2 && endPoint != -2) {
        part.plotStartPoint = startPoint
        part.plotEndPoint = endPoint
      } else if (startPoint != -2 || endPoint != -2) {
        part.plotStartPoint = startPoint
        part.plotEndPoint = endPoint

        //either Start Point or EndPoint is null mean that highchart needs to show the timeline
        if (startPoint == -2) part.plotStartPoint = -1
        if (endPoint == -2) part.plotEndPoint = -1
      }
    })

    var annotationLabelStart: {
      point:
      | { xAxis: number; yAxis: number; x: any; y: any }
      | { xAxis: number; yAxis: number; x: any; y: any }
      text: string
    }[] = []
    var annotationLabelEnd: {
      point:
      | { xAxis: number; yAxis: number; x: any; y: any }
      | { xAxis: number; yAxis: number; x: any; y: any }
      text: string
    }[] = []

    let _activePromotion: any = []
    promotions.forEach(function (promotion, index) {
      if (promotion.plotStartPoint != -2 && promotion.plotEndPoint != -2) {
        _activePromotion = [..._activePromotion, promotion]

        var point1, point2
        if (promotion.plotStartPoint >= 0 && promotion.plotEndPoint >= 0) {
          point1 = chart.series[0].data[promotion.plotStartPoint]
          point2 = chart.series[0].data[promotion.plotEndPoint]
        } else if (promotion.plotStartPoint < 0 && promotion.plotEndPoint >= 0) {
          point1 = chart.series[0].data[0]
          point2 = chart.series[0].data[promotion.plotEndPoint]
        } else if (promotion.plotStartPoint >= 0 && promotion.plotEndPoint < 0) {
          point1 = chart.series[0].data[promotion.plotStartPoint]
          point2 = chart.series[0].data[chart.series[0].data.length - 1]
        } else {
          point1 = chart.series[0].data[0]
          point2 = chart.series[0].data[chart.series[0].data.length - 1]
        }

        //add Annotations
        if (point1 != undefined && point2 != undefined) {

          if (promotion.plotStartPoint >= 0) {
            const point = chart.series[0].points[promotion.plotStartPoint];
            const pos = point.pos(true);
            let _pS = hasMultiplePromotions(promotion.plotStartPoint);

            if (_pS.length == 1)
              _imgs = [
                ..._imgs, chart.renderer.circle(pos[0], pos[1], 8).
                  on('click', () => {
                    renderPromotionOnChart(pos[0], pos[1], [promotion], promotion.plotStartPoint)
                  }).css({

                    cursor: 'pointer',
                  }).attr({
                    fill: promotion.color,
                    stroke: 'white',
                    'stroke-width': 2,
                    zIndex: 8
                  }).on('mouseover', () => {
                    renderPromotionOnChart(pos[0], pos[1], [promotion], promotion.plotStartPoint)
                  }).add()
              ];
            else {
              _imgs = [
                ..._imgs, chart.renderer.circle(pos[0], pos[1], 10).css({

                  cursor: 'pointer',
                })
                  .attr({
                    fill: "#009ef7",
                    stroke: 'white',
                    'stroke-width': 2,

                    zIndex: 8
                  }).on('click', () => {
                    renderPromotionOnChart(pos[0], pos[1], _pS, promotion.plotStartPoint)
                  }).on('mouseover', () => {
                    renderPromotionOnChart(pos[0], pos[1], _pS, promotion.plotStartPoint)
                  }).add()
              ];
              _imgs = [
                ..._imgs, chart.renderer.text(_pS.length.toString(), pos[0] - 4, pos[1] + 5)
                  .css({
                    color: "#ffffff",
                    cursor: "pointer"
                  })
                  .attr({
                    zIndex: 9
                  }).on('click', () => {
                    renderPromotionOnChart(pos[0], pos[1], _pS, promotion.plotStartPoint)
                  }).on('mouseover', () => {
                    renderPromotionOnChart(pos[0], pos[1], _pS, promotion.plotStartPoint)
                  })
                  .add()
              ];
            }
          }

          if (promotion.plotEndPoint >= 0 && !promotion.unKnownEndDate) {
            const point = chart.series[0].points[promotion.plotEndPoint];
            const pos = point.pos(true);
            let _pS = hasMultiplePromotions(promotion.plotEndPoint);

            if (_pS.length == 1)
              _imgs = [
                ..._imgs, chart.renderer.circle(pos[0], pos[1], 8)
                  .attr({
                    fill: promotion.color,
                    stroke: 'white',
                    'stroke-width': 2,
                    zIndex: 8
                  }).css({

                    cursor: 'pointer',
                  }).on('click', () => {
                    renderPromotionOnChart(pos[0], pos[1], [promotion], promotion.plotEndPoint)
                  }).on('mouseover', () => {
                    renderPromotionOnChart(pos[0], pos[1], [promotion], promotion.plotEndPoint)
                  }).add()
              ];
            else {
              _imgs = [
                ..._imgs, chart.renderer.circle(pos[0], pos[1], 10)
                  .css({

                    cursor: 'pointer',
                  })
                  .attr({
                    fill: "#009ef7",
                    stroke: 'white',
                    'stroke-width': 2,

                    zIndex: 8
                  }).on('click', () => {
                    renderPromotionOnChart(pos[0], pos[1], _pS, promotion.plotEndPoint)
                  }).on('mouseover', () => {
                    renderPromotionOnChart(pos[0], pos[1], _pS, promotion.plotEndPoint)
                  }).add()
              ];
              _imgs = [
                ..._imgs, chart.renderer.text(_pS.length.toString(), pos[0] - 4, pos[1] + 5)
                  .css({
                    color: "#ffffff",
                    cursor: 'pointer',
                  })
                  .attr({
                    zIndex: 9
                  }).on('click', () => {
                    renderPromotionOnChart(pos[0], pos[1], _pS, promotion.plotEndPoint)
                  }).on('mouseover', () => {
                    renderPromotionOnChart(pos[0], pos[1], _pS, promotion.plotEndPoint)
                  }).add()
              ];
            }


          }
          let _image;
          if (promotion.imageAssetUrl != null)
            _image = chart.renderer.image(promotion.imageAssetUrl, point1.plotX + chart.plotLeft + 10,
              chart.chartHeight +
              i * campaignVisualHeight -
              activePromotion.length * campaignVisualHeight - 15, 21, 21).

              attr({
                zIndex: 6,


              }).add()
          const nameLen = Math.floor((chart.plotLeft + point2.plotX + 10 - (point1.plotX + chart.plotLeft + 10 + (promotion.imageAssetUrl != null ? 27 : 0))) / 8.435)
          let _text = chart.renderer
            .text(
              clipText(promotion.name, nameLen),
              point1.plotX + chart.plotLeft + 10 + (promotion.imageAssetUrl != null ? 27 : 0),
              chart.chartHeight +
              i++ * campaignVisualHeight -
              activePromotion.length * campaignVisualHeight
            )
            .attr({
              zIndex: 5,
              // stroke: '#ffffff',
              fill: 'white',
              'stroke-width': 0.1,
            })
            .css({
              fontWeight: 700,
              cursor: 'pointer',
            })
            .on('click', () => {
              if (release)
                getSingleCampaign(promotion.id.toString(), release).then((d) => {
                  setshowActivityForm(true)
                  setEditCampaign(d)
                })
            })
            .add()
          let box = _text.getBBox(true)

          if (promotion.imageAssetUrl != null)
            box.x -= 27;
          let _box = chart.renderer
            .rect(
              box.x - 5,
              box.y - 7.5,
              chart.plotLeft + point2.plotX - box.x + 10,
              box.height + 15,
              3
            )
            .attr({
              fill: promotion.color ?? colors[promotion.categoryId % 4],
              stroke: promotion.color ?? colors[promotion.categoryId % 4],
              'stroke-width': 1,
              zIndex: 4,
            })
            .css({
              cursor: 'pointer',
            })
            .on('click', () => {
              if (release)
                getSingleTask(promotion.id.toString(), release).then((d) => {
                  setshowActivityForm(true)
                  setEditCampaign(d)
                })
            })
            .add()
          let _startRect = null,
            _endRect = null,
            _unknownEndComponent1 = null,
            _unknownEndComponent2 = null,
            _unknownEndComponent3 = null
          if (promotion.plotStartPoint < 0)
            _startRect = chart.renderer
              .rect(box.x - 6, box.y - 7.5, 3, box.height + 15, 0)
              .attr({
                fill: '#ffffff',
                stroke: 'white',
                'stroke-width': 1,
                zIndex: 6,
              })
              .add()
          if (promotion.plotEndPoint < 0 || promotion.unKnownEndDate)
            _endRect = chart.renderer
              .rect(
                box.x - 5 + chart.plotLeft + point2.plotX - box.x + 10 - 2,
                box.y - 7.5,
                3,
                box.height + 15,
                0
              )
              .attr({
                fill: '#ffffff',
                stroke: 'white',
                'stroke-width': 1,
                zIndex: 6,
              })
              .add()
          if (promotion.unKnownEndDate) {
            _unknownEndComponent1 = chart.renderer
              .rect(
                box.x - 5 + chart.plotLeft + point2.plotX - box.x + 10 - 10,
                box.y - 7.5,
                3,
                box.height + 15,
                0
              )
              .attr({
                fill: '#ffffff',
                stroke: 'white',
                'stroke-width': 0,
                zIndex: 6,
              })
              .add()
            _unknownEndComponent2 = chart.renderer
              .rect(
                box.x - 5 + chart.plotLeft + point2.plotX - box.x + 10 - 20,
                box.y - 7.5,
                3,
                box.height + 15,
                0
              )
              .attr({
                fill: '#ffffff',
                stroke: 'white',
                'stroke-width': 0,
                zIndex: 6,
              })
              .add()
            _unknownEndComponent3 = chart.renderer
              .rect(
                box.x - 5 + chart.plotLeft + point2.plotX - box.x + 10 - 30,
                box.y - 7.5,
                3,
                box.height + 15,
                0
              )
              .attr({
                fill: '#ffffff',
                stroke: 'white',
                'stroke-width': 0,
                zIndex: 6,
              })
              .add()
          }

          chart.timeline.push([
            _image,
            _text,
            _box,
            _startRect,
            _endRect,
            _unknownEndComponent1,
            _unknownEndComponent2,
            _unknownEndComponent3,
          ])
        }
      }
    })

    // if (_a != undefined) {
    //   chart.removeAnnotation(_a)
    // }

    // _a = chart.addAnnotation({
    //   labelOptions: {
    //     backgroundColor: 'rgba(255,255,255,0.5)',
    //     allowOverlap: true,
    //   },
    //   zIndex: 7,
    //   labels: annotationLabelStart,
    // })
    // if (_e != undefined) {
    //   chart.removeAnnotation(_e)
    // }

    // _e = chart.addAnnotation({
    //   labelOptions: {
    //     allowOverlap: true,
    //   },
    //   zIndex: 7,
    //   labels: annotationLabelEnd,
    // })

    if (_activePromotion.length != activePromotion.length) setActivePromotions(_activePromotion)
  }


  function renderPromotionOnChart(X: number, Y: number, _pS: Promotion[], xAxis: number) {
    let x = X;
    let y = Y;
    closePopups(undefined)
    let hs = 40, w = 150, br = 5, p = 5, h = p + (_pS.length) * (hs + p), zIndex = 11;
    var chart = chartComponentRef?.current?.chart
    const lastPoint = chart?.series[0].points[chart?.series[0].points.length - 1]
    const pos = lastPoint?.pos(true, undefined)
    if (y - (h + 20) < 0) {
      y = y + h + 40
    }
    if (lastPoint)
      if (x + w / 2 > pos![0]) {
        x = x - (x + w / 2 - pos![0])
        console.log(x);
      }

    if (chart) {
      _openPopupCordinates = { x: X, y: Y };
      _popupPromotion = [
        ..._popupPromotion,
        chart.renderer.rect(x - w / 2, y - (h + 20), w, h, br, br)
          .attr({
            fill: '#FFFFFF',
            stroke: '#e1e3ea',
            'stroke-width': 2,
            zIndex: zIndex
          }).add()
      ];
      // console.log(y - (h + 20));
      for (let i = 0; i < _pS.length; i++) {

        const element = _pS[i];
        let isStart;
        if (element.plotStartPoint == xAxis)
          isStart = true;
        else
          isStart = false;

        _popupPromotion = [
          ..._popupPromotion,
          chart.renderer.rect(x - w / 2 + p, y - (h + 20) + p * (i + 1) + hs * i, w - 2 * p, hs, br, br)
            .attr({
              fill: element.color,
              stroke: (isStart ? "#ffffff" : '#0a0a0a'),
              'stroke-width': 1,
              zIndex: zIndex
            }).add()
        ];

        if (element.imageAssetUrl)
          _popupPromotion = [..._popupPromotion,
          chart.renderer.image(
            element.imageAssetUrl,
            x - w / 2 + p + p,
            y - (h + 20) + p * (i + 1) + hs * i + p,
            hs - 2 * p,
            hs - 2 * p
          ).attr({
            zIndex: zIndex
          }).add()];

        let a;
        const t = element.name;
        const at = t.split(" ");
        let clipped = false;

        do {
          if (a)
            a.destroy();
          a = chart.renderer.text(at.join(" ") + (clipped ? ' ...' : ''),
            x - w / 2 + p + p + (element.imageAssetUrl ? hs : 0),
            y - (h + 20) + p * (i + 1) + hs * i + p + 11 + (element.name.length < 16 ? 10 : 0), true)
            .css({
              color: '#ffffff',
              width: !element.imageAssetUrl ? 130 : 90,
              "overflow": 'hidden',
              "font-size": "12px",
              "font-family": "Arial"
            })
            .attr({
              fill: 'rgba(0, 0, 0, 0.75)',
              padding: 8,
              r: 5,
              zIndex: zIndex,

            }).add();
          clipped = true;
          at.pop();
          // console.log(a.getBBox(true).height)
        }
        while (a.getBBox(true).height > 40);
        _popupPromotion = [..._popupPromotion, a];
      }
    }
  }

  function hasMultiplePromotions(x: number) {
    let _pS: Promotion[] = [];
    for (let i = 0; i < promotions.length; i++) {
      const p = promotions[i];
      if (p.plotStartPoint == x || p.plotEndPoint == x)
        _pS = [..._pS, p]
    }
    return _pS;
  }
  function closePopups(e: any) {
    let closePopup = true;
    if (e != undefined && _openPopupCordinates != undefined) {
      if (Math.abs(_openPopupCordinates.x - e.chartX) < 20 && Math.abs(_openPopupCordinates.y - e.chartY) < 20)
        closePopup = false;
    }

    // for (let elem of _popupPromotion) {
    //   const box = elem.getBBox(true)
    //   // console.log(box.x + 75)
    //   // console.log(e.chartX)
    //   // console.log((Math.abs(box.x + 75 - e.chartX)))
    //   // console.log(Math.abs(box.y + 65 - e.chartY))
    // }
    //console.log(closePopup);

    if (closePopup) {
      for (let elem of _popupPromotion)
        elem.destroy()
      _popupPromotion = [];
      _openPopupCordinates = undefined;
    }
  }

  let _a: any, _e: any

  const options = {
    chart: {
      type: 'spline',
      style: {
        fontFamily: "Inter"
      },
      zoomType: 'x',
      panning: true,
      panKey: 'shift',
      events: {
        click: function (e: any) {
          closePopups(e)

        },
        load() {
          //createLabel(this, 'load event', 80, colors[0]);
        },
        redraw() {
          //createLabel(this, 'redraw event', 80, colors[3]);
        },
        render() {
          timeline(this)
        },
      },
    },
    annotations: [],

    caption: {
      text: '',
    },

    title: {
      text: 'Streams',
      align: 'left',
    },

    accessibility: {
      description: '',
      landmarkVerbosity: 'one',
    },


    lang: {
      accessibility: {
        screenReaderSection: {
          annotations: {
            descriptionNoPoints:
              '{annotationText}, at distance {annotation.options.point.x}km, elevation {annotation.options.point.y} meters.',
          },
        },
      },
    },

    credits: {
      enabled: false,
    },

    xAxis: {
      type: 'datetime',

      minRange: 5,
      title: {
        text: null,
      },
      lineColor: '#999',
      tickColor: '#999',
      labels: {
        style: {
          color: '#999',
          fontSize: '12.35px',
          fontFamily: 'Inter, Helvetica, "sans-serif"',
        },
      },
    },

    yAxis: {
      title: {
        text: null,
      },
      labels: {
        format: '{value}',
        style: {
          color: '#999',
          fontSize: '12.35px',
          fontFamily: 'Inter, Helvetica, "sans-serif"',
        },
      },
    },

    tooltip: {
      enabled: true,
      headerFormat: '<b>{series.name}</b><br>',
      pointFormat: '{point.x:%e. %b}: {point.y:.0f} Streams',
    },

    legend: {
      enabled: false,
    },
    series: series,
  }

  // Dialog States

  // const showActivityDialog = (param: any) => {
  //     setshowActivityForm(param);
  // };

  // const showListActivityDialog = (param: any) => {
  //     setShowListActivity(param);
  // };

  const dateOptions = [
    'Start At First Record',
    'Last 1 week',
    'Last 2 weeks',
    'Last 1 month',
    'Last 3 months',
    'Last 6 months',
  ]
  const onChangeDate = function (arg0: any) {
    let yesterday = new Date()
    yesterday.setDate(yesterday.getDate())

    switch (arg0) {
      case dateOptions[1]: {
        let lastWeekDate = new Date()
        lastWeekDate.setDate(lastWeekDate.getDate() - 7)
        setIsStartAtFirstRecord(false);
        setDateFilter(new DateFilter(lastWeekDate, yesterday))
        break
      }
      case dateOptions[0]: {

        let lastMonth = new Date()
        lastMonth.setMonth(lastMonth.getMonth() - 6)
        if (responseCampaign.data.length > 0) {


          const firstCampaign = responseCampaign.data.reduce((data: Task, item: Task) => Date.parse(data.start_date ?? "") < Date.parse(item.start_date ?? "") ? data : item)
          const firstCampaignDate = new Date(Date.parse(firstCampaign.start_date));

          if (firstCampaignDate < lastMonth && firstCampaignDate.getFullYear() > (yesterday.getFullYear() - 2)) {
            firstCampaignDate.setDate(firstCampaignDate.getDate() - 7)
            setDateFilter(new DateFilter(firstCampaignDate, yesterday))
          } else {
            setDateFilter(new DateFilter(lastMonth, yesterday))
          }
        } else {
          setDateFilter(new DateFilter(lastMonth, yesterday))
        }
        setIsStartAtFirstRecord(true);
        break
      }
      case dateOptions[2]: {

        let lastWeekDate = new Date()
        lastWeekDate.setDate(lastWeekDate.getDate() - 14)
        setDateFilter(new DateFilter(lastWeekDate, yesterday))
        setIsStartAtFirstRecord(false);
        break
      }
      case dateOptions[3]: {
        let lastMonth = new Date()
        lastMonth.setMonth(lastMonth.getMonth() - 1)
        setDateFilter(new DateFilter(lastMonth, yesterday))
        setIsStartAtFirstRecord(false);
        break
      }
      case dateOptions[4]: {
        let lastMonth = new Date()
        lastMonth.setMonth(lastMonth.getMonth() - 3)
        setDateFilter(new DateFilter(lastMonth, yesterday))
        setIsStartAtFirstRecord(false);
        break
      }
      case dateOptions[5]: {
        let lastMonth = new Date()
        lastMonth.setMonth(lastMonth.getMonth() - 6)
        setDateFilter(new DateFilter(lastMonth, yesterday))
        setIsStartAtFirstRecord(false);
        break
      }

      default: {
        let lastWeekDate = new Date()
        lastWeekDate.setDate(lastWeekDate.getDate() - 14)
        setDateFilter(new DateFilter(lastWeekDate, yesterday))
        setIsStartAtFirstRecord(false);
        break
      }
    }
  }

  // const onChangePromotion = function (arg0: any[]) {
  //     let _selectedPromoion: any[] = [];
  //     for (var k in arg0) {
  //         _selectedPromoion = [...selectedPromotion, allPromotions[k]]
  //     }
  //     console.log(_selectedPromoion);
  //     setSelectedPromotions(_selectedPromoion);
  // }
  //console.log(options.chart.marginBottom);

  useEffect(() => {
    setTimeout(() => {
      const chart = chartComponentRef?.current?.chart
      if (chart != null && activePromotion.length == 0) {
        chart.setSize(null, null, false)
      }
    }, 3000)
  }, [])

  return (
    <>
      {isFetching ? <Loader /> : <></>}
      <div className='assets_head'>
        <h3>Trends</h3>
        <div>
          {/* <MultiSelectionDropdown options={options3} onChange={function () { }} selecType='Streams' /> */}
          <div className='form-check heading-checkbox mt-2 ms-3'>
            <input
              className='form-check-input'
              type='checkbox'
              value={1}
              checked={hideCampaigns}
              onChange={() => {
                setHideCampaigns(!hideCampaigns)
                if (!hideCampaigns)
                  setSelectedPromotionOption([]);
              }}
            />
            <label className='form-check-heading'>Hide Campaigns</label>
          </div>
          <SingleSelectionDropdown
            options={dateOptions}
            filterName={dateOptions[2]}
            onChange={onChangeDate}
          />
          <MultiSelectionDropdown
            options={songs}
            onChange={setSelectedSong}
            text='Songs'
            enableSelectedOption={false}
            selectedOption={[]}
            menuAlignment='right'
            menuClass={songs.filter((e: any, i) => { return e.label.length > 20 }).length > 0 ? 'w-300px' : ''}
          />
          <MultiSelectionDropdown
            options={promotionOption}
            onChange={setSelectedPromotionOption}
            text='Campaigns'
            enableSelectedOption={true}
            selectedOption={selectedPromotionOption}

            noItemText='Create campaigns to see them here'
          />
          {/* <a className="btn btn-sm fw-bold btn-light-primary   mb-1 ms-3 rightauto" onClick={() => showListActivityDialog(true)}>View Campaigns</a>
                    {currentUser &&
                        <a className="btn btn-sm fw-bold btn-primary mb-1 ms-3 " onClick={() => showActivityDialog(true)}><span>+</span> Add Campaign</a>
                    } */}
        </div>
      </div>
      { }
      <HighchartsReact ref={chartComponentRef} highcharts={Highcharts} options={options} />

      <div className='assets_head mb-50 mt-10'></div>

      {showActivityForm ? (
        <AddTask
          dialogAction={showActivityForm}
          editId={editCampaign ? editCampaign.id : 0}
          editData={editCampaign}
          viewMode={currentUser && currentUser.role != 'partner' ? false : true}
          onChange={() => {
            setEditCampaign(undefined)
            refetchCampaign()
            setshowActivityForm(false)
          }}
          onDataChange={(d) => { }}
        />
      ) : null}

      {/* {showListActivity ? <ViewActivity dialogAction={showListActivity} sendData={showListActivityDialog} callback={refetchCampaign} /> : null} */}
    </>
  )
}
var _imgs: Highcharts.SVGElement[] = []
var _popupPromotion: Highcharts.SVGElement[] = []
var _openPopupCordinates: { x: number, y: number } | undefined = undefined;

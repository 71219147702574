/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { KTIcon } from '../../../../../../_metronic/helpers';
import Loader from '../../../../global-module/loader/loader';


// Load services
import instance from '../../../../../services/axiosConfig';

// Load other component
import DeleteConfirmationDialog from '../../../deleteConfirmationDialog/deleteConfirmationDialog';
import { formatDateTime } from '../../../../dashboard/core/Helpers';
import { useAuth } from '../../../../auth';

type Props = {
  isDrawer?: boolean,
  currentTaskId?: any
}

const ChatInner: FC<Props> = ({ isDrawer = false, currentTaskId }) => {
  const { currentUser, release } = useAuth();
  useEffect(() => {
    fetchAllComment();
  }, [currentTaskId]);

  // Fetch Comment
  const [listComment, setAllComment] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchAllComment = async () => {


    if (currentTaskId) {
      setIsLoading(true);
      const response = await instance.get('/task/comment/' + currentTaskId + "?release_id=" + release);
      setFormData({ ...formData, ['task_id']: currentTaskId });
      setAllComment(response.data?.data);
      setIsLoading(false);
    }


  };

  // Start form
  const [commentId, setcommentId] = useState(0);

  interface TaskComment {
    task_id: number;
    text: string;
  }

  const [formData, setFormData] = useState<any>({
    task_id: currentTaskId,
    text: '',
  });

  const [errors, setErrors] = useState({
    task_id: '',
    text: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (e.target.value == '') {
      formData.text = '';
      setcommentId(0);
    }
  };

  const validateForm = (data: any) => {
    const errors: any = {};

    if (!data.task_id) {
      errors.task_id = 'Task Id is required.';
    }

    if (!data.text) {
      errors.text = 'Please enter message';
    }

    return Object.keys(errors).length ? errors : null;
  };

  const handleSubmit = async () => {

    // Validate form data
    const validationErrors = validateForm(formData);
    if (validationErrors) {
      setErrors(validationErrors);
      console.log(validationErrors);
      return;
    }

    if (commentId != 0) {

      // Add data
      setIsLoading(true);
      try {
        const response = await instance.put('/task/comment/' + commentId, formData);

        toast.success(response?.data?.message);
        fetchAllComment();
        setTimeout(function () {
          setIsLoading(false);
        }, 2000);
        formData.text = '';
        setcommentId(0);
      } catch (error) {
        toast.error('something went wrong please try again');
        fetchAllComment();
        setTimeout(function () {
          setIsLoading(false);
        }, 2000);
      }
    }
    else {
      // Add data
      setIsLoading(true);
      try {

        const response = await instance.post('/task/comment', formData);
        toast.success(response?.data?.message);
        fetchAllComment();
        setTimeout(function () {
          setIsLoading(false);
        }, 2000);
        formData.text = '';
        setcommentId(0);
      } catch (error) {
        toast.error('something went wrong please try again');
        fetchAllComment();
        setTimeout(function () {
          setIsLoading(false);
        }, 2000);
      }
    }

  };

  // Edit Action

  const editiComment = (itemId: number, currentTask: any) => {
    setcommentId(itemId);
    setFormData(currentTask);
  }

  // Delete Comment

  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isDeleteId, setDeleteId] = useState(0);

  const handleDelete = async () => {

    try {

      const response = await instance.delete('/task/comment/' + isDeleteId);
      toast.success('Comment Deleted Successfully');
      setDeleteDialogOpen(false);
      fetchAllComment();
    } catch (error) {
      toast.error('Sorry, Unable to Delete Comment');
      setDeleteDialogOpen(false);
      fetchAllComment();
    }

  };

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false);
  };


  const deleteComment = (param: any, itemId: number) => {
    setDeleteId(itemId);
    setDeleteDialogOpen(param);
  }

  return (
    isLoading ? <div>Loading...</div> :
      <>
        <DeleteConfirmationDialog
          isOpen={isDeleteDialogOpen}
          onCancel={handleCancelDelete}
          onConfirm={handleDelete}
        />

        <div className='commentbox'>
          {listComment.length < 1 ? <span className="dull">No Comment Added</span> : null}
          {listComment.map((commentRow, index) => (
            <div key={index} className="comment">
              {currentUser ?
                <div className="editdiv">
                  <a className='btn btn-sm p-0 me-1' onClick={() => editiComment(commentRow.id, commentRow)}>
                    <KTIcon iconName='pencil' className='fs-3' />
                  </a>
                  <a className='btn btn-sm p-0' onClick={() => deleteComment(true, commentRow.id)}>
                    <KTIcon iconName='trash' className='fs-3' />
                  </a>
                </div> : null}
              <div className='imgdiv'>
                <div className='imgs'>{commentRow.user.name.slice(0, 2).toUpperCase()}</div>
              </div>
              <div className='datadiv'>
                <div className="namesdiv"><span className='names'>{commentRow.user.name}</span> <span className='dayago'>{(new Date(commentRow.created_at)).toLocaleDateString()}</span></div>
                <div className="desq">{commentRow?.text}</div>
              </div>
            </div>
          ))}
        </div>
        {currentUser ?
          <div className='commentboxform'>
            <div className='position-relative'>
              <input type="text" className='form-control' id='text' name="text" value={formData.text} onChange={handleChange} placeholder="Add new comment" />
              {errors.text && formData.text === '' && <div className='error-form'>{errors.text}</div>}
              <button type='submit' className='btn btn-submit' onClick={handleSubmit} >
                {commentId != 0 ? 'Update' : 'Send'}
              </button>
            </div>
          </div> : null
        }
      </>
  )
}

export { ChatInner }

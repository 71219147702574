/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import * as Yup from 'yup'

import { ReleaseHeader } from './ReleaseHeader'
import { Trends } from './Trends'
import { Tasks } from './tasks/Tasks'
import { Assets } from './assets/Assets';
import { DrawerMessenger } from '../../global-module/components/task-comment'

import { HeaderWrapper } from '../../global-module/components/header';
import { Sidebar } from '../../global-module/components/sidebar';
import { useAuth } from '../../auth/core/Auth'
import { Navigate, Outlet, useParams } from 'react-router-dom'


export function Common(props: any) {
  const { currentUser } = useAuth()
  return (
    <>
      <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
        <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
          <>
            {currentUser &&
              <HeaderWrapper />
            }
            <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
              {currentUser &&
                <Sidebar />
              }
              <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                <div className='d-flex flex-column flex-column-fluid'>
                  <Outlet></Outlet>
                  {/* <DrawerMessenger /> */}
                </div>
              </div>
            </div>

          </>
        </div>
      </div>
    </>
  )
}

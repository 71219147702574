import React, { useState, useEffect, ChangeEvent } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

import Loader from '../../../../global-module/loader/loader';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


// Load services

import { useCommonContext } from '../../../../../services/CommonContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import { removeLastRelease, useAuth } from '../../../../auth';
import instance from '../../../../../services/axiosConfig';
import { generateQuery } from '../../../core/Helpers';
import { Filter } from '../../../core/_models';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import { SearchInner } from '../../../../global-module/components/SearchInner';
import { deleteRelease, importRelease } from '../../../core/_requests';
import { useParams } from 'react-router-dom';
let cancelToken: any
interface AddReleaseProps {
    showReleaseDailog: boolean,
    onChange(arg0: boolean): any;
}
const AddRelease: React.FC<AddReleaseProps> = ({ showReleaseDailog = false, onChange }) => {


    const { releaseDeleted, setReleaseDeleted } = useCommonContext()

    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1)
    const [listReleases, setReleases] = useState<any[]>([])
    const [totalRelease, setTotalRelease] = useState(0)
    const [filters, setFilters] = useState<Filter[]>([])
    const [searchText, setSearchText] = useState<any>('')
    const [checked, setChecked] = useState<number[]>([])
    const [openDeleteDailog, setOpenDeleteDailog] = useState<boolean>(false)
    const [removeReleaseCandidate, setRemoveReleaseCandidate] = useState<string | undefined>(undefined)
    const [toBeDeleted, setToBeDeleted] = useState<string | undefined>(undefined)
    const { currentUser } = useAuth()
    const handleClose = () => {
        onChange(false);
    };
    const { id }: any = useParams<any>()
    useEffect(() => {

        fetchReleases(page.toString()).then((d) => {
            setReleases(d)
        })
    }, [filters, releaseDeleted])

    useEffect(() => {
        setPage(1)
        setFilters([
            {
                name: 'search',
                value: [searchText],
            },
        ])
        console.log(searchText)
    }, [searchText])

    const fetchReleases = async (page?: string) => {
        try {
            if (typeof cancelToken != typeof undefined) {
                cancelToken.cancel('canceled')
            }

            cancelToken = axios.CancelToken.source()
            setIsLoading(true)

            let response;
            response = (
                await instance.get('/fetchigroovereleases' + generateQuery(filters) + '&page=' + (page ? page : 1), {
                    cancelToken: cancelToken.token,
                })
            ).data

            let listoutReleases = response.data
            setTotalRelease(response.total)

            let findReleasesArray: any = []
            findReleasesArray = listoutReleases

            setIsLoading(false)

            return findReleasesArray
        } catch (e: any) {
            console.log(e)
            if (e.message != 'canceled') setIsLoading(false)
        }
        return []
    }

    const perPage = 30

    const handleSubmit = () => {
        // await instance
        importRelease(checked.join(',')).then(() => {
            toast.success('Release Imported Successfully')
            fetchReleases(page.toString()).then((d) => {
                setReleases(d)
            })
        })
            .catch(() => {
                toast.error('Release Import Failed due to some errors.')
            })
    }

    async function checkHandler(event: ChangeEvent<HTMLInputElement>) {

        if (checked.indexOf(parseInt(event.currentTarget.value)) == -1) {
            setChecked([...checked, parseInt(event.currentTarget.value)])
        } else {
            setChecked(checked.filter(function (item) {
                return item !== parseInt(event.currentTarget.value)
            }))
        }
    }

    const handleSourceChange = () => { }

    const removeRelease = (releaseId: string) => {

        deleteRelease(releaseId)
            .then(() => {
                toast.success('Release Deleted Successfully')
                setReleaseDeleted(Number(releaseId));
            })
            .catch(() => {
                toast.error('Release Deletion Failed due to some errors.')
            })
    }

    return (
        <>
            <Modal show={showReleaseDailog} onHide={handleClose}>
                <div className="custom_modal">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Add Releases
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className='col-md-12'>
                                {/* <div className="separator my-3"></div> */}
                                <div className='d-flex justify-content-between'>
                                    <span className='mt-3'>
                                        Select Source
                                    </span>
                                    <div className="">
                                        <select className='form-control' name='user_id' value="igroove" onChange={handleSourceChange}>
                                            {/* <option value='' >Select Assignee</option> */}
                                            <option key="igroove" value="igroove">iGroove</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="separator my-3"></div>
                                <InfiniteScroll
                                    dataLength={page * perPage}
                                    next={() => {
                                        fetchReleases((page + 1).toString()).then((d) => {
                                            setReleases([...listReleases, ...d])
                                            setPage(Number(page) + 1)
                                        })
                                    }}

                                    hasMore={page * perPage < totalRelease}
                                    loader={null}
                                    height={400}
                                    endMessage={
                                        <p style={{ textAlign: 'center' }} className='text-white'>
                                            {/* <b>Yay! You have seen it all</b> */}
                                        </p>
                                    }
                                >
                                    <SearchInner initalValue={searchText} onChange={function (arg0: string) {
                                        console.log(arg0)
                                        setSearchText(arg0)
                                    }}></SearchInner>

                                    {!isLoading && listReleases.length < 1 ? (
                                        <div className=' text-center mb-2'>No Record found</div>
                                    ) : null}

                                    {listReleases.map((releasesData, i) => (
                                        // <a
                                        //     key={i}
                                        //     className='menu-item'
                                        //     onClick={(e) => { }}
                                        //     href={'/share/' + releasesData?.id}
                                        // >
                                        <div className='menu-item'>
                                            <span className='menu-link px-3 py-3 active' data-kt-element='project'>
                                                <div className=' d-flex me-4 form-check form-check-sm form-check-custom form-check-solid'>
                                                    <input
                                                        className='form-check-input widget-13-check'
                                                        type='checkbox'
                                                        checked={checked.indexOf(parseInt(releasesData?.id)) != -1}
                                                        value={releasesData?.id}
                                                        onChange={checkHandler}
                                                        disabled={releasesData?.already_imported ? true : false}
                                                    />
                                                </div>

                                                <span className='d-flex flex-center flex-shrink-0 w-40px me-3'>
                                                    <img
                                                        alt='Logo'
                                                        src={releasesData?.cover_url}
                                                        data-kt-element='logo'
                                                        width={45}
                                                    />
                                                </span>
                                                <span className='d-flex flex-column align-items-start'>
                                                    <span className='fs-5 fw-bold text-black' data-kt-element='title'>
                                                        {releasesData?.title}&nbsp;
                                                        {releasesData.internal_tag.length > 0 ? (
                                                            <img
                                                                className='mb-2'
                                                                src={toAbsoluteUrl('/media/misc/note.png')}
                                                                id='dropdownUserAction'
                                                                data-bs-toggle='dropdown'
                                                                aria-expanded='false'
                                                            ></img>
                                                        ) : null}
                                                    </span>
                                                    <span className='fs-7 fw-bold text-gray-700 lh-sm' data-kt-element='desc'>
                                                        {releasesData?.fullArtist}
                                                    </span>
                                                </span>
                                                {
                                                    releasesData?.already_imported ? <span className='d-flex flex-center flex-shrink-0  m-l-auto'>
                                                        <a
                                                            className='text-danger'
                                                            onClick={() => {
                                                                setOpenDeleteDailog(true);
                                                                setRemoveReleaseCandidate(releasesData?.id);
                                                                setToBeDeleted(releasesData?.total_campaigns + ' Campaigns, ' + releasesData?.total_tasks + ' Tasks, ' + releasesData?.total_assets + ' Assets will be deleted.');
                                                            }}
                                                        ><i className='fa text-danger fa-trash'></i> Remove Release</a>
                                                    </span> :
                                                        null
                                                }


                                            </span>
                                            <div className="separator my-3"></div>
                                        </div>

                                    ))}
                                    {isLoading ? <div className=' text-center mb-2'>Loading...</div> : null}
                                </InfiniteScroll>
                            </div>

                        </div>
                        <DeleteConfirmationDialogWithTerms isOpen={openDeleteDailog} onCancel={function (): void {
                            setRemoveReleaseCandidate(undefined);
                            setOpenDeleteDailog(false)
                        }} onConfirm={function (): void {
                            removeRelease(removeReleaseCandidate!)
                            setOpenDeleteDailog(false)
                        }}
                            heading='Are you sure you want to delete?'
                            body={toBeDeleted}
                        />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="" onClick={handleSubmit} className='btn-active'>
                            Import Releases
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal>

        </>
    )
}



interface DeleteConfirmationDialogProps {
    isOpen: boolean;
    heading?: string;
    body?: string;
    onCancel: () => void;
    onConfirm: () => void;
}

export const DeleteConfirmationDialogWithTerms: React.FC<DeleteConfirmationDialogProps> = ({
    isOpen,
    onCancel,
    onConfirm,
    heading = "Are you sure you want to delete?",
    body = ""
}) => {
    const [checkbox, setCheckbox] = useState<boolean>(false)

    if (!isOpen) {
        return null; // If the dialog is not open, don't render anything
    }
    return (
        <div className="delete-confirmation-dialog">
            <div className="dialog-box">
                <div className="dialog-text-head fs-4 fw-bold">
                    {heading}
                </div>
                <div className="dialog-text-head">
                    {body}
                </div>
                <div>
                    <div className='form-check form-check-sm form-check-custom form-check-solid mt-2 mb-2'>
                        <input
                            className='form-check-input widget-13-check'
                            type='checkbox'
                            checked={checkbox}
                            value={1}
                            onChange={() => {
                                setCheckbox(!checkbox)
                            }}
                        /> &nbsp;
                        I have understood and agree.
                    </div>

                </div>

                <button className='btn btn-sm fw-bold ' onClick={() => {
                    setCheckbox(false);
                    onCancel();
                }}>Cancel</button>
                <button className='btn btn-sm fw-bold btn-danger ms-3' disabled={!checkbox} onClick={() => {
                    setCheckbox(false);
                    onConfirm();
                }}>Delete</button>
            </div>
        </div>
    );
};


export default AddRelease;
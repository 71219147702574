import React from 'react';

interface DeleteConfirmationDialogProps {
  isOpen: boolean;
  message?: string;
  onCancel: () => void;
  onConfirm: () => void;
}

const DeleteConfirmationDialog: React.FC<DeleteConfirmationDialogProps> = ({
  isOpen,
  onCancel,
  onConfirm,
  message = "Are you sure you want to delete?"
}) => {
  if (!isOpen) {
    return null; // If the dialog is not open, don't render anything
  }

  return (
    <div className="delete-confirmation-dialog">
      <div className="dialog-box">
        <div className="dialog-text">
          {message}
        </div>
        <button className='btn btn-sm fw-bold ' onClick={onCancel}>Cancel</button>
        <button className='btn btn-sm fw-bold btn-danger ms-3' onClick={onConfirm}>Confirm</button>
      </div>
    </div>
  );
};

export default DeleteConfirmationDialog;
import React, { useState, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

export interface Option {
  id: number
  label: string
}

interface CheckboxMultipleSelectProps {
  options1: Option[]
  options2: Option[]
  enableSelectedOption: boolean | false
  selectedOption1: Option[]
  selectedOption2: Option[]
  buttonText: string
  text1: string
  text2: string
  onChange1(arg0: Option[]): any
  onChange2(arg0: Option[]): any
  total1?: number | undefined
  total2?: number | undefined
  loadMore1?(): any
  loadMore2?(): any
  dropdownHeight?: number | undefined
  noItemText?: string | undefined
  isLoading?: boolean | false
}

const MultiSelectionGroupedDropdown: React.FC<CheckboxMultipleSelectProps> = ({
  options1,
  options2,
  enableSelectedOption = false,
  selectedOption1 = [],
  selectedOption2 = [],
  buttonText,
  text1,
  text2,
  onChange1,
  onChange2,
  total1 = options1.length,
  total2 = options2.length,
  loadMore1 = () => { },
  loadMore2 = () => { },
  dropdownHeight = undefined,
  noItemText = '',
  isLoading = false,
}) => {
  const [selectedOptions1, setSelectedOptions1] = useState<any[]>([])
  const [changed1, setChanged1] = useState<boolean>(false)

  const [selectedOptions2, setSelectedOptions2] = useState<any[]>([])
  const [changed2, setChanged2] = useState<boolean>(false)

  const [hideCampaign, setHideCampaign] = useState<boolean>(false)
  const [hideTasks, setHideTasks] = useState<boolean>(false)

  useEffect(() => {
    if (enableSelectedOption) {
      const initialSelection = options1.filter((option: any) => selectedOption1.includes(option.id))
      setSelectedOptions1(initialSelection)
    }
  }, [options1, selectedOption1])

  const handleOptionChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const optionId = Number(event.target.value)
    const isChecked = event.target.checked
    let changedOption: Option[]
    if (isChecked) {
      changedOption = [...selectedOptions1, options1.find((e) => e.id == optionId)]
    } else {
      changedOption = selectedOptions1.filter((e) => e.id !== optionId)
    }
    setSelectedOptions1(changedOption)
    onChange1(changedOption)
    setChanged1(true)
  }

  const checkValue1 = function (option: any) {
    let found: boolean = false
    for (var k in selectedOptions1) {
      if (selectedOptions1[k].id == option.id) {
        found = true
      }
    }
    if (found) return true
    else return false
  }

  //Second option

  useEffect(() => {
    if (enableSelectedOption) {
      const initialSelection = options2.filter((option: any) => selectedOption2.includes(option.id))
      setSelectedOptions2(initialSelection)
    }
  }, [options2, selectedOption2])

  const handleOptionChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const optionId = Number(event.target.value)
    const isChecked = event.target.checked
    let changedOption: Option[]
    if (isChecked) {
      changedOption = [...selectedOptions2, options2.find((e) => e.id == optionId)]
    } else {
      changedOption = selectedOptions2.filter((e) => e.id !== optionId)
    }
    setSelectedOptions2(changedOption)
    onChange2(changedOption)
    setChanged2(true)
  }

  const checkValue2 = function (option: any) {
    let found: boolean = false
    for (var k in selectedOptions2) {
      if (selectedOptions2[k].id == option.id) {
        found = true
      }
    }
    if (found) return true
    else return false
  }

  return (
    <>
      <div className='dropdown sele_dropdown mb-1'>
        <button
          className='btn dropdown-toggle btn-sm fw-bold btn-secondary ms-3'
          type='button'
          id='dropdownMenuButton1'
          data-bs-toggle='dropdown'
          aria-expanded='false'
        >
          {changed1 || changed2 ? (
            selectedOptions1.length + selectedOptions2.length == 0 && (!hideTasks && !hideCampaign) ? (
              ''
            ) : (
              <>
                <i className='fas fa-check text-primary'></i>{' '}
                {selectedOptions1.length + selectedOptions2.length > 0 ? selectedOptions1.length + selectedOptions2.length : ''}
              </>
            )
          ) : ''}
          {' '}
          {buttonText}
        </button>
        <div
          className='dropdown-menu'
          aria-labelledby='dropdownMenuButton1'
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <div className='form-check'>
            <input
              className='form-check-input'
              type='checkbox'
              checked={hideTasks}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const isChecked = event.target.checked
                setHideTasks(isChecked)
                setSelectedOptions1([])
                if (isChecked) onChange1([{ id: -1, label: 'all' }])
                else onChange1([])
              }}
            />
            <label className='form-check-label'>Hide All Tasks</label>
          </div>
          <div className='form-check'>
            <input
              className='form-check-input'
              type='checkbox'
              checked={hideCampaign}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const isChecked = event.target.checked
                setHideCampaign(isChecked)
                setSelectedOptions2([])
                if (isChecked) onChange2([{ id: -1, label: 'all' }])
                else onChange2([])
              }}
            />
            <label className='form-check-label'>Hide All Campaigns</label>
          </div>

          <div className='separator mb-5 mt-5'></div>
          <strong>{text1}</strong>
          <InfiniteScroll
            dataLength={options1.length}
            next={loadMore1}
            hasMore={options1.length < total1}
            loader={<h4>Loading...</h4>}
            height={dropdownHeight}
            endMessage={
              <p style={{ textAlign: 'center' }} className='text-white'>
                {/* <b>Yay! You have seen it all</b> */}
              </p>
            }
          >
            {isLoading ? <h4>Loading...</h4> : null}
            {options1.length == 0 && !isLoading ? (
              <div className='form-check'>{noItemText ? noItemText : 'No Record Found'}</div>
            ) : null}
            {options1.map((option, index) => (
              <div className='form-check' key={index}>
                <input
                  className='form-check-input'
                  type='checkbox'
                  disabled={hideTasks}
                  value={option.id}
                  checked={checkValue1(option)}
                  onChange={handleOptionChange1}
                />
                <label className='form-check-label'>{option.label}</label>
              </div>
            ))}
          </InfiniteScroll>
          <strong>{text2}</strong>
          <InfiniteScroll
            dataLength={options2.length}
            next={loadMore2}
            hasMore={options2.length < total2}
            loader={<h4>Loading...</h4>}
            height={dropdownHeight}
            endMessage={
              <p style={{ textAlign: 'center' }} className='text-white'>
                {/* <b>Yay! You have seen it all</b> */}
              </p>
            }
          >
            {isLoading ? <h4>Loading...</h4> : null}
            {options2.length == 0 && !isLoading ? (
              <div className='form-check'>{noItemText ? noItemText : 'No Record Found'}</div>
            ) : null}
            {options2.map((option, index) => (
              <div className='form-check' key={index}>
                <input
                  className='form-check-input'
                  type='checkbox'
                  disabled={hideCampaign}
                  value={option.id}
                  checked={checkValue2(option)}
                  onChange={handleOptionChange2}
                />
                <label className='form-check-label'>{option.label}</label>
              </div>
            ))}
          </InfiniteScroll>
        </div>
      </div>
    </>
  )
}

export default MultiSelectionGroupedDropdown

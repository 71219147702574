export interface Release {
  // constructor(id: string, imageUrl: string, title: string, artist: string, year: string, releaseDate: Date, linkSharingWithBudgetEnabled: boolean, linkSharingWithoutBudgetEnabled: boolean) {
  //   this.id = id;
  //   this.imageUrl = imageUrl;
  //   this.title = title;
  //   this.artist = artist;
  //   this.year = year;
  //   this.releaseDate = releaseDate;
  //   this.linkSharingWithBudgetEnabled = linkSharingWithBudgetEnabled
  //   this.linkSharingWithoutBudgetEnabled = linkSharingWithoutBudgetEnabled
  // }
  id: string
  imageUrl: string
  title: string
  artist: string
  year: string
  releaseDate: Date
  linkSharingWithBudgetEnabled: boolean
  linkSharingWithoutBudgetEnabled: boolean
  linkSharingWithBudget: String
  linkSharingWithoutBudget: String
  projectedStreams: number
  projectedCreations: number
  projectBudget: number
  internalTag: string
  isBundle?:boolean|false
  child?:any[]
}

export interface ReleaseStats {
  currentMonthlyListeners: string | undefined
  currentTotalCreations: string | undefined
  currentTotalStreams: string | undefined

  growthMonthlyListeners: string | undefined
  growthTotalCreations: string | undefined
  growthTotalStreams: string | undefined
  totalBudget: number | undefined
  taskBudget: number | undefined
  campaignBudget: number | undefined
  assetBuget: number
  taskBudgetCount: number
  campaignBudgetCount: number
  assetBudgetCount: number
  taskCategoryBudget: CategoryBudget[]
  campaignCategoryBudget: CategoryBudget[]
  assetCategoryBudget: CategoryBudget[]
}

export interface CategoryBudget {
  percentage: number | 0
  category_name: string
  budget: number
  category_id: number
  count: number
}
export class Promotion {
  constructor(
    name: string,
    isVisible: boolean,
    duration: Array<number>,
    plotStartPoint: number,
    plotEndPoint: number,
    categoryId: number,
    imageAssetUrl: string | undefined = undefined,
    color: string,
    id: number
  ) {
    this.id = id
    this.name = name
    this.isVisible = isVisible
    this.duration = duration
    this.plotStartPoint = plotStartPoint
    this.plotEndPoint = plotEndPoint
    this.categoryId = categoryId
    this.imageAssetUrl = imageAssetUrl
    this.color = color
    this.unKnownEndDate = false
  }
  unKnownEndDate: boolean
  id: number
  name: string
  isVisible: boolean
  duration: Array<number>
  plotStartPoint: number
  plotEndPoint: number
  categoryId: number
  imageAssetUrl: string | undefined
  color: string
}

export class DateFilter {
  constructor(startDate: Date, endDate: Date) {
    this.startDate = startDate
    this.endDate = endDate
  }
  startDate: Date
  endDate: Date
}

export interface TaskCategory {
  id: number
  name: string
  created_at: number
}

// Fetch Status

export interface Taskstatus {
  id: number
  name: string
  created_at: number
}

export interface Task {
  id: number | undefined
  title: string
  task_category_id?: number
  user_id: number
  start_week: string
  start_date?: string
  is_fixed_date: boolean
  budget: number
  tags: string
  task_status_id: number
  description: string
  release_id: any
  type: string
  campaign_id: number | null
  end_week?: string
  end_date?: string
  campaign_type_id?: number
  result?: string
  asset_url?:string
  asset?:any
}

export interface Filter {
  name: string
  value: string[]
}
export interface Activity {
  id: number
  campaign_type_id: number
  release_id: string
  budget: number
  name: string
  start_date: string
  end_date: string
  campaign_type: CampaignType
  result: string
  user_id: number
}

export interface CampaignType {
  id: number
  name: string
}

export interface Asset {
  id?: number
  release_id: string
  name: string
  url: string
  budget: number
  asset_category_id: number
  created_at?: string
  updated_at?: string
}

export interface TaskComment {
  id?: number
  text: string
  created_by: number
  created_at?: string
  updated_at?: string
  user: User
}

export interface User {
  id?: number
  name: string
  email: string

}
export interface AddProps {
  name?: string,
  budget?: number,
  startDateIsFixedDate?: boolean,
  startDate?: string,
  startWeek?: string,
  taskToBeDeletedId?: number,
  campaignToBeDeletedId?: number
}

export let initialData: AddProps = {
  name: '',
  budget: 0,
  startDate: '',
  startWeek: '',
  startDateIsFixedDate: false
}
import { getAuth, removeAuth } from "../modules/auth";
import { useAuth } from "../modules/auth/core/Auth";
// auth.ts
const SETTINGS_LOCAL_STORAGE_KEY = 'app-setting';
export const saveToken = (token: string) => {
    localStorage.setItem('token', token);
};

export const getToken = () => {
    const auth = getAuth();
    return auth?.token;
};

export const removeToken = () => {
    removeAuth();
};

export const saveSettings = (settigns: Settings) => {
    localStorage.setItem(SETTINGS_LOCAL_STORAGE_KEY, JSON.stringify(settigns));
}

export const getSettings = (): Settings => {
    let initialSettings: Settings = {
        hideCompleted: false
    }
    if (!localStorage) {
        return initialSettings
    }

    const lsValue: string | null = localStorage.getItem(SETTINGS_LOCAL_STORAGE_KEY)
    if (!lsValue) {
        return initialSettings
    }

    try {
        const data = JSON.parse(lsValue);
        const settings: Settings = {
            hideCompleted: data.hideCompleted,
        } as Settings

        if (settings) {
            return settings
        }
    } catch (error) {
        console.log(error);
        console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
    }
    return initialSettings;
}


export interface Settings {
    hideCompleted: boolean | false
}
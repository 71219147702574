import React, { useState, useEffect } from 'react'
import axios from 'axios'
import moment from 'moment'
import { ToastContainer, toast } from 'react-toastify'

import { Tabs, Tab } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

// Load other component
import DeleteConfirmationDialog from '../../../global-module/deleteConfirmationDialog/deleteConfirmationDialog'

// Assets Dialog
import AddEditAssets from './dialog/add-edit-assets'

// Load services
import instance from '../../../../services/axiosConfig'
import Loader from '../../../global-module/loader/loader'
import { useLocation } from 'react-router-dom'
import { useAuth } from '../../../auth/core/Auth'
import { Asset } from '../../core/_models'
import { useCommonContext } from '../../../../services/CommonContext'

// Tab Call Select
type TabSelectCallback = (eventKey: string | null) => void

export function Assets() {
  // Add Assets dialog

  const [show, setShow] = useState(false)
  const [edit, setEdit] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [editData, setEditData] = useState<Asset>()
  const [listAssets, setAssets] = useState<Asset[]>([])
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [isDeleteId, setDeleteId] = useState(0)
  const [activeTab, setActiveTab] = useState<string>('allAssets')
  const { budgetUpdated, setBudgetUpdated } = useCommonContext()

  const handleShow = (param: any) => {
    setShow(param)
  }
  const { currentUser, release } = useAuth()

  // Fetch the Assets Category

  interface Assetscategory {
    id: number
    name: string
    created_at: number
  }

  const [listAssetsCategory, setAssetsCategory] = useState<Assetscategory[]>([])

  // Assets Listing

  useEffect(() => {
    fetchAssetsCategory()
    if (release) fetchAssetsByCategory('allAssets', release)
  }, [release])

  const fetchAssetsCategory = async () => {
    try {
      const response = await instance.get('/asset-category')
      setAssetsCategory(response.data?.data)
    } catch (error) { }
  }
  const fetchAssetsByCategory = async (category: any, releaseId: string) => {
    try {
      let apiUrl: string = ''

      if (category == 'allAssets') {
        apiUrl = '/asset?release_id=' + releaseId
      } else {
        apiUrl = '/asset?asset_category_id=' + category + '&release_id=' + releaseId
      }

      const response = await instance.get(apiUrl)
      setAssets(response.data?.data)
      setIsLoading(false)
    } catch (error) {
      //Todo: Show API Error
      setIsLoading(false)
    }
  }

  const deleteActivityDialogOpen = (param: any, itemId: number) => {
    setDeleteId(itemId)
    setDeleteDialogOpen(param)
  }

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false)
  }

  const handleDelete = async () => {
    try {
      const response = await instance.delete('/asset/' + isDeleteId)

      toast.success('Delete Assets Successfully')
      setDeleteDialogOpen(false)
      setBudgetUpdated(!budgetUpdated)
      if (release) fetchAssetsByCategory(activeTab, release)
    } catch (error) {
      toast.error('Delete Assets Failed')
      setDeleteDialogOpen(false)
      if (release) fetchAssetsByCategory(activeTab, release)
    }
  }

  const editAssets = (data: Asset) => {
    setEditData(data)
    setEdit(true)
  }

  const handleTabSelect: TabSelectCallback = (eventKey) => {
    setIsLoading(true)
    if (eventKey !== null) {
      setActiveTab(eventKey)
      if (release) fetchAssetsByCategory(eventKey, release)
    }
  }

  return (
    <>
      {currentUser && currentUser.role != "partner" && (
        <div className='assets_head'>
          <h3>Assets</h3>
          <a className='btn btn-sm fw-bold btn-primary' onClick={() => handleShow(true)}>
            <i className='fa fa-plus'></i>  Add asset
          </a>
        </div>
      )}

      {isLoading ? <Loader /> : <></>}

      <div className='assets_tabs mb-20'>
        <DeleteConfirmationDialog
          isOpen={isDeleteDialogOpen}
          onCancel={handleCancelDelete}
          onConfirm={handleDelete}
        />

        <Tabs
          activeKey={activeTab}
          id='uncontrolled-tab-example'
          className='mb-3'
          onSelect={handleTabSelect}
        >
          <Tab eventKey='allAssets' title='All Assets'>
            <div className='tabboxmain row g-4'>
              {listAssets.map((assetsData) => (
                <div className='col-sm-12 col-lg-4 col-md-6' key={assetsData.id}>
                  <div className='subbox  d-flex justify-content-center' onClick={() => {
                    try {
                      window.open(assetsData?.url.match(/^http[s]?:\/\//)
                        ? assetsData?.url
                        : 'http://' + assetsData?.url, '_blank');
                    } catch (e) {
                      console.error(e);
                      toast.error('Invalid Asset Url')
                    }
                  }}>
                    {/* <div className="actiondiv">
                                            <a href={assetsData?.url} target="_blank" className="fa fa-download"></a>
                                            {currentUser &&
                                                <a className={"fa fa-pencil actionbtn pointer-cust"} onClick={() => editAssets(assetsData)}></a>
                                            }

                                            {currentUser && assetsData.id &&
                                                <a className="fa fa-trash pointer-cust" onClick={() => deleteActivityDialogOpen(true, assetsData.id!)}></a>
                                            }
                                        </div> */}
                    <div>
                      <h4>{assetsData?.name}</h4>
                      <p>Updated at: {moment(assetsData?.updated_at).format('MMMM D, h A')}</p>
                    </div>
                    <div className='dropdown align-right-auto' onClick={(e) => { e.stopPropagation() }}>
                      <button
                        className=' btn btn-sm btn-icon btn-bg-light btn-active-color-primary '
                        type='button'
                        id='dropdownUserAction'
                        data-bs-toggle='dropdown'
                        aria-expanded='false'
                      >
                        <i className='bi bi-three-dots fs-3'></i>
                      </button>
                      <div
                        className={
                          isDeleteDialogOpen ? 'dropdown-menu w-150px' : 'dropdown-menu w-150px'
                        }
                        aria-labelledby='dropdownUserAction'
                      >
                        <div className='menu-item px-3'>
                          <a
                            href={
                              assetsData?.url.match(/^http[s]?:\/\//)
                                ? assetsData?.url
                                : 'http://' + assetsData?.url
                            }
                            target='_blank'
                            className='menu-link px-3'
                          >
                            Download
                          </a>
                        </div>
                        {currentUser && currentUser.role != "partner" && (
                          <div className='menu-item px-3'>
                            <a className={'menu-link px-3'} onClick={() => editAssets(assetsData)}>
                              {' '}
                              Edit
                            </a>
                          </div>
                        )}
                        {currentUser && currentUser.role != "partner" && (
                          <div className='menu-item px-3'>
                            <a
                              className='menu-link px-3 color-red'

                              onClick={() => deleteActivityDialogOpen(true, assetsData.id!)}
                            >
                              Delete
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {listAssets?.length == 0 ? (currentUser && currentUser.role != "partner" ? (
                <div className='nodatafound subbox' onClick={() => handleShow(true)}>
                  Looks empty! Click to add some items
                </div>
              ) : (<div className='nodatafound ' >
                Looks empty!
              </div>))
                : null}
            </div>
          </Tab>

          {listAssetsCategory.map((categoryData) => (
            <Tab key={categoryData.id} eventKey={categoryData.id} title={categoryData.name}>
              <div className='tabboxmain row g-4'>
                {listAssets.map((assetsData) => (
                  <div className='col-sm-12 col-lg-4 col-md-6' key={assetsData.id}>
                    <div className='subbox  d-flex justify-content-center' onClick={() => {
                      try {
                        window.open(assetsData?.url.match(/^http[s]?:\/\//)
                          ? assetsData?.url
                          : 'http://' + assetsData?.url, '_blank');
                      } catch (e) {
                        toast.error('Invalid Asset Url')
                      }
                    }}>
                      <div>
                        <h4>{assetsData?.name}</h4>
                        <p>Updated at: {moment(assetsData?.updated_at).format('MMMM D, h A')}</p>
                      </div>
                      <div className='dropdown align-right-auto' onClick={(e) => { e.stopPropagation() }}>
                        <button
                          className=' btn btn-sm btn-icon btn-bg-light btn-active-color-primary '
                          type='button'
                          id='dropdownUserAction'
                          data-bs-toggle='dropdown'
                          aria-expanded='false'
                        >
                          <i className='bi bi-three-dots fs-3'></i>
                        </button>
                        <div
                          className={
                            isDeleteDialogOpen ? 'dropdown-menu w-150px' : 'dropdown-menu w-150px'
                          }
                          aria-labelledby='dropdownUserAction'
                        >
                          <div className='menu-item px-3'>
                            <a
                              href={
                                assetsData?.url.match(/^http[s]?:\/\//)
                                  ? assetsData?.url
                                  : 'http://' + assetsData?.url
                              }
                              target='_blank'
                              className='menu-link px-3'
                            >
                              Download
                            </a>
                          </div>
                          {currentUser && currentUser.role != "partner" && (
                            <div className='menu-item px-3'>
                              <a
                                className={'menu-link px-3'}
                                onClick={() => editAssets(assetsData)}
                              >
                                {' '}
                                Edit
                              </a>
                            </div>
                          )}
                          {currentUser && currentUser.role != "partner" && (
                            <div className='menu-item px-3'>
                              <a
                                className='menu-link px-3 color-red'

                                onClick={() => deleteActivityDialogOpen(true, assetsData.id!)}
                              >
                                Delete
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {listAssets?.length == 0 && <div className='nodatafound'>No Assets Here</div>}
              </div>
            </Tab>
          ))}
        </Tabs>
      </div>

      {edit ? (
        <AddEditAssets
          dialogAction={edit}
          editId={editData?.id}
          editData={editData}
          sendData={setEdit}
          callback={() => {
            fetchAssetsCategory()
            if (release) fetchAssetsByCategory(activeTab, release)
          }}
        />
      ) : null}
      {show ? (
        <AddEditAssets
          dialogAction={show}
          sendData={handleShow}
          callback={() => {
            fetchAssetsCategory()
            if (release) fetchAssetsByCategory(activeTab, release)
          }}
        />
      ) : null}
    </>
  )
}

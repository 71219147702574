/* eslint-disable react/jsx-no-target-blank */
import { KTIcon } from '../../../../../_metronic/helpers'
import { removeAuth } from '../../../auth';

const SidebarFooter = () => {

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('cuurentTaskId');
    localStorage.removeItem('role');
    localStorage.removeItem('userReleasesId');
    localStorage.removeItem('releasesId');
    removeAuth();
    window.location.href = "/auth/login";
  };

  return (
    <div className='app-sidebar-footer flex-column-auto pt-2 pb-6 px-6' id='kt_app_sidebar_footer'>
      <a
        className='btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100'
        onClick={logout}
      >
        <span className='btn-label'>Log Out</span>
        <KTIcon iconName='document' className='btn-icon fs-2 m-0' />
      </a>
    </div>
  )
}

export { SidebarFooter }

import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

export interface Option {
    id: number;
    label: string;
}

interface CheckboxMultipleSelectProps {
    options: Option[];
    enableSelectedOption: boolean | false;
    selectedOption: Option[];
    text: string;
    onChange(arg0: Option[]): any;
    total?: number | undefined;
    loadMore?(): any;
    dropdownHeight?: number | undefined;
    noItemText?: string | undefined;
    isLoading?: boolean | false
    onSearch?: any
    enableSearch?: boolean | false
    menuAlignment?: string | 'auto'
    menuClass?: string
}

const MultiSelectionDropdown: React.FC<CheckboxMultipleSelectProps> = ({ options, enableSelectedOption = false, selectedOption = [], text, onChange,
    total = options.length,
    loadMore = () => { },
    dropdownHeight = undefined,
    noItemText = '',
    isLoading = false,
    enableSearch = false,
    menuAlignment = 'auto',
    menuClass = '',
    onSearch = (searchString: string) => { }
},
) => {
    const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
    const [changed, setChanged] = useState<boolean>(false);
    useEffect(() => {
        if (enableSelectedOption) {

            const initialSelection = options.filter((option: any) =>
                selectedOption.find((so) => so.id == option.id)
            );
            setSelectedOptions(initialSelection);
        }
    }, [options, selectedOption]);

    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const optionId = Number(event.target.value);
        const isChecked = event.target.checked;
        let changedOption: Option[];
        if (isChecked) {
            changedOption = [...selectedOptions, options.find((e) => e.id == optionId)];
        } else {
            changedOption = selectedOptions.filter((e) => e.id !== optionId)

        }
        setSelectedOptions(changedOption)
        onChange(changedOption);
        setChanged(true);
    };

    const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;

        if (isChecked) {
            const allOptionIds = options.map((option) => option.id);
            setSelectedOptions(allOptionIds);
        } else {
            setSelectedOptions([]);
        }
    };

    const checkValue = function (option: any) {
        let found: boolean = false;
        for (var k in selectedOptions) {
            if (selectedOptions[k].id == option.id) {
                found = true;
            }
        }
        if (found)
            return true;
        else
            return false
    }
    const menuBodyClass = (menuAlignment == "right" ? "dropdown-menu-end dropdown-menu" : "dropdown-menu") + ' ' + menuClass;
    return (
        <>

            <div className="dropdown sele_dropdown mb-1">
                <button className="btn dropdown-toggle btn-sm fw-bold btn-secondary ms-3" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    {changed ? (selectedOptions.length == 0 ? '' : <><i className="fas fa-check text-primary"></i> {selectedOptions.length}</>) : (selectedOption.length == 0 ? '' : selectedOption.length)} {text}
                </button>
                <div className={menuBodyClass} aria-labelledby="dropdownMenuButton1" >
                    {enableSearch ? <input
                        className='form-control p-2 mt-2'
                        placeholder='Search'
                        type='text'
                        id='search'
                        name='search'
                        onChange={(e) => { onSearch(e.target.value) }}
                    /> : null}
                    <InfiniteScroll
                        dataLength={options.length}
                        next={loadMore}
                        hasMore={options.length < total}
                        loader={<h4>Loading...</h4>}
                        height={dropdownHeight}
                        endMessage={
                            <p style={{ textAlign: "center" }} className='text-white'>
                                {/* <b>Yay! You have seen it all</b> */}
                            </p>
                        }
                    >
                        {isLoading ? <h4>Loading...</h4> : null}
                        {options.length == 0 && !isLoading ? <div className="form-check">{noItemText ? noItemText : 'No Record Found'}</div> : null}
                        {options.map((option, index) => (
                            <div className="form-check" key={index}>
                                <input className="form-check-input" type="checkbox" value={option.id} checked={checkValue(option)} onChange={handleOptionChange} />
                                <label className="form-check-label">
                                    {option.label}
                                </label>
                            </div>
                        ))}
                    </InfiniteScroll>
                </div>
            </div>

        </>

    );
};

export default MultiSelectionDropdown;
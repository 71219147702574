import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

// Load other component
import CheckboxMultipleSelect from '../multi-select-dropdown/MultiSelectionDropdown';
import Pagination from '../../../global-module/pagination/pagination';
import DeleteConfirmationDialog from '../../../global-module/deleteConfirmationDialog/deleteConfirmationDialog';
import Loader from '../../../global-module/loader/loader';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

// Activity Dialog
import AddActivity from './add-activity';

// Load services
import instance from '../../../../services/axiosConfig';
import { useLocation } from 'react-router-dom';
import { FormattedDate } from 'react-intl';
import { useAuth } from '../../../auth/core/Auth';
import { formatDate } from '../../core/Helpers';
import { Activity } from '../../core/_models';
import { useCommonContext } from '../../../../services/CommonContext';

const ViewActivity = (props: any) => {

    const { dialogAction, sendData, callback } = props;
    const [show, setShowActivity] = useState(dialogAction);
    const [isLoading, setIsLoading] = useState(true);
    const [isActionDisable, setActionDisable] = useState(false);

    const { budgetUpdated, setBudgetUpdated, campaignUpdated, setCampaignUpdated } = useCommonContext()

    const closeListActivityDialog = () => {
        setShowActivity(false);
        sendData(false);
    };
    let releaseId: string = useLocation().pathname.split('/').pop()!;
    const { currentUser } = useAuth()

    // Pagination

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
            fetchActivity(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            fetchActivity(currentPage - 1);
        }
    };

    const handleGoToPage = (page: number) => {
        setCurrentPage(page);
        fetchActivity(page);
    };

    const [listActivity, setActivityList] = useState<Activity[]>([]);
    useEffect(() => {
        fetchActivity(currentPage);
    }, []);


    const fetchActivity = async (currentPage: any) => {

        try {

            const response = await instance.get('/campaign?release_id=' + releaseId + '&page=' + currentPage);
            setActivityList(response.data?.data);
            var calTotalPage = response.data?.total / response.data?.per_page;
            setTotalPages(calTotalPage);
            setIsLoading(false);

        } catch (error) {
            toast.error('something went wrong please try again');
            setIsLoading(false);
        }

    };

    // Edit Action

    const [showActivityForm, setshowActivityForm] = useState(false);
    const [activityId, setshowActivityId] = useState(0);
    const [SingleActivity, setSingleActivity] = useState<Activity[]>([]);

    const showActivityDialog = (param: any) => {
        setshowActivityForm(param);
        fetchActivity(currentPage);
        callback();
    };

    const editActivity = (param: any, itemId: number, currentActivity: any) => {
        setSingleActivity(currentActivity);
        setshowActivityId(itemId);
        setshowActivityForm(param);
    }

    // Delete Conformation

    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [isDeleteId, setDeleteId] = useState(0);

    const handleDelete = async () => {
        setIsLoading(true);
        setActionDisable(true);
        try {

            const response = await instance.delete('/campaign/' + isDeleteId);
            callback()
            toast.success('Delete Campaign Successfully');
            setDeleteDialogOpen(false);
            setIsLoading(false);
            fetchActivity(currentPage);
            setCampaignUpdated(!campaignUpdated);
            setBudgetUpdated(!budgetUpdated);
            setTimeout(function () {
                setActionDisable(false);
            }, 2000);
        } catch (error) {
            toast.success('Delete Campaign Failed');
            setDeleteDialogOpen(false);
            setIsLoading(false);
            setIsLoading(false);
            fetchActivity(currentPage);
            setTimeout(function () {
                setActionDisable(false);
            }, 5000);
        }

    };

    const handleCancelDelete = () => {
        setDeleteDialogOpen(false);
    };

    const deleteActivityDialogOpen = (param: any, itemId: number) => {
        setDeleteId(itemId);
        setDeleteDialogOpen(param);
    }



    return (
        <>
            {showActivityForm ? <AddActivity dialogAction={showActivityForm} editId={activityId} editData={SingleActivity}
                callback={showActivityDialog}
                onDataChange={(data) => {

                }} /> : null}

            {isLoading ? (
                <Loader />
            ) : (
                <Modal show={show} onHide={closeListActivityDialog} dialogClassName="my-modlist_activity_modal">
                    <DeleteConfirmationDialog
                        isOpen={isDeleteDialogOpen}
                        onCancel={handleCancelDelete}
                        onConfirm={handleDelete}
                    />
                    <Modal.Header closeButton>
                        <Modal.Title>List Campaign</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className="table-responsive">
                            <table className="table table-bordered fs-6 gy-3">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Category</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        {currentUser ? <th>Actions</th> : null}
                                    </tr>
                                </thead>

                                <tbody>
                                    {listActivity.map((activityData) => (
                                        <tr key={activityData.id}>
                                            <td>{activityData.name}</td>
                                            <td>{activityData.campaign_type.name}</td>
                                            <td>{formatDate(activityData.start_date)}</td>
                                            <td>{activityData.end_date ? formatDate(activityData.end_date) : '-'}</td>
                                            {currentUser &&
                                                <td>
                                                    <i className={isActionDisable ? "fa fa-pencil actionbtn cust_disabled" : "fa fa-pencil actionbtn"} onClick={() => editActivity(true, activityData.id, activityData)}></i>
                                                    <i className={isActionDisable ? "fa fa-trash actionbtn cust_disabled" : "fa fa-trash actionbtn"} onClick={() => deleteActivityDialogOpen(true, activityData.id)}></i>
                                                </td>
                                            }
                                        </tr>
                                    ))}

                                    {listActivity?.length == 0 &&
                                        <tr>
                                            <td className='nodatafound'>
                                                No Data Found
                                            </td>
                                        </tr>
                                    }

                                </tbody>
                            </table>
                        </div>
                        {totalPages > 1 &&
                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onNextPage={handleNextPage}
                                onPreviousPage={handlePreviousPage}
                                onGoToPage={handleGoToPage}
                            />
                        }
                    </Modal.Body>
                </Modal>

            )}
            <ToastContainer />
        </>
    );
}

export default ViewActivity;
import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'

import CheckboxMultipleSelect from '../multi-select-dropdown/MultiSelectionDropdown'
import Loader from '../../../global-module//loader/loader'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

// Load services
import instance from '../../../../services/axiosConfig'
import { useLocation } from 'react-router-dom'
import { Activity, AddProps, initialData } from '../../core/_models'
import { useCommonContext } from '../../../../services/CommonContext'
import { getAssetUrl } from '../../core/Helpers'
import { getUser } from '../../core/_requests'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'

let Link = Quill.import('formats/link')
class CustomLink extends Link {
  static sanitize(url: any) {
    let value = super.sanitize(url)
    if (value) {
      for (let i = 0; i < this.PROTOCOL_WHITELIST.length; i++)
        if (value.startsWith(this.PROTOCOL_WHITELIST[i])) return value

      return `http://${value}`
    }
    return value
  }
}

Quill.register(CustomLink)
interface AddActivityProps {
  initalData?: AddProps,
  dialogAction: any,
  editId: any,
  editData: any,
  viewMode?: boolean | false,
  callback(arg0: boolean): any,
  onDataChange(data: AddProps, openAddTask?: boolean, openAddCampaign?: boolean): any
}
const AddActivity: React.FC<AddActivityProps> = ({ initalData, dialogAction, editId, editData, callback, viewMode = false, onDataChange }) => {

  const [show, setShowActivity] = useState(dialogAction)
  const [isLoading, setIsLoading] = useState(false)
  const [listAllUser, setAllUser] = useState<any[]>([])
  const { budgetUpdated, setBudgetUpdated, campaignUpdated, setCampaignUpdated } = useCommonContext()
  let releaseId: string = useLocation().pathname.split('/').pop()!
  const closeActivityDialog = () => {
    setShowActivity(false)
    callback(false)
  }

  const [file, setFile] = useState<File | undefined>()
  const [resultText, setResultText] = useState('')

  useEffect(() => {
    setFormData({ ...formData, ['result']: resultText })
  }, [resultText])


  const [formData, setFormData] = useState<any>({
    name: initalData?.name || '',
    campaign_type_id: 0,
    release_id: releaseId,
    budget: initalData?.budget || 0,
    start_date: initalData?.startDate || '',
    end_date: '',
    user_id: 0,
    result: '',
    is_fixed_date: initalData?.startDateIsFixedDate || false,
    start_week: initalData?.startWeek || '',
    end_week: '',
  })

  const [errors, setErrors] = useState({
    name: '',
    campaign_type_id: '',
    release_id: '',
    budget: '',
    start_date: '',
    end_date: '',
    user_id: '',
    result: '',
    start_week: '',
    end_week: '',
  })

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
    switch (e.target.name) {
      case "name":
        onDataChange({
          name: e.target.value,
          budget: formData.budget,
          startDate: formData.start_date,
          startWeek: formData.start_week,
          startDateIsFixedDate: formData.is_fixed_date
        })
        break;
    }
  }
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.files?.[0])
  }
  const fetchAllUser = async () => {
    const data = await getUser('')
    setAllUser(data)
  }

  useEffect(() => {
    if (editId != 0) {
      handleEdit(editData)
    }
    fetchAllUser()
  }, [])
  const onIsFixedDateChanged = (e: boolean) => {

    if (!e && new Date(formData.end_date) < new Date(formData.start_date)) {
      setFormData({ ...formData, ['end_date']: formData.start_date })
      console.log(formData)
    }

    setFormData({ ...formData, ['is_fixed_date']: e })
  }

  const validateForm = (data: any) => {
    const errors: any = {}

    if (!data.name) {
      errors.name = 'Name is required.'
    }

    if (!data.campaign_type_id) {
      errors.campaign_type_id = 'Campaign Category is required.'
    }

    if (!data.release_id) {
      errors.release_id = 'Release is required.'
    }

    if (!data.user_id) {
      errors.user_id = 'Assignee is required.'
    }

    // if (data.budget) {
    //     errors.budget = 'Budget is required.';
    // }

    // if (!) {
    //     errors.end_date = 'End date required.';
    // }
    if (data.is_fixed_date && !data.start_date) {
      errors.start_date = 'Start date is required.'
    }

    if (
      data.is_fixed_date &&
      data.end_date &&
      new Date(data.end_date) < new Date(data.start_date)
    ) {
      errors.end_date = 'End date should be later than or equal to  start date'
    }
    //        console.log(data.start_week);
    if (!data.is_fixed_date && data.start_week === '') {
      errors.start_week = 'Start week is required.'
    }

    if (!data.is_fixed_date && data.end_week && Number(data.end_week) < Number(data.start_week)) {
      errors.end_week = 'End week should be later than or equal to start week'
    }

    return Object.keys(errors).length ? errors : null
  }

  const handleEdit = async (editData: Activity) => {
    setFormData(editData)
    setResultText(editData.result ?? '')
  }

  const handleSubmit = async () => {
    // Validate form data
    const validationErrors = validateForm(formData)

    if (validationErrors) {
      setErrors(validationErrors)
      return
    }

    if (editId != 0) {
      // Edit data
      setIsLoading(true)
      try {
        //delete formData.campaign_type;
        //delete formData.asset_url;
        let _formData = new FormData()
        for (var key in formData) {
          if (key == 'is_fixed_date') _formData.append(key, formData[key] == true ? '1' : '0')
          else if (key != 'campaign_type' && key != 'asset_url')
            _formData.append(key, formData[key] ?? '')
        }
        if (file) _formData.append('asset', file)
        //console.log(formData);
        const response = await instance.post('/campaign/' + editId + '?_method=PUT', _formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        toast.success(response?.data?.message)
        callback(false)
        setShowActivity(false)
        setIsLoading(false)
        setBudgetUpdated(!budgetUpdated)
        setCampaignUpdated(!campaignUpdated)
        onDataChange(initialData)
      } catch (error) {
        toast.error('something went wrong please try again')

        setIsLoading(false)
      }
    } else {
      // Add data
      setIsLoading(true)

      let _formData = new FormData()
      for (var key in formData) {
        if (key == 'is_fixed_date') _formData.append(key, formData[key] == true ? '1' : '0')
        else _formData.append(key, formData[key])
      }
      if (file) _formData.append('asset', file)
      const response = await instance
        .post('/campaign', _formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(function (response) {
          toast.success(response?.data?.message)
          callback(false)
          setShowActivity(false)
          setIsLoading(false)
          setBudgetUpdated(!budgetUpdated)
          setCampaignUpdated(!campaignUpdated)
          onDataChange(initialData)
        })
        .catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx

            for (let _e in error.response.data.errors) {
              toast.error(error.response.data.errors[_e][0])
            }
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request)
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message)
          }
          setIsLoading(false)
        })
    }
  }

  useEffect(() => {
    fetchCampaignType()
  }, [])

  // Fetch the Campaign Type

  interface CampaignType {
    id: number
    name: string
    created_at: number
  }
  const [listCampaignType, setCampaignType] = useState<CampaignType[]>([])
  const startDateRef = useRef<HTMLInputElement>(null)
  const fetchCampaignType = async () => {
    try {
      const response = await instance.get('/campaign-type')
      setCampaignType(response.data?.data)
    } catch (error) { }
  }

  return (
    <>
      {isLoading ? <Loader /> : <></>}

      <Modal show={show} onHide={closeActivityDialog}>
        <div className='custom_modal'>
          <Modal.Header closeButton>
            <Modal.Title>{editId != 0 ? viewMode ? 'Campaign' : 'Update Campaign' : 'Add New Campaign'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='row'>
              <div className='col-md-12'>
                <button className='btn btn-info' onClick={() => {
                  onDataChange({
                    name: formData.name,
                    budget: formData.budget,
                    startDate: formData.start_date,
                    startWeek: formData.start_week,
                    startDateIsFixedDate: formData.is_fixed_date
                  }, true, false)
                }}>Task</button>
                <button className='btn btn-info' disabled>Campaign</button>
              </div>
              <div className='col-md-12'>
                <div className='form-group'>
                  <label className='labels'>Campaign Name</label>
                  <input
                    className='form-control'
                    placeholder='Enter Campaign Name'
                    type='text'
                    id='name'
                    name='name'
                    disabled={viewMode ? true : false}
                    value={formData.name}
                    onChange={handleChange}
                  />
                  {errors.name && formData.name === '' && (
                    <div className='error-form'>{errors.name}</div>
                  )}
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label className='labels'>Campaign Category</label>
                  <select
                    className='form-control'
                    name='campaign_type_id'
                    value={formData.campaign_type_id}
                    onChange={handleChange}
                    disabled={viewMode ? true : false}
                  >
                    <option value='' defaultValue={''}>
                      Select Category
                    </option>
                    {listCampaignType.map((campaignType) => (
                      <option key={campaignType.id} value={campaignType.id}>
                        {campaignType.name}
                      </option>
                    ))}
                  </select>
                  {errors.campaign_type_id && (
                    <div className='error-form'>{errors.campaign_type_id}</div>
                  )}
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label className='labels'>Assignee</label>
                  <select
                    className='form-control'
                    name='user_id'
                    value={formData.user_id}
                    onChange={handleChange}
                    disabled={viewMode ? true : false}
                  >
                    <option value=''>Select Assignee</option>
                    {listAllUser.map((userData) => (
                      <option key={userData.id} value={userData.id}>
                        {userData.name}
                      </option>
                    ))}
                  </select>
                  {errors.user_id && (
                    <div className='error-form'>{errors.user_id}</div>
                  )}
                </div>
              </div>
              <div className='col-md-12'>
                <div className='form-group'>
                  <label className='labels'>Budget</label>
                  <input
                    className='form-control'
                    placeholder='Enter Budget'
                    type='number'
                    id='budget'
                    name='budget'
                    disabled={viewMode ? true : false}
                    value={formData.budget}
                    onChange={handleChange}
                  />
                  {errors.name && isNaN(formData.budget) && (
                    <div className='error-form'>{errors.budget}</div>
                  )}
                </div>
              </div>

              <div className='col-md-12'>
                <div className='form-group'>
                  <label className='labels'>Image</label>
                  {formData.asset_url && file == undefined ? (
                    <img src={getAssetUrl(formData.asset_url)}></img>
                  ) : null}
                  <input
                    className='form-control'
                    placeholder='Choose File'
                    type='file'
                    id='asset'
                    name='asset'
                    disabled={viewMode ? true : false}
                    onChange={handleFileChange}
                  />
                  {errors.name && isNaN(formData.budget) && (
                    <div className='error-form'>{errors.budget}</div>
                  )}
                </div>
              </div>

              {/* <div className="col-md-12">
                            <div className="form-group activity_song_drop">
                                <label className='labels'>Songs</label>
                                <CheckboxMultipleSelect options={options1} onChange={onchange} selecType='Songs selected' />
                            </div>
                        </div> */}

              <div className='col-md-12'>
                <label className='labels'>Start Date</label>
              </div>
              <div className='col-md-6'>
                {/* begin::Radio */}

                <div className='form-group form-check form-check-custom form-check-solid'>
                  <input
                    className='form-check-input me-3'
                    onChange={() => onIsFixedDateChanged(false)}
                    name='role'
                    type='radio'
                    value='Administrator'
                    id='kt_modal_update_role_option_0'
                    disabled={viewMode ? true : false}
                    checked={formData.is_fixed_date == false}
                  />
                  <label className='form-check-label' htmlFor='kt_modal_update_role_option_0'>
                    <div className='fw-bolder text-gray-800'>Week</div>
                  </label>
                </div>
              </div>

              <div className='col-md-6'>
                <div className=' form-group form-check form-check-custom form-check-solid'>
                  <input
                    className='form-check-input me-3'
                    onChange={() => onIsFixedDateChanged(true)}
                    name='role'
                    type='radio'
                    value='Developer'
                    disabled={viewMode ? true : false}
                    id='kt_modal_update_role_option_1'
                    checked={formData.is_fixed_date == true}
                  />
                  <label className='form-check-label' htmlFor='kt_modal_update_role_option_1'>
                    <div className='fw-bolder text-gray-800'>Fixed</div>
                  </label>
                  {/* end::Label */}
                </div>
                {/* end::Radio */}
              </div>
              {formData.is_fixed_date ? (
                <>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <label className='labels'>Start Date</label>
                      <input
                        disabled={viewMode ? true : false}
                        className='form-control'
                        placeholder='Select a date'
                        name='start_date'
                        type='date'
                        ref={startDateRef}
                        onClick={() => {
                          startDateRef.current?.focus()
                        }}
                        value={formData.start_date}
                        onChange={handleChange}
                      />
                      {errors.start_date && formData.start_date === '' && (
                        <div className='error-form'>{errors.start_date}</div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <label className='labels'>End Date</label>
                      <input
                        className='form-control'
                        placeholder='Select a date'
                        name='end_date'
                        type='date'
                        disabled={viewMode ? true : false}
                        value={formData.end_date}
                        onChange={handleChange}
                      />
                      {errors.end_date &&
                        formData.end_date &&
                        new Date(formData.end_date) < new Date(formData.start_date) && (
                          <div className='error-form'>{errors.end_date}</div>
                        )}
                    </div>
                  </div>{' '}
                </>
              ) : (
                <>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <label className='labels'>Start Week</label>
                      <select
                        className='form-control'
                        name='start_week'
                        disabled={viewMode ? true : false}
                        value={formData.start_week}
                        onChange={handleChange}
                      >
                        <option value='' defaultValue={''}>
                          Select a Week
                        </option>

                        <option value={-16}>Week -16</option>
                        <option value={-15}>Week -15</option>
                        <option value={-14}>Week -14</option>
                        <option value={-13}>Week -13</option>
                        <option value={-12}>Week -12</option>
                        <option value={-11}>Week -11</option>
                        <option value={-10}>Week -10</option>
                        <option value={-9}>Week -9</option>
                        <option value={-8}>Week -8</option>
                        <option value={-7}>Week -7</option>
                        <option value={-6}>Week -6</option>
                        <option value={-5}>Week -5</option>
                        <option value={-4}>Week -4</option>
                        <option value={-3}>Week -3</option>
                        <option value={-2}>Week -2</option>
                        <option value={-1}>Week -1</option>
                        <option value={0}>Week 0</option>
                        <option value={1}>Week 1</option>
                        <option value={2}>Week 2</option>
                        <option value={3}>Week 3</option>
                        <option value={4}>Week 4</option>
                        <option value={5}>Week 5</option>
                        <option value={6}>Week 6</option>
                        <option value={7}>Week 7</option>
                        <option value={8}>Week 8</option>
                        <option value={9}>Week 9</option>
                        <option value={10}>Week 10</option>
                        <option value={11}>Week 11</option>
                        <option value={12}>Week 12</option>
                        <option value={13}>Week 13</option>
                        <option value={14}>Week 14</option>
                        <option value={15}>Week 15</option>
                        <option value={16}>Week 16</option>
                      </select>
                      {errors.start_week && <div className='error-form'>{errors.start_week}</div>}
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <label className='labels'>End Week</label>
                      <select
                        className='form-control'
                        name='end_week'
                        disabled={viewMode ? true : false}
                        value={formData.end_week}
                        onChange={handleChange}
                      >
                        <option value='' defaultValue={''}>
                          Select a Week
                        </option>
                        <option value={-16}>Week -16</option>
                        <option value={-15}>Week -15</option>
                        <option value={-14}>Week -14</option>
                        <option value={-13}>Week -13</option>
                        <option value={-12}>Week -12</option>
                        <option value={-11}>Week -11</option>
                        <option value={-10}>Week -10</option>
                        <option value={-9}>Week -9</option>
                        <option value={-8}>Week -8</option>
                        <option value={-7}>Week -7</option>
                        <option value={-6}>Week -6</option>
                        <option value={-5}>Week -5</option>
                        <option value={-4}>Week -4</option>
                        <option value={-3}>Week -3</option>
                        <option value={-2}>Week -2</option>
                        <option value={-1}>Week -1</option>
                        <option value={0}>Week 0</option>
                        <option value={1}>Week 1</option>
                        <option value={2}>Week 2</option>
                        <option value={3}>Week 3</option>
                        <option value={4}>Week 4</option>
                        <option value={5}>Week 5</option>
                        <option value={6}>Week 6</option>
                        <option value={7}>Week 7</option>
                        <option value={8}>Week 8</option>
                        <option value={9}>Week 9</option>
                        <option value={10}>Week 10</option>
                        <option value={11}>Week 11</option>
                        <option value={12}>Week 12</option>
                        <option value={13}>Week 13</option>
                        <option value={14}>Week 14</option>
                        <option value={15}>Week 15</option>
                        <option value={16}>Week 16</option>
                      </select>
                      {errors.end_week &&
                        formData.end_week &&
                        Number(formData.end_week) < Number(formData.start_week) && (
                          <div className='error-form'>{errors.end_week}</div>
                        )}
                    </div>
                  </div>
                </>
              )}
              <div className='col-md-12'>
                <div className='form-group'>
                  <label className='labels'>Results</label>
                  <ReactQuill theme='snow' readOnly={viewMode ? true : false} value={resultText} onChange={setResultText} />
                  {/* <textarea className='form-control' placeholder='Enter Campaign Result' id="result" name="result" value={formData.result} onChange={handleChange} /> */}
                  {/* {errors.result && formData.result === '' && <div className='error-form'>{errors.result}</div>} */}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={closeActivityDialog}>
              Close
            </Button>
            {viewMode ? null :
              <Button variant='' onClick={handleSubmit} className='btn-active'>
                {editId != 0 ? 'Update Campaign' : 'Add Campaign'}
              </Button>}
          </Modal.Footer>
        </div>
      </Modal>
      <ToastContainer />
    </>
  )
}

export default AddActivity

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { KTIcon } from '../../../../../../../_metronic/helpers'
import { ChatInner } from '../../chat/ChatInner'

type Props = {
  custTaskName?: string,
  custtaskId?: number
}

const DrawerMessenger: FC<Props> = ({ custTaskName, custtaskId }) => {

  return (
    <>
      <div
        id='kt_drawer_chat'
        className='bg-body'
        data-kt-drawer='true'
        data-kt-drawer-name='chat'
        data-kt-drawer-activate='true'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width="{default:'300px', 'md': '500px'}"
        data-kt-drawer-direction='end'
        data-kt-drawer-toggle='#kt_drawer_chat_toggle'
        data-kt-drawer-close='#kt_drawer_chat_close'
      >
        <div className='card w-100 rounded-0' id='kt_drawer_chat_messenger'>
          <div className='card-header pe-5' id='kt_drawer_chat_messenger_header'>
            <div className='card-title'>
              <div className='d-flex justify-content-center flex-column me-3'>
                <a className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'>
                  {custTaskName}
                </a>
              </div>
            </div>

            <div className='card-toolbar'>
              <div className='btn btn-sm btn-icon btn-active-light-primary' id='kt_drawer_chat_close'>
                <KTIcon iconName='cross' className='fs-2' />
              </div>
            </div>
          </div>

          <ChatInner isDrawer={true} currentTaskId={custtaskId} />
        </div>
      </div>
    </>
  )
}

export { DrawerMessenger }

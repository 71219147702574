/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage, useAuth } from '../modules/auth'
import { App } from '../App'
import { ApgRedirection, DasboardPage, DashboardLayout } from '../modules/dashboard'
import { CampaignCategoryList } from '../modules/settings/components/pages/campaign-categories/CampaignCategories'
import { CommonContextProvider } from '../services/CommonContext'
import { UserList } from '../modules/project-users/components/user-management/UserList'
import { TaskCategoryList } from '../modules/settings/components/pages/task-categories/TaskCategoryList'
import { AssetCategoryList } from '../modules/settings/components/pages/asset-categories/AssetCategories'
import { ReleaseBundleList } from '../modules/release-bundle/ReleaseBundleList'

const PUBLIC_URL = '';

const AppRoutes: FC = () => {
  const { currentUser } = useAuth()

  return (
    <CommonContextProvider>
      <BrowserRouter basename={PUBLIC_URL}>
        <Routes>
          <Route element={<App />}>
            <Route path='error/*' element={<ErrorsPage />} />
            <Route path='logout' element={<Logout />} />
            {currentUser ? (
              <>
                <Route element={<DashboardLayout />}>
                  <Route index element={<Navigate to='/dashboard' />} />
                  {/* <Route path='s/:data/:id' element={<Navigate to='/dashboard' />} /> */}
                  <Route path='s/:data/:id' element={<DasboardPage withEncData={true} />} />
                  <Route path='share/:id' element={<DasboardPage />} />
                  <Route path='dashboard' element={<DasboardPage />} />
                  <Route path='project-user' element={<UserList />} />
                  <Route path='release-bundle' element={<ReleaseBundleList />} />
                  <Route path='settings/campaign-category' element={<CampaignCategoryList />} />
                  <Route path='settings/task-category' element={<TaskCategoryList />} />
                  <Route path='settings/asset-category' element={<AssetCategoryList />} />
                  {/* <Route path='*' element={<ErrorsPage />} /> */}

                </Route>
                <Route path='notfound' element={<ErrorsPage />} />
              </>
            ) : (
              <>
                <Route path='auth/*' element={<AuthPage />} />
                <Route element={<DashboardLayout />}>
                  <Route path='s/:data/:id/:tenant' element={<DasboardPage withEncData={true} />} />
                  <Route path='s/:data/:id' element={<ApgRedirection />} />
                  <Route path='share/:id' element={<Navigate to='/auth/login' />} />
                </Route>

                <Route path='notfound' element={<ErrorsPage />} />
                <Route path='/' element={<Navigate to='/auth/login' />} />

              </>
            )}
            <Route path='*' element={<Navigate to='/' />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </CommonContextProvider>
  )
}

export { AppRoutes }
